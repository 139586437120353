import React, { Component } from "react";
import {
  Table,
  Card,
  PageHeader,
  Button,
  Tooltip,
  Pagination,
  Typography,
  Select,
} from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
// import swal from "sweetalert2";

import { CREATE_SUB_ORG_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text } = Typography;

class SubOrgTypeList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    sort_by: "seq_no",
    sort_type: "asc",
  };
  componentDidMount() {
    this.getSubOrgTypeList();
  }

  getSubOrgTypeList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_SUB_ORG_TYPE}?sort_by=${this.state.sort_by}&sort_type=${this.state.sort_type}&page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from sub org type list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from sub org type list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getSubOrgTypeList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getSubOrgTypeList();
    });
  };

  refresh = () => {
    this.setState(
      {
        page: 0,
        limit: 10,
        sort_by: "seq_no",
        sort_type: "asc",
      },
      () => {
        this.getSubOrgTypeList();
      }
    );
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.sub_org.sub_org_type.sub_org_type_create ? (
          <Link to="/createSubOrgType">
            <Tooltip placement="top" title="Create Sub Org Type">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}
        <PageHeader title="Sub Organization Types" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <div style={{ margin: 10, textAlign: "center" }}>
            <Button
              style={{ backgroundColor: "#1A3337", color: "white" }}
              onClick={this.refresh}
            >
              Refresh
            </Button>
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="Sequence Number" dataIndex="seq_no" key="seq_no" />

          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />

          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.sub_org.sub_org_type
                  .sub_org_type_detail ? (
                  <Link
                    to={{
                      pathname: "/editSubOrgType",
                      state: { sub_org_type_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(SubOrgTypeList);
