import React, { Component } from 'react';
import { Card, Form, Input, Button, Typography, PageHeader} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { CREATE_UNIT} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";

import "../login.css";

const { Text } = Typography;

export default class CreateUnit extends Component {
    state = {
        name: "",
        unit: "",
        loading: false
      };
    
    
      setName = evt => {
        evt.preventDefault();
        this.setState({ name: evt.target.value }, () => {
          console.log(this.state.name);
        });
      };
      // setUnit = evt => {
      //   evt.preventDefault();
      //   this.setState({ unit: evt.target.value }, () => {
      //     console.log(this.state.unit);
      //   });
      // };
      
    
      createUnit = () => {
        if(this.state.name === "" ){
          swal.fire("info","Please enter all informations","info");
        }else{
        this.setState({ loading: true });
        const data = {
          name: this.state.name,
          unit: this.state.name
        };
        console.log(data);
        GET_ACCESS_TOKEN().then(token => {
          let url=`${CREATE_UNIT}?access_token=${token}`
          axios({
            method: "post",
            url:url ,
            data: data,
            headers: { 
              'content-type': 'application/x-www-form-urlencoded' 
            }
          })
            .then(response => {
              this.setState({ loading: false });
              swal.fire("Info", response.data.message, "info");
              console.log("response from create unit", response);
            })
            .catch(error => {
              this.setState({ loading: false });
              swal.fire("Error", error.message, "error");
              console.log("error from create unit", error);
            });
        });
      }
    }
      render() {
        if (this.state.loading === true) {
          return <Spinner />;
        }
        return (
          <Card style={{ margin: 10 }}>
            <PageHeader
              
              title="Create Unit"
            />
            <Form
              style={{
                display: "flex",
                alignSelf: "center",
                flexDirection: "column"
              }}
            >
              <Form.Item>
                <Text>Name</Text>
                <Input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </Form.Item>
              {/* <Form.Item>
                <Text>Unit</Text>
                <Input
                  placeholder="Unit"
                  type="text"
                  value={this.state.unit}
                  onChange={this.setUnit}
                />
              </Form.Item> */}
    
              
              <Form.Item>
                <Button className="login-form-button" htmlType="submit" onClick={this.createUnit}>
                  Create Unit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        );
      }
}
