import React from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Divider,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { Redirect } from "react-router-dom";
import {
  CREATE_ORGANIZATION,
  CREATE_COUNTRY,
  EDIT_COUNTRY,
  ORG_IMAGE_UPLOAD,
  PRE_SELECTED_PLAN_CHECK,
  USER_PROFILE,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  CHECK_BOOL,
  // GET_USER_LOCAL
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";
import { timezone } from "../timezone";

const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;

export default class CreateOrganization extends React.Component {
  state = {
    name: "",
    address: "",
    cities: [],
    filtered: [],
    city: "",
    city_id: "",
    country: "",
    country_id: "",
    connect_key: "",
    post_code: "",
    telephone: "",
    website: "",
    allow_connect: true,
    sub_orgs: [],
    countries: [],
    loading: false,
    redirectToSubscribe: false,
    org_id: "",
    timezone: [],
    timezone_name: "",
    filteredTimezone: [],
    for_name: "organization",
    imgFile: null,
    imgUrl: null,
    pre_selected_plan_id: "",
    user_id: "",
  };
  componentDidMount() {
    this.setState({ timezone: timezone });
    this.countryList();
    // this.getPreSelectedPlan();
    this.getUserdata();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getUserdata = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user profile", response);

          this.setState(
            {
              user_id: response.data._id.$oid,
            },
            () => {
              this.getPreSelectedPlan();
            }
          );
        })
        .catch((error) => {
          console.log("error from user profile", error);
        });
    });
  };

  getPreSelectedPlan = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRE_SELECTED_PLAN_CHECK}${this.state.user_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            pre_selected_plan_id: response.data.data.pre_selected_plan_id,
          });
          console.log("pre selected plan", response);
        })
        .catch((error) => {
          console.log("error from pre selected plan api", error);
        });
    });
  };
  countryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch((error) => {
          console.log("error from country list api", error);
        });
    });
  };
  handleSearch = (value) => {
    const result = this.state.timezone.filter((tz) => {
      return tz.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filteredTimezone: result });
  };
  setTimezone = (value) => {
    this.setState({ timezone_name: value }, () => {
      console.log(this.state.timezone_name);
    });
  };
  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setAddress = (evt) => {
    evt.preventDefault();
    this.setState({ address: evt.target.value }, () => {
      console.log(this.state.address);
    });
  };
  handleSearchCity = (value) => {
    const result = this.state.cities.filter((city) => {
      return city.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };
  setCity = (value) => {
    this.setState({ city: value.label, city_id: value.key }, () => {
      console.log(this.state.city);
    });
  };
  setCountry = (value) => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
      let url = `${EDIT_COUNTRY}${this.state.country_id}`;
      GET_ACCESS_TOKEN().then((token) => {
        axios({
          method: "get",
          url: url,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          console.log("response fro edit country", response);
          this.setState({
            cities: response.data.data.cities,
          });
        });
      });
    });
  };
  setConnectKey = (evt) => {
    evt.preventDefault();
    this.setState({ connect_key: evt.target.value }, () => {
      console.log(this.state.connect_key);
    });
  };
  setPostCode = (evt) => {
    evt.preventDefault();
    this.setState({ post_code: evt.target.value }, () => {
      console.log(this.state.post_code);
    });
  };
  setTelephone = (evt) => {
    evt.preventDefault();
    this.setState({ telephone: evt.target.value }, () => {
      console.log(this.state.telephone);
    });
  };
  setWebsite = (evt) => {
    evt.preventDefault();
    this.setState({ website: evt.target.value }, () => {
      console.log(this.state.website);
    });
  };

  setSubOrgs = (evt) => {
    evt.preventDefault();
    this.setState({ sub_orgs: evt.target.value }, () => {
      console.log(this.state.sub_orgs);
    });
  };

  setAllowConnection = (value) => {
    this.setState({ allow_connect: CHECK_BOOL(value) }, () => {
      console.log(this.state.allow_connect);
    });
  };

  uploadImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}${this.state.for_name}?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              imgUrl: `${process.env.REACT_APP_BASE_PATH}${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };

  createOrganization = async () => {
    if (
      this.state.name === "" ||
      this.state.address === "" ||
      this.state.city === "" ||
      this.state.country === "" ||
      this.state.post_code === "" ||
      this.state.telephone === "" ||
      this.state.timezone_name === ""
    ) {
      swal.fire(
        "info",
        "Please fill up name,address,country,city,post code,timezone name and telephone to submit ",
        "info"
      );
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        org_image: this.state.imgUrl,
        address: this.state.address,
        city: this.state.city,
        country: this.state.country,
        connect_key: this.state.connect_key,
        post_code: this.state.post_code,
        telephone: this.state.telephone,
        website: this.state.website,
        sub_orgs: this.state.sub_orgs,
        allow_connect: this.state.allow_connect,
        timezone_name: this.state.timezone_name,
        deleted: false,
        plan_id: this.state.pre_selected_plan_id,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${CREATE_ORGANIZATION}?access_token=${token}`;
        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            if (this.state.pre_selected_plan_id === "") {
              swal.fire(
                "Info",
                "Organization Created.Please Subscribe to a Plan to Access Full feature",
                "info"
              );
              console.log("response from create org", response);
              window.location.reload();
              // this.setState({ org_id: response.data.data._id.$oid }, () => {
              //   this.setState({ redirectToSubscribe: true });
              // });
            } else {
              swal.fire("Info", response.data.message, "info");
              window.location.reload();
              console.log(
                "response from create org where plan-id exists",
                response
              );
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("Error", error.message, "error");
            console.log("error from create org", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    if (this.state.redirectToSubscribe === true) {
      return (
        <Redirect
          to={{
            pathname: "/subscribeOrganizationPlan",
            state: { org_id: this.state.org_id },
          }}
        />
      );
    }

    const timezoneList = this.state.timezone.map((r) => {
      return <Option key={r.name}>{r.name}</Option>;
    });

    const FliteredTimezoneList = this.state.filteredTimezone.map((r) => {
      return <Option key={r.name}>{r.name}</Option>;
    });

    const citiesList = this.state.cities.map((r) => {
      return <Option key={r.code}>{r.name}</Option>;
    });

    const FliteredList = this.state.filtered.map((r) => {
      return <Option key={r.code}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 5 }}>
        <PageHeader title="Create Organization (* Marked fields are mandatory)" />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Card style={{ width: "50%" }}>
              <Form.Item>
                <Text>Name *</Text>
                <Input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </Form.Item>
              <Form.Item>
                <Text>Address *</Text>

                <TextArea
                  rows={4}
                  placeholder="Address"
                  type="text"
                  value={this.state.address}
                  onChange={this.setAddress}
                />
              </Form.Item>
              <Form.Item>
                <Text>Country *</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.country_id,
                    label: this.state.country,
                  }}
                  onChange={this.setCountry}
                  placeholder="Select Country"
                >
                  {this.state.countries.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Search City *</Text>

                <Select
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearchCity}
                  disabled={this.state.country === "" ? true : false}
                  value={{ key: this.state.city_id, label: this.state.city }}
                  onChange={this.setCity}
                  placeholder="Select City"
                >
                  {this.state.filtered.length === 0 ? citiesList : FliteredList}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Search Timezone *</Text>
                <Select
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  value={this.state.timezone_name}
                  onChange={this.setTimezone}
                  placeholder="Select Timezone"
                >
                  {this.state.filteredTimezone.length === 0
                    ? timezoneList
                    : FliteredTimezoneList}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Post Code *</Text>

                <Input
                  placeholder="Post Code"
                  type="text"
                  value={this.state.post_code}
                  onChange={this.setPostCode}
                />
              </Form.Item>
            </Card>
            <Card style={{ width: "50%", marginLeft: 5 }}>
              <Form.Item>
                <Text>Telephone *</Text>

                <Input
                  placeholder="Telephone"
                  type="text"
                  value={this.state.telephone}
                  onChange={this.setTelephone}
                />
              </Form.Item>
              <Divider>Additional Informations</Divider>
              {this.state.imgUrl === null ? null : (
                <img
                  src={this.state.imgUrl}
                  alt="avatar"
                  style={{ width: 100, height: 100 }}
                />
              )}
              <Form.Item>
                <Text>
                  Upload Organization Image (Image must be under 100 KB)
                </Text>

                <input
                  type="file"
                  onChange={(e) => {
                    console.log("Image file inside", this.state.imgUrl);
                    this.setState(
                      {
                        imgUrl: URL.createObjectURL(e.target.files[0]),
                        imgFile: e.target.files[0],
                      },
                      () => {
                        this.uploadImage();
                      }
                    );
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Allow Connect</Text>
                <Select
                  value={this.state.allow_connect.toString()}
                  onChange={this.setAllowConnection}
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Connect Key</Text>

                <Input
                  placeholder="Connect Key"
                  type="text"
                  value={this.state.connect_key}
                  onChange={this.setConnectKey}
                />
              </Form.Item>

              <Form.Item>
                <Text>Website</Text>

                <Input
                  placeholder="Website"
                  type="text"
                  value={this.state.website}
                  onChange={this.setWebsite}
                />
              </Form.Item>
              <Form.Item>
                <Text>Sub Organizations</Text>

                <Input
                  placeholder="Sub Organizations"
                  type="text"
                  value={this.state.sub_orgs}
                  onChange={this.setSubOrgs}
                />
              </Form.Item>
            </Card>
          </div>

          <Form.Item>
            <Button
              style={{ marginTop: 5 }}
              className="login-form-button"
              htmlType="submit"
              onClick={this.createOrganization}
            >
              Create Organization
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
