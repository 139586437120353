import React from "react";
import { Form, Icon, Input, Button, Card, Typography } from "antd";
import { Redirect } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";

import { GET_ACCESS_TOKEN } from "../utilities/utilities";
import { MOBILE_VERIFY, RESEND_CODE } from "../api/constants";
import { Spinner } from "./loader";
import "./login.css";
import Logo from "../assets/logoblue.png";

const { Text, Title } = Typography;

class VerifyAccount extends React.Component {
  state = {
    mobile: "",
    code: "",
    loading: false,
    redirect: false,
    width: window.innerWidth,
    height: window.innerHeight,
  };

  componentDidMount() {
    this.setState({ mobile: this.props?.location?.state?.mobile ?? "" });
    const handleResize = () => {
      this.setState({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", handleResize);
  }

  setCode = (evt) => {
    evt.preventDefault();
    this.setState({ code: evt.target.value });
    console.log(this.state.code);
  };

  verifyUser = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${MOBILE_VERIFY}${this.state.code}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          if (response.data.hasOwnProperty("status")) {
            swal.fire("Information", response.data.status, "info");
          }
          if (response.data.status === "mobile verified") {
            this.setState({ redirect: true });
          }
          console.log("response from verify user api", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error in verify user", error);
        });
    });
  };

  resendCode = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      axios({
        method: "get",
        url: `${RESEND_CODE}&access_token=${token}`,
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Information", response.data.status, "info");
          console.log("response from resend code", response);
        })
        .catch((error) => {
          console.log("error in resend code", error);
          swal.fire("Error", error, "error");
        });
    });
  };
  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/createOrganization" />;
    } else if (this.state.loading === true) {
      return <Spinner />;
    } else if (this.state.toLogin === true) {
      return <Redirect to="/" />;
    }
    return (
      <div className="container" style={{ width: this.state.width,backgroundColor: "#EFF3EA", }}>
        <div style={{ display: "flex" }}>
          <Card
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 15,
              position: "relative",
              top: this.state.height / 20,
            }}
          >
            <div style={{ textAlign: "center", paddingBottom: 10 }}>
              <a href="https://www.conveyance.app/">
                <img
                  src={Logo}
                  alt=""
                  style={{
                    width: 230,
                    position: "relative",
                    padding: 10,
                  }}
                />
              </a>
            </div>
            <Title>Enter the 6-digit code</Title>
            <Text>We've sent an one-time passcode to {this.state.mobile}</Text>

            <Form className="login-form">
              <Form.Item>
                <Input
                  type="number"
                  prefix={
                    <Icon type="number" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Enter Verification Code"
                  value={this.state.code}
                  onChange={this.setCode}
                />
              </Form.Item>
              <div
                style={{
                  marginTop: -20,
                  textAlign: "center",
                  marginBottom: 15,
                }}
              >
                <Button type="link" onClick={this.resendCode}>
                  Didn't receive the code?
                </Button>
              </div>

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  className="login-form-button"
                  htmlType="submit"
                  onClick={this.verifyUser}
                >
                  Verify Account
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    );
  }
}

export default VerifyAccount;
