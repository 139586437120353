import React, { Component } from "react";
import { Card, Form, Input, Button, Typography, PageHeader } from "antd";
import axios from "axios";
import swal from "sweetalert2";
import { ENTITY_TYPE_EDIT } from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";

import "../login.css";

const { Text } = Typography;

export default class EditEntityType extends Component {
  state = {
    name: "",
    entity_type_id: "",
  };
  componentDidMount() {
    this.setState({ entity_type_id: this.props.location.state.entity_type_id });

    GET_ACCESS_TOKEN().then((token) => {
      let entity_type_id = this.props.location.state.entity_type_id;
      let url = `${ENTITY_TYPE_EDIT}${entity_type_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit unit", response);
        this.setState({
          name: response.data.data.name,
        });
      });
    });
  }

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };

  editEntityType = () => {
    const data = {
      name: this.state.name,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ENTITY_TYPE_EDIT}${this.state.entity_type_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          console.log("response from edit entity type", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from edit entity type", error);
        });
    });
  };
  render() {
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
         
          title="Edit Place Type"
          onBack={() => this.props.history.goBack()}
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Name</Text>
            <Input
              placeholder="Name"
              type="text"
              value={this.state.name}
              onChange={this.setName}
            />
          </Form.Item>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editEntityType}
            >
              Update Place Type
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
