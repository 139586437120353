import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip,Pagination } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";

import { CREATE_TASK_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";

import "../login.css";

const { Column } = Table;

class TaskTypeList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    limit: 10,
    loading: false
  };
  componentDidMount() {
    this.props.getUserData();
    this.getTaskType();
  }

  getTaskType = () => {
    GET_ACCESS_TOKEN().then(token => {
      const url = `${CREATE_TASK_TYPE}?page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from task type list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total
          });
        })
        .catch(error => {
          console.log("error from task type list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getTaskType();
    });
  };


  render() {
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.task.task_type.task_type_create ? (
          <Link to="/createTaskType">
            <Tooltip placement="top" title="Create Task Type">
            <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

        <PageHeader
          title="Task Types"
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.task.task_type.task_type_update ? (
                  <Link
                    to={{
                      pathname: "/editTaskType",
                      state: { taskType_id: record._id.$oid }
                    }}
                  >
                    <Button
                      type="link"
                      style={{ color: "green" }}
                    >
                      Edit
                    </Button>
                  </Link>
                ) : null}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userProfileData: state.userProfile.userProfileData
});

export default connect(mapStateToProps, { getUserData })(TaskTypeList);
