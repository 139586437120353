import React, { Component } from "react";
import { Table, Card, PageHeader } from "antd";
// import { Link } from "react-router-dom";
import axios from "axios";
// import swal from "sweetalert2";

import { INVENTORY_PRODUCT_STOCK_HISTORY_LIST } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";


import "../login.css";

const { Column } = Table;

export default class ViewProductStockHistory extends Component {
  state = {
    data: [],
    product_id:"",
    inventory_id:""
  };
  componentDidMount() {
    this.setState({ product_id: this.props.location.state.product_id,inventory_id:this.props.location.state.inventory_id });
    this.getProductStockHistoryList();
     ReactGA.send({ hitType: "pageview", page:window.location.pathname,title:window.location.pathname });;

  }

  getProductStockHistoryList = () => {
    let product_id=this.props.location.state.product_id;
    let inventory_id=this.props.location.state.inventory_id;
    GET_ACCESS_TOKEN().then(token => {
      const url = `${INVENTORY_PRODUCT_STOCK_HISTORY_LIST}${inventory_id}?product_id=${product_id}&access_token=${token}`;

      axios({
        method: "get",
        url: url
      })
        .then(response => {
          console.log("response from product stock history list", response);
          this.setState({
            data:response.data.data
          });
        })
        .catch(error => {
          console.log("error from product stock history list api", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title={`Product Name: ${this.state.data.length>0?this.state.data[0].product_name:""}, Current Stock Quantity: ${this.state.data.length>0?this.state.data[0].current_stock_quantity:0}`}
        />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={record => record._id.$oid}
          size="small"
          bordered
        >
           <Column
              title="Product Name"
              dataIndex="product_name"
              key="product_name"
            />
            <Column
              title="Current Stock Quantity"
              dataIndex="current_stock_quantity"
              key="current_stock_quantity"
            />

            <Column
              title="Current Unit Purchase Price"
              dataIndex="current_unit_purchase_price"
              key="current_unit_purchase_price"
            />
            <Column
              title="Previous Stock Quantity"
              dataIndex="previous_stock_quantity"
              key="previous_stock_quantity"
            />
            <Column
              title="Previous Unit Purchase Price"
              dataIndex="previous_unit_purchase_price"
              key="previous_unit_purchase_price"
            />
            <Column
              title="Discount Per Unit"
              dataIndex="discount_per_unit"
              key="discount_per_unit"
            />

            <Column
              title="Added Stock"
              dataIndex="added_stock"
              key="added_stock"
            />

            <Column title="Unit Cost" dataIndex="unit_cost" key="unit_cost" />
            <Column
              title="Cost Amount"
              dataIndex="cost_amount"
              key="cost_amount"
            />

            <Column
              title="Subtracted Stock"
              dataIndex="subtracted_stock"
              key="subtracted_stock"
            />
            <Column
              title="Add or Subtract Reason"
              dataIndex="add_or_subtract_reason"
              key="add_or_subtract_reason"
            />
            <Column
              title="Comment"
              dataIndex="comment"
              key="comment"
            />
            
            <Column
              title="Created On"
              key="created_by"
              render={(text, record) => (
                <span>{timeConverter(record.created_on.$date)}</span>
              )}
            />
        </Table>
   
      </Card>
    );
  }
}
