import React, { Component } from "react";
import {
  Card,
  Form,
  Button,
  Typography,
  PageHeader,
  Select,
  Switch,
  Collapse,
  List,
  Tag,
  Input,
  Pagination,
} from "antd";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import swal from "sweetalert2";
import {
  EDIT_ENTITY,
  CREATE_ENTITY,
  SEARCH_NEARBY_ASSETS,
  REVERSE_LOCATION_SEARCH_GOOGLE,
  DISPATCH_ROUTE,
  ASSET_SEARCH,
  ADVANCED_ORDER_SEARCH,
  CREATE_DRIVER,
  GENERATE_TASK_FROM_DISPATCH_ROUTE
} from "../../api/constants";
import { GET_ACCESS_TOKEN, randomId } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import { debounce } from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { colors } from "../colors.js";

import "../login.css";
import "../dispatchRoute.css";

const { Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const red = new L.Icon({
  iconUrl: require("../.././assets/mymarkertwo.png"),
  iconSize: [30, 40],
});

const truck = new L.Icon({
  iconUrl: require("../.././assets/cargo_truck.png"),
  iconSize: [50, 50],
});

const createNumberedIcon = (number, color) => {
  return L.divIcon({
    html: `<div style="background-color: ${color};color:white; border: 2px solid #1A3337; border-radius: 50%; width: 35px; height: 35px; display: flex; align-items: center; justify-content: center; font-weight: bold; font-size: 14px;">
      ${number}
    </div>`,
    className: "", // Remove default class
    iconSize: [35, 35],
    iconAnchor: [15, 15],
  });
};

export default class DispatchRoute extends Component {
  state = {
    entity_id: "",
    entity_name: "",
    selected_assets: [],
    data: [],
    zoom: 13,
    center_lat: 23.685,
    center_lon: 90.3563,
    polylines: [],
    user_loc: true,
    assetData: [],
    asset_page: 0,
    asset_total: 0,
    nearby_assets: [],
    start_time: Date.now(),
    start_time_view: moment(),
    end_time: Date.now(),
    end_time_view: moment(),
    select_asset: true,
    vehicle_assignments: [],
    asset_polylines: [],
    loading_dispatch: false,
    asset_search_text: "",
    orders: [],
    order_page: 0,
    order_total: 0,
    selected_orders: [],
    order_search_text: "",
    asset_ids: [],
    vehicle_data_for_swap: [],
    userData: [],
    start_time_task:""
  };

  componentDidMount() {
    this.getEntityList();
    this.getAssetList();
    this.getUserList();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getUserList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 100;
      const url = `${CREATE_DRIVER}?limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ userData: response.data.data });
          console.log("response from user list", response);
        })
        .catch((error) => {
          console.log("error from user list api", error);
        });
    });
  };

  searchUser = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_DRIVER}?name=${value}&limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from search user", response);
          this.setState({
            userData: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from search user api", error);
        });
    });
  }, 500);

  getAssetList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 10;
      const url = `${ASSET_SEARCH}?name=${this.state.asset_search_text}&capable_asset=true&page=${this.state.asset_page}&limit=${limit}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            assetData: response.data.data,
            asset_total: response.data.total,
          });
          console.log("response from asset list", response);
        })
        .catch((error) => {
          console.log("error from asset list api", error);
        });
    });
  }, 500);

  pageChangeAsset = (current, pageSize) => {
    this.setState({ asset_page: current - 1 }, () => {
      console.log("page number", this.state.asset_page);
      this.getAssetList();
    });
  };

  getOrderList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${ADVANCED_ORDER_SEARCH}?page=${this.state.order_page}&limit=10&percel_weight=true&order_number=${this.state.order_search_text}&end_time=${this.state.end_time}&start_time=${this.state.start_time}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from order list", response);
          this.setState({
            orders: response.data.data,
            order_total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from order list api", error);
        });
    });
  }, 500);

  pageChangeOrder = (current, pageSize) => {
    this.setState({ order_page: current - 1 }, () => {
      console.log("page number", this.state.order_page);
      this.getOrderList();
    });
  };

  searchOrder = (evt) => {
    this.setState({ order_search_text: evt.target.value }, () => {
      this.getOrderList();
    });
  };
  searchAsset = (evt) => {
    this.setState({ asset_search_text: evt.target.value }, () => {
      this.getAssetList();
    });
  };

  getNearbyAssets = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${SEARCH_NEARBY_ASSETS}?user_loc=${this.state.user_loc}&lat=${this.state.center_lat}&lng=${this.state.center_lon}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            selected_assets: response.data?.data ?? [],
            nearby_assets: response.data?.data ?? [],
            asset_ids: [],
          });
          console.log("nearby assets", response);
        })
        .catch((error) => {
          console.log("nearby assets", error);
        });
    });
  };

  getEntityList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ data: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&name=${value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ data: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  }, 500);

  setEntity = (value) => {
    this.setState(
      { entity_id: value.key, entity_name: value.label, response_entity: [] },
      () => {
        GET_ACCESS_TOKEN().then((token) => {
          let url = `${EDIT_ENTITY}${this.state.entity_id}?access_token=${token}`;
          axios({
            method: "get",
            url: url,
          }).then((response) => {
            console.log("response fro edit supplier", response);
            this.setState(
              {
                center_lat: response.data.data.loc.coordinates[1],
                center_lon: response.data.data.loc.coordinates[0],
              },
              () => this.getNearbyAssets()
            );
          });
        });
      }
    );
  };

  setAsset = (value) => {
    this.setState({ asset_ids: value, nearby_assets: [] }, () => {
      console.log(this.state.asset_ids);
    });
  };

  updatePositionEnd = (evt) => {
    console.log(evt.target.getLatLng());
    this.setState(
      {
        center_lon: evt.target.getLatLng().lng,
        center_lat: evt.target.getLatLng().lat,
      },
      () => {
        this.getNearbyAssets();
        GET_ACCESS_TOKEN().then((token) => {
          axios
            .get(
              `${REVERSE_LOCATION_SEARCH_GOOGLE}?lat=${this.state.center_lat}&lng=${this.state.center_lon}&access_token=${token}`
            )
            .then((response) => {
              console.log("response for end marker change", response);
              let name = response.data.google_loc_data[0].address;
              let id = response.data.google_loc_data[0]._id.$oid;
              this.setState({
                entity_name: name,
                entity_id: id,
              });
            });
        });
      }
    );
  };

  setStartTime = (value, dateString) => {
    this.setState(
      { start_time: Number(moment(value).format("x")), start_time_view: value },
      () => {
        // this.dispatchRoute();
        console.log("Selected Time: ", this.state.start_time);
        console.log("Selected value: ", this.state.start_time_view);
      }
    );
  };
  setEndTime = (value, dateString) => {
    this.setState(
      { end_time: Number(moment(value).format("x")), end_time_view: value },
      () => {
        // this.dispatchRoute();
        console.log("Selected Time: ", this.state.end_time);
        console.log("Selected value: ", this.state.end_time_view);
      }
    );
  };

  dispatchRoute = () => {
    this.setState({ loading_dispatch: true });
    let data = {
      // start_date: this.state.start_time,
      // end_date: this.state.end_time,
      nearby_assets: this.state.nearby_assets,
      asset_ids: this.state.asset_ids,
      order_obj: this.state.selected_orders,
      vehicle_orders: this.state.vehicle_data_for_swap,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${DISPATCH_ROUTE}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          this.setState({ loading_dispatch: false });
          swal.fire("Info", response.data.message, "info");
          this.setState({
            vehicle_assignments:
              response.data?.data?.vehicle_assignments?.vehicle_assignments ??
              [],
            vehicle_data_for_swap:
              response.data?.data?.vehicle_assignments?.vehicle_assignments ??
              [],
              start_time_task:
              response.data?.data?.vehicle_assignments?.start_time ??
              "",
            asset_polylines:
              response.data?.data?.assigned_asset_route_polylines ?? [],
          });
          console.log("dispatch route", response);
        })
        .catch((error) => {
          this.setState({ loading_dispatch: false });
          swal.fire("Error", error.message, "error");
          console.log("dispatch route", error);
        });
    });
  };
  generateTask = () => {
    const allValid = this.state.vehicle_assignments.every(
      (assignment) => assignment.user_id && assignment.user_id.trim() !== ""
    );
    if (!allValid) {
      swal.fire("Info", "Please assign users to your asset", "info");
    } else {
      let data = {
        vehicle_assignments: this.state.vehicle_assignments,
        start_time: this.state.start_time_task,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${GENERATE_TASK_FROM_DISPATCH_ROUTE}?access_token=${token}`;

        axios({
          method: "post",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            console.log("gen task from dispatch route", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("gen task from dispatch route", error);
          });
      });
    }
  };

  adjustDuplicateCoordinates = (deliveryPoints) => {
    const seenCoordinates = {};
    const offset = 0.0001;

    return deliveryPoints.map((item) => {
      const { lat, lon } = item.delivery_point;
      const key = `${lat},${lon}`;

      if (seenCoordinates[key]) {
        const adjustmentCount = seenCoordinates[key]++;
        const newLat = lat + offset * adjustmentCount;
        const newLon = lon + offset * adjustmentCount;
        return {
          ...item,
          delivery_point: {
            ...item.delivery_point,
            lat: newLat,
            lon: newLon,
          },
        };
      } else {
        seenCoordinates[key] = 1;
        return item;
      }
    });
  };

  setAssignedUser = (asset, value) => {
    this.setState(
      (prevState) => {
        const targetVehicle = prevState.vehicle_assignments.find(
          (item) => item.vehicle_id === asset.vehicle_id
        );

        const vehicle_with_user_id = {
          vehicle_name: targetVehicle.vehicle_name,
          user_id: value,
          vehicle_load: targetVehicle.vehicle_load,
          vehicle_start_lat: targetVehicle.vehicle_start_lat,
          vehicle_start_lon: targetVehicle.vehicle_start_lon,
          name: targetVehicle.name,
          id: targetVehicle.id,
          vehicle_id: targetVehicle.vehicle_id,
          route: targetVehicle.route,
        };

        const updatedVehicleData = prevState.vehicle_assignments.map(
          (vehicle) => {
            if (vehicle.vehicle_id === targetVehicle.vehicle_id) {
              return vehicle_with_user_id;
            }
            return vehicle;
          }
        );

        return {
          vehicle_assignments: updatedVehicleData,
        };
      },
      () => console.log(this.state.vehicle_assignments)
    );
  };

  handleDragStart = (event, asset) => {
    event.dataTransfer.setData("asset", JSON.stringify(asset));
  };
  handleDragStartOrder = (event, order) => {
    event.dataTransfer.setData("order", JSON.stringify(order));
  };
  handleDragSwap = (event, asset, order) => {
    event.dataTransfer.setData("assetswap", JSON.stringify(asset));
    event.dataTransfer.setData("orderswap", JSON.stringify(order));
  };

  handleDropSwap = (event, target_asset) => {
    event.preventDefault();
    const asset = JSON.parse(event.dataTransfer.getData("assetswap"));
    const order = JSON.parse(event.dataTransfer.getData("orderswap"));
    const sum = target_asset.route.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.delivery_point.parcel_wght;
    }, 0);
    if (sum + order.delivery_point.parcel_wght > target_asset.vehicle_load) {
      swal.fire("Info", "Vehicle load exceeded", "info");
    } else if (
      target_asset.route.some(
        (item) =>
          item.delivery_point.order_number === order.delivery_point.order_number
      )
    ) {
      swal.fire("Info", "Order already exists in asset", "info");
    } else {
      this.setState(
        (prevState) => {
          const draggedVehicle = prevState.vehicle_data_for_swap.find(
            (item) => item.vehicle_id === asset.vehicle_id
          );

          const updatedDraggedVehicle = {
            vehicle_name: draggedVehicle.vehicle_name,
            vehicle_load: draggedVehicle.vehicle_load,
            vehicle_start_lat: draggedVehicle.vehicle_start_lat,
            vehicle_start_lon: draggedVehicle.vehicle_start_lon,
            name: draggedVehicle.name,
            id: draggedVehicle.id,
            vehicle_id: draggedVehicle.vehicle_id,
            route: draggedVehicle.route.filter(
              (routeItem) =>
                routeItem.delivery_point.order_number !==
                order.delivery_point.order_number
            ),
          };

          const targetVehicle = prevState.vehicle_data_for_swap.find(
            (item) => item.vehicle_id === target_asset.vehicle_id
          );

          const updatedTargetVehicle = {
            vehicle_name: targetVehicle.vehicle_name,
            vehicle_load: targetVehicle.vehicle_load,
            vehicle_start_lat: targetVehicle.vehicle_start_lat,
            vehicle_start_lon: targetVehicle.vehicle_start_lon,
            name: targetVehicle.name,
            id: targetVehicle.id,
            vehicle_id: targetVehicle.vehicle_id,
            route: [...targetVehicle.route, ...[order]],
          };

          const updatedVehicleData = prevState.vehicle_data_for_swap.map(
            (vehicle) => {
              if (vehicle.vehicle_id === draggedVehicle.vehicle_id) {
                return updatedDraggedVehicle;
              }
              if (vehicle.vehicle_id === targetVehicle.vehicle_id) {
                return updatedTargetVehicle;
              }
              return vehicle;
            }
          );

          return {
            vehicle_data_for_swap: updatedVehicleData,
            selected_orders: [],
            selected_assets: [],
            asset_ids: [],
            nearby_assets: [],
          };
        },
        () => console.log(this.state.vehicle_data_for_swap)
      );
    }
  };
  handleDrop = (event) => {
    event.preventDefault();
    const asset = JSON.parse(event.dataTransfer.getData("asset"));
    if (
      !this.state.selected_assets.some((item) => item._id === asset._id.$oid)
    ) {
      let asset_obj = {
        name: asset.name,
        vehicle_load: asset.vehicle_load,
        _id: asset._id.$oid,
      };
      this.setState(
        {
          selected_assets: [...this.state.selected_assets, ...[asset_obj]],
          asset_ids: [...this.state.asset_ids, ...[asset._id.$oid]],
        },
        () => {
          console.log(this.state.selected_assets);
        }
      );
    }
  };
  handleDropOrder = (event) => {
    event.preventDefault();
    const order = JSON.parse(event.dataTransfer.getData("order"));
    if (
      !this.state.selected_orders.some((item) => item._id === order._id.$oid)
    ) {
      let order_obj = {
        name: order.name,
        order_number: order.order_number,
        end_loc_name: order.end_loc_name,
        parcel_wght: order.parcel_wght,
        end_entity: order.end_entity,
        _id: order._id.$oid,
      };
      this.setState(
        {
          selected_orders: [...this.state.selected_orders, ...[order_obj]],
          vehicle_data_for_swap: [],
        },
        () => {
          console.log(this.state.selected_orders);
        }
      );
    }
  };

  removeSwapOrder = (asset, order) => {
    this.setState(
      (prevState) => {
        const selectedAsset = prevState.vehicle_data_for_swap.find(
          (item) => item.vehicle_id === asset.vehicle_id
        );
        const deletedOrder = {
          vehicle_name: selectedAsset.vehicle_name,
          vehicle_load: selectedAsset.vehicle_load,
          vehicle_start_lat: selectedAsset.vehicle_start_lat,
          vehicle_start_lon: selectedAsset.vehicle_start_lon,
          name: selectedAsset.name,
          id: selectedAsset.id,
          vehicle_id: selectedAsset.vehicle_id,
          route: selectedAsset.route.filter(
            (routeItem) =>
              routeItem.delivery_point.order_number !==
              order.delivery_point.order_number
          ),
        };

        const updatedVehicleData = prevState.vehicle_data_for_swap.map(
          (vehicle) => {
            if (vehicle.vehicle_id === asset.vehicle_id) {
              return deletedOrder;
            }
            return vehicle;
          }
        );

        return {
          vehicle_data_for_swap: updatedVehicleData,
          selected_orders: [],
          selected_assets: [],
          asset_ids: [],
          nearby_assets: [],
        };
      },
      () => console.log(this.state.vehicle_data_for_swap)
    );
  };
  removeAsset = (asset) => {
    const filtered_asset_ids = this.state.selected_assets.filter(
      (item) => item._id !== asset._id
    );
    const filtered_asset_only_ids = this.state.asset_ids.filter(
      (item) => item !== asset._id
    );
    this.setState(
      {
        selected_assets: filtered_asset_ids,
        asset_ids: filtered_asset_only_ids,
      },
      () => {
        console.log(this.state.selected_assets);
      }
    );
  };
  removeOrder = (order) => {
    const filtered_order_ids = this.state.selected_orders.filter(
      (item) => item._id !== order._id
    );
    this.setState(
      {
        selected_orders: filtered_order_ids,
      },
      () => {
        console.log(this.state.selected_orders);
      }
    );
  };

  handleDragOver = (event) => {
    event.preventDefault();
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader title="Dispatch Route" />

        <Form>
          <div
            style={{
              display: "flex",
              alignSelf: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <Map
                style={{ marginBottom: 10 }}
                center={[this.state.center_lat, this.state.center_lon]}
                zoom={this.state.zoom}
                onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
              >
                <ReactLeafletGoogleLayer
                  googleMapsLoaderConf={{
                    KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                    VERSION: "3.53",
                  }}
                  type={"roadmap"}
                />
                {!this.state.user_loc ? (
                  <Marker
                    position={[this.state.center_lat, this.state.center_lon]}
                    draggable
                    onDragend={this.updatePositionEnd}
                    icon={red}
                  >
                    <Popup>
                      <Card style={{ marginTop: 20, borderRadius: 10 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text ellipsis={true} style={{}}>
                            {this.state.entity_name}
                          </Text>
                        </div>
                      </Card>
                    </Popup>
                  </Marker>
                ) : null}
                {this.state.vehicle_assignments.length > 0 ? (
                  <>
                    {this.state.vehicle_assignments.map((r, vehicle_index) => (
                      <div key={r.vehicle_id}>
                        <Marker
                          position={[r.vehicle_start_lat, r.vehicle_start_lon]}
                          icon={truck}
                        >
                          <Popup>
                            {/* <Card style={{ marginTop: 20, borderRadius: 10 }}> */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  padding: 5,
                                  border: "1px solid #FBFBFB",
                                  borderRadius: 5,
                                  borderLeft: "5px solid #659287",
                                }}
                              >
                                <Text
                                  style={{}}
                                >{`Vehicle Name - ${r.vehicle_name}`}</Text>
                              </div>
                              <div
                                style={{
                                  padding: 5,
                                  border: "1px solid #FBFBFB",
                                  borderRadius: 5,
                                  borderLeft: "5px solid #DEAA79",
                                }}
                              >
                                <Text
                                  style={{}}
                                >{`Start Location - ${r.name}`}</Text>
                              </div>
                            </div>
                            {/* </Card> */}
                          </Popup>
                        </Marker>
                        {this.adjustDuplicateCoordinates(r.route).map(
                          (m, index) => (
                            <Marker
                              key={randomId(20)}
                              position={[
                                m.delivery_point.lat,
                                m.delivery_point.lon,
                              ]}
                              icon={createNumberedIcon(
                                index + 1,
                                colors[vehicle_index]
                              )}
                            >
                              <Popup>
                                {/* <Card
                                  style={{ marginTop: 20, borderRadius: 10 }}
                                > */}
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      padding: 5,
                                      border: "1px solid #FBFBFB",
                                      borderRadius: 5,
                                      borderLeft: "5px solid #BC7C7C",
                                    }}
                                  >
                                    <Text
                                      style={{}}
                                    >{`Order Number - ${m.delivery_point.order_number}`}</Text>
                                  </div>
                                  <div
                                    style={{
                                      padding: 5,
                                      border: "1px solid #FBFBFB",
                                      borderRadius: 5,
                                      borderLeft: "5px solid #A2D2DF",
                                    }}
                                  >
                                    <Text
                                      style={{}}
                                    >{`Delivery Location - ${m.delivery_point.delivery_place_name}`}</Text>
                                  </div>
                                  <div
                                    style={{
                                      padding: 5,
                                      border: "1px solid #FBFBFB",
                                      borderRadius: 5,
                                      borderLeft: "5px solid #7EACB5",
                                    }}
                                  >
                                    <Text
                                      style={{}}
                                    >{`Vehicle Name - ${r.vehicle_name}`}</Text>
                                  </div>
                                  <div
                                    style={{
                                      padding: 5,
                                      border: "1px solid #FBFBFB",
                                      borderRadius: 5,
                                      borderLeft: "5px solid #295F98",
                                    }}
                                  >
                                    <Text
                                      style={{}}
                                    >{`Estimated Arrival - ${m.delivery_point.estimated_arrival_time}`}</Text>
                                  </div>
                                </div>
                                {/* </Card> */}
                              </Popup>
                            </Marker>
                          )
                        )}
                      </div>
                    ))}
                  </>
                ) : null}
                {this.state.asset_polylines.length > 0 ? (
                  <>
                    {this.state.asset_polylines.map((pl, outerIndex) => (
                      <div key={outerIndex}>
                        {pl.polyline.map((m, innerIndex) => (
                          <Polyline
                            key={innerIndex}
                            color={colors[outerIndex]}
                            positions={[m.map((n) => [n[0], n[1]])]}
                          />
                        ))}
                      </div>
                    ))}
                  </>
                ) : null}
              </Map>
            </div>
            <div>
              <Collapse
                defaultActiveKey={[
                  "Select Asset",
                  "Search Order",
                  "Swap Orders",
                  "Timeline",
                  "Assign User to Asset",
                ]}
              >
                <Panel header="Search/Select Assets" key="Select Asset">
                  <Form.Item>
                    <Text>
                      Manual/Nearby Asset
                      <Switch
                        style={{ marginLeft: 5, marginTop: 4 }}
                        checked={this.state.select_asset}
                        onChange={(checked) =>
                          this.setState({ select_asset: checked }, () => {
                            if (!checked) {
                              this.getNearbyAssets();
                            } else {
                              this.setState({ nearby_assets: [] });
                            }
                          })
                        }
                        checkedChildren="Manual"
                        unCheckedChildren="Nearby"
                      />
                    </Text>
                  </Form.Item>
                  {this.state.select_asset ? (
                    <div style={{ display: "flex", gap: "5px" }}>
                      {/* Assets Table */}
                      <div className="table-design">
                        <table className="table-design">
                          <thead>
                            <tr className="tr-design">
                              <th
                                className="th-design"
                                style={{ textAlign: "center" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Text>Search/Drag Asset</Text>
                                  <Input
                                    type="text"
                                    onChange={this.searchAsset}
                                  />
                                </div>
                              </th>
                              <th
                                className="th-design"
                                style={{ textAlign: "center" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Text>Asset Load</Text>
                                </div>
                              </th>
                              <th
                                className="th-design"
                                style={{ textAlign: "center" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Text>Registration No.</Text>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.assetData.map((asset) => (
                              <tr
                                className="tr-design"
                                key={asset._id.$oid}
                                draggable
                                onDragStart={(event) =>
                                  this.handleDragStart(event, asset)
                                }
                              >
                                <td
                                  className="td-design"
                                  style={{ textAlign: "center" }}
                                >
                                  <Tag color={colors[45]}>{asset.name}</Tag>
                                </td>
                                <td
                                  className="td-design"
                                  style={{ textAlign: "center" }}
                                >
                                  <Tag color={colors[45]}>
                                    {asset.vehicle_load}
                                  </Tag>
                                </td>
                                <td
                                  className="td-design"
                                  style={{ textAlign: "center" }}
                                >
                                  <Tag color={colors[45]}>
                                    {asset.registration_number}
                                  </Tag>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {this.state.asset_total > 10 ? (
                          <Pagination
                            pageSize={10}
                            current={this.state.asset_page + 1}
                            total={this.state.asset_total}
                            onChange={this.pageChangeAsset}
                            style={{ marginTop: 10 }}
                          />
                        ) : null}
                      </div>

                      {/* Dropped Assets Table */}
                      <table
                        className="table-design"
                        onDrop={this.handleDrop}
                        onDragOver={this.handleDragOver}
                      >
                        <thead>
                          <tr className="tr-design">
                            <th
                              className="th-design"
                              style={{ textAlign: "center" }}
                            >
                              {" "}
                              Drop/Remove Asset
                            </th>
                            <th
                              className="th-design"
                              style={{ textAlign: "center" }}
                            >
                              {" "}
                              Asset Load
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.selected_assets.map((asset) => (
                            <tr className="tr-design" key={asset._id}>
                              <td
                                className="td-design"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Tag
                                  onClick={() => this.removeAsset(asset)}
                                  color={colors[66]}
                                >
                                  {asset.name}
                                </Tag>
                              </td>
                              <td
                                className="td-design"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Tag
                                  onClick={() => this.removeAsset(asset)}
                                  color={colors[66]}
                                >
                                  {asset.vehicle_load}
                                </Tag>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <Form.Item>
                      <Text>
                        Nearby Asset by User Loc/Manual
                        <Switch
                          style={{ marginLeft: 5, marginTop: 4 }}
                          checked={this.state.user_loc}
                          onChange={(checked) =>
                            this.setState({ user_loc: checked }, () =>
                              this.getNearbyAssets()
                            )
                          }
                          checkedChildren="User Loc"
                          unCheckedChildren="Manual Loc"
                        />
                      </Text>
                    </Form.Item>
                  )}
                  {!this.state.user_loc ? (
                    <Form.Item>
                      <Text>Move Marker or Select Saved Place</Text>
                      <Select
                        showSearch={true}
                        labelInValue
                        filterOption={false}
                        showArrow={false}
                        autoClearSearchValue={false}
                        onSearch={this.handleSearch}
                        value={{
                          key: this.state.entity_id,
                          label: this.state.entity_name,
                        }}
                        onChange={this.setEntity}
                      >
                        {this.state.data.map((r) => {
                          return <Option key={r._id.$oid}>{r.name}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  ) : null}
                  {this.state.nearby_assets.length > 0 ? (
                    <List
                      size="small"
                      header={<div>Nearby Assets</div>}
                      bordered
                      dataSource={this.state.nearby_assets}
                      renderItem={(item, index) => (
                        <List.Item>{`${index + 1} : ${item.name}`}</List.Item>
                      )}
                    />
                  ) : null}
                </Panel>
                <Panel header="Search/Select Orders" key="Search Order">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Form.Item>
                      <div
                        style={{
                          marginBottom: 5,
                          marginRight: 5,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            inputVariant="outlined"
                            inputProps={{
                              style: {
                                padding: 10,
                                paddingTop: 15,
                                height: 14,
                                width: 130,
                              },
                            }}
                            value={this.state.start_time_view}
                            onChange={this.setStartTime}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <div
                        style={{
                          marginBottom: 5,
                          marginRight: 5,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            inputVariant="outlined"
                            inputProps={{
                              style: {
                                padding: 10,
                                paddingTop: 15,
                                height: 14,
                                width: 130,
                              },
                            }}
                            value={this.state.end_time_view}
                            onChange={this.setEndTime}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    </Form.Item>
                    <Form.Item style={{ marginTop: 1 }}>
                      <Button
                        style={{ color: "white", backgroundColor: "#1A3337" }}
                        htmlType="submit"
                        onClick={this.getOrderList}
                      >
                        Search
                      </Button>
                    </Form.Item>
                  </div>

                  <div style={{ display: "flex", gap: "5px" }}>
                    <div className="table-design">
                      <table className="table-design">
                        <thead>
                          <tr className="tr-design">
                            <th
                              className="th-design"
                              style={{ textAlign: "center" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Text>Search/Drag Order No.</Text>
                                <Input
                                  type="text"
                                  onChange={this.searchOrder}
                                />
                              </div>
                            </th>
                            <th
                              className="th-design"
                              style={{ textAlign: "center" }}
                            >
                              <Text>Delivery Location</Text>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.orders.map((order) => (
                            <tr
                              className="tr-design"
                              key={order._id.$oid}
                              draggable
                              onDragStart={(event) =>
                                this.handleDragStartOrder(event, order)
                              }
                            >
                              <td
                                className="td-design"
                                style={{ textAlign: "center" }}
                              >
                                <Tag color={colors[6]}>
                                  {order.order_number}
                                </Tag>
                              </td>
                              <td
                                className="td-design"
                                style={{ textAlign: "center" }}
                              >
                                <Tag color={colors[6]}>
                                  {order.end_loc_name}
                                </Tag>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {this.state.order_total > 10 ? (
                        <Pagination
                          pageSize={10}
                          current={this.state.order_page + 1}
                          total={this.state.order_total}
                          onChange={this.pageChangeOrder}
                          style={{ marginTop: 10 }}
                        />
                      ) : null}
                    </div>

                    {/* Dropped order Table */}
                    <table
                      className="table-design"
                      onDrop={this.handleDropOrder}
                      onDragOver={this.handleDragOver}
                    >
                      <thead>
                        <tr className="tr-design">
                          <th
                            className="th-design"
                            style={{ textAlign: "center" }}
                          >
                            {" "}
                            Drop/Remove Order
                          </th>
                          <th
                            className="th-design"
                            style={{ textAlign: "center" }}
                          >
                            <Text>Delivery Location</Text>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.selected_orders.map((order) => (
                          <tr className="tr-design" key={order._id}>
                            <td
                              className="td-design"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Tag
                                onClick={() => this.removeOrder(order)}
                                color={colors[5]}
                              >
                                {order.order_number}
                              </Tag>
                            </td>
                            <td
                              className="td-design"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Tag
                                onClick={() => this.removeOrder(order)}
                                color={colors[5]}
                              >
                                {order.end_loc_name}
                              </Tag>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {this.state.selected_orders.length > 0 &&
                  this.state.selected_assets.length > 0 ? (
                    <Form.Item>
                      <Button
                        style={{ marginTop: 15, width: "25%" }}
                        loading={this.state.loading_dispatch}
                        className="login-form-button"
                        htmlType="submit"
                        onClick={this.dispatchRoute}
                      >
                        {this.state.loading_dispatch
                          ? "Generating"
                          : "Generate"}
                      </Button>
                    </Form.Item>
                  ) : null}
                </Panel>

                <Panel header="Estimated Delivery Timeline" key="Timeline">
                  {this.state.vehicle_assignments.length > 0 && (
                    <div>
                      {this.state.vehicle_assignments.map(
                        (asset, asset_index) => (
                          <div
                            key={asset.vehicle_id}
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <Text
                                style={{
                                  color: colors[asset_index],
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {asset.vehicle_name.toUpperCase()}
                              </Text>
                              <div className="timeline">
                                {asset.route.map((order, order_index) => (
                                  <div
                                    className="timeline-item"
                                    key={order.delivery_point.order_number}
                                  >
                                    <div
                                      style={{
                                        backgroundColor: colors[asset_index],
                                      }}
                                      className="timeline-dot"
                                    >
                                      {order_index + 1}
                                    </div>
                                    <div className="timeline-content">
                                      <Text
                                        style={{
                                          color: colors[asset_index],
                                          textAlign: "center",
                                        }}
                                      >
                                        {
                                          order.delivery_point.estimated_arrival_time.split(
                                            " "
                                          )[1]
                                        }
                                      </Text>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <hr
                              style={{
                                width: "100%", // Full viewport width
                                border: "none",
                                borderTop: "1px solid #ccc", // Customize the color of the line
                                margin: "20px 0", // Adjust spacing around the divider
                              }}
                            />
                          </div>
                        )
                      )}
                    </div>
                  )}
                </Panel>

                <Panel
                  header="Drag/Drop Orders to Swap Them or Remove the Order"
                  key="Swap Orders"
                >
                  {this.state.vehicle_data_for_swap.length > 0 && (
                    <div className="table-container">
                      {this.state.vehicle_data_for_swap.map(
                        (asset, vehicle_index) => (
                          <Card
                            className="table-wrapper"
                            key={asset.id}
                            style={{
                              margin: 0,
                              borderRadius: 10,
                              marginTop: 5,
                              marginRight: 5,
                            }}
                          >
                            <PageHeader
                              title={`Asset Name: ${asset.vehicle_name}`}
                            />
                            <PageHeader
                              title={`Total Capacity: ${asset.vehicle_load}`}
                            />
                            <PageHeader
                              title={`Available Capacity: ${
                                asset.vehicle_load -
                                asset.route.reduce(
                                  (accumulator, currentItem) => {
                                    return (
                                      accumulator +
                                      currentItem.delivery_point.parcel_wght
                                    );
                                  },
                                  0
                                )
                              }`}
                            />
                            <table className="table-design">
                              <thead>
                                <tr className="tr-design">
                                  <th
                                    className="th-design"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Text>Order Number</Text>
                                  </th>
                                  <th
                                    className="th-design"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Text>Delivery Location</Text>
                                  </th>
                                  <th
                                    className="th-design"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Text>Parcel Weight</Text>
                                  </th>
                                  <th
                                    className="th-design"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Text>Estimated Time</Text>
                                  </th>
                                  <th
                                    className="th-design"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Text>Remove</Text>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {asset.route.map((order) => (
                                  <tr
                                    className="tr-design"
                                    key={order.delivery_point.order_number}
                                    draggable
                                    onDragStart={(event) =>
                                      this.handleDragSwap(event, asset, order)
                                    }
                                    onDrop={(event) =>
                                      this.handleDropSwap(event, asset)
                                    }
                                    onDragOver={this.handleDragOver}
                                  >
                                    <td
                                      className="td-design"
                                      style={{ textAlign: "center" }}
                                    >
                                      <Tag color={colors[7]}>
                                        {order.delivery_point.order_number}
                                      </Tag>
                                    </td>
                                    <td
                                      className="td-design"
                                      style={{ textAlign: "center" }}
                                    >
                                      <Tag color={colors[7]}>
                                        {
                                          order.delivery_point
                                            .delivery_place_name
                                        }
                                      </Tag>
                                    </td>
                                    <td
                                      className="td-design"
                                      style={{ textAlign: "center" }}
                                    >
                                      <Tag color={colors[7]}>
                                        {
                                          order.delivery_point
                                            .parcel_wght
                                        }
                                      </Tag>
                                    </td>
                                    <td
                                      className="td-design"
                                      style={{ textAlign: "center" }}
                                    >
                                      <Tag color={colors[7]}>
                                        {
                                          order.delivery_point.estimated_arrival_time.split(
                                            " "
                                          )[1]
                                        }
                                      </Tag>
                                    </td>
                                    <td
                                      className="td-design"
                                      style={{ textAlign: "center" }}
                                    >
                                      <Button
                                        type="link"
                                        onClick={() =>
                                          this.removeSwapOrder(asset, order)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Card>
                        )
                      )}
                    </div>
                  )}
                  {this.state.vehicle_data_for_swap.length > 0 ? (
                    <Form.Item>
                      <Button
                        style={{ marginTop: 15, width: "25%" }}
                        loading={this.state.loading_dispatch}
                        className="login-form-button"
                        htmlType="submit"
                        onClick={this.dispatchRoute}
                      >
                        {this.state.loading_dispatch ? "Updating" : "Update"}
                      </Button>
                    </Form.Item>
                  ) : null}
                </Panel>
                <Panel header="Assign User to Asset" key="Assign User to Asset">
                  {this.state.vehicle_assignments.length > 0 && (
                    <>
                      <table className="table-design">
                        <thead>
                          <tr className="tr-design">
                            <th
                              className="th-design"
                              style={{ textAlign: "center" }}
                            >
                              {" "}
                              Asset Name
                            </th>

                            <th
                              className="th-design"
                              style={{ textAlign: "center" }}
                            >
                              Vehicle Load
                            </th>
                            <th
                              className="th-design"
                              style={{ textAlign: "center" }}
                            >
                              Assigned User
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.vehicle_assignments.map((asset) => (
                            <tr className="tr-design" key={asset.vehicle_id}>
                              <td
                                className="td-design"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Tag color={colors[50]}>
                                  {asset.vehicle_name}
                                </Tag>
                              </td>
                              <td
                                className="td-design"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Tag color={colors[50]}>
                                  {asset.vehicle_load}
                                </Tag>
                              </td>
                              <td
                                className="td-design"
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <Select
                                  showSearch={true}
                                  filterOption={false}
                                  showArrow={false}
                                  autoClearSearchValue={false}
                                  onSearch={this.searchUser}
                                  style={{ maxWidth: 200 }}
                                  onChange={(value) =>
                                    this.setAssignedUser(asset, value)
                                  }
                                >
                                  {this.state.userData.map((r) => {
                                    return (
                                      <Option
                                        key={r._id.$oid}
                                      >{`${r.name}>${r.mobile}`}</Option>
                                    );
                                  })}
                                </Select>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {this.state.vehicle_assignments.length > 0 ? (
                        <Form.Item>
                          <Button
                            style={{ marginTop: 15, width: "25%" }}
                            className="login-form-button"
                            htmlType="submit"
                            onClick={this.generateTask}
                          >
                            Generate Task
                          </Button>
                        </Form.Item>
                      ) : null}
                    </>
                  )}
                </Panel>
              </Collapse>
            </div>
          </div>
        </Form>
      </Card>
    );
  }
}
