import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Modal } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
// import moment from "moment";
// import FileSaver from "file-saver";

import { CREATE_ROLE, EDIT_ROLE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
// import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import CreateRole from "../role/createRole";

import "../login.css";

const { Column } = Table;

class RoleList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    showRoleModal: false,
  };
  componentDidMount() {
    this.props.getUserData();
    this.getRoleList();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getRoleList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ROLE}?limit=30&page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from role list", response);
          this.setState({
            data:
              this.state.page === 0
                ? response.data.data
                : [...this.state.data, ...response.data.data],
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from role list api", error);
        });
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getRoleList();
      }
    );
  };

  deleteRole = (id) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_ROLE}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from del", error);
        });
    });
  };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.roles_and_permission
          .roles_and_permission_create ? (
          <div
            style={{
              paddingBottom: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="login-form-button"
              style={{
                width: 200,
                fontSize: "medium",
                margin: 0,
                marginRight: 5,
                height: 40,
              }}
              onClick={() => this.setState({ showRoleModal: true })}
            >
              Create New Role
            </Button>
          </div>
        ) : null}

        <PageHeader title="Roles" />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Role Name" dataIndex="name" key="name" />
          <Column
            title="Role Description"
            dataIndex="description"
            key="description"
          />
          <Column
            title="Created On"
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.roles_and_permission
                  .add_or_update_permissions_to_roles ? (
                  <Link
                    to={{
                      pathname: "/managePermission",
                      state: { role_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Manage Permisssions
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.roles_and_permission.roles_and_permission_update &&(record.name !== "org_admin"&&record.name !== "default_user_role" ) ? (
                  <Link
                    to={{
                      pathname: "/editRole",
                      state: { role_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
                {/* {this.props.userProfileData.roles_and_permission.roles_and_permission_delete?(
                    <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteRole(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ):null} */}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>
            <Button
              style={{ color: "white", backgroundColor: "#0d98ad" }}
              onClick={this.handleLoadMore}
            >
              Load More ...
            </Button>
          </div>
        ) : null}

        <Modal
          title="Create New Role"
          visible={this.state.showRoleModal}
          onOk={() => {
            this.setState({ showRoleModal: false });
            this.getRoleList();
          }}
          onCancel={() => {
            this.setState({ showRoleModal: false });
            this.getRoleList();
          }}
          width={window.innerWidth - 200}
          closable={true}
          destroyOnClose={true}
          footer={null}
        >
          <CreateRole />
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(RoleList);
