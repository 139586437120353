import React, { Component } from "react";
import { Card, Form, Button, Typography, PageHeader, Select, Tag } from "antd";
import axios from "axios";
import "leaflet/dist/leaflet.css";
import { Map, Marker, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import swal from "sweetalert2";
import {
  EDIT_ENTITY,
  CREATE_ENTITY,
  SUB_ORGS_WITH_USER_ROLE_PERMISSION,
  OPTIMIZED_ROUTES,
} from "../../api/constants";
import { GET_ACCESS_TOKEN } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";
import { debounce } from "lodash";

import "../login.css";

const { Text } = Typography;
const { Option } = Select;

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const userIcon = new L.Icon({
  iconUrl: require("../../assets/store.svg"),
  iconSize: [35, 50],
});

export default class OptimizedRoute extends Component {
  state = {
    entity_id: "",
    entity_name: "",
    name: "",
    id:"",
    data: [],
    selected_entity_display: [],
    zoom: 13,
    center_lat: 23.685,
    center_lon: 90.3563,
    sub_orgs: [],
    sub_org: "",
    response_entity: [],
    polylines: [],
  };

  componentDidMount() {
    this.getEntityList();
    this.getSubOrgs();
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${SUB_ORGS_WITH_USER_ROLE_PERMISSION}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("sub org list according to role", response);
          this.setState({
            sub_orgs: response.data?.data ?? [],
            sub_org:
              response.data?.data?.length > 0
                ? response.data.data[0]._id.$oid
                : "",
          });
        })
        .catch((error) => {
          console.log("error from sub org list according to role", error);
        });
    });
  };

  getEntityList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ data: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  };

  handleSearch = debounce((value) => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ENTITY}?limit=100&name=${value}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ data: response.data.data });
          console.log("data from entity list api", response);
        })
        .catch((error) => {
          console.log("error from entity list api", error);
        });
    });
  }, 500);

  setEntity = (value) => {
    this.setState({ entity_id: value.key, entity_name: value.label,response_entity:[] }, () => {
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_ENTITY}${this.state.entity_id}?access_token=${token}`;
        axios({
          method: "get",
          url: url,
        }).then((response) => {
          console.log("response fro edit supplier", response);
       
          let selected_display = {
            lat: response.data.data.loc.coordinates[1],
            lon: response.data.data.loc.coordinates[0],
            name: response.data.data.name,
            id: response.data.data._id.$oid,
          };
          this.setState({
            center_lat: response.data.data.loc.coordinates[1],
            center_lon: response.data.data.loc.coordinates[0],
            selected_entity_display: [
              ...[selected_display],
              ...this.state.selected_entity_display,
            ],
          });
        });
      });
    });
  };

  setSubOrg = (value) => {
    this.setState({ sub_org: value }, () => {
      console.log(this.state.sub_org);
    });
  };
  generateRoute = () => {
    let data = {
      points: this.state.selected_entity_display,
      sub_org: this.state.sub_org,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${OPTIMIZED_ROUTES}?access_token=${token}`;

      axios({
        method: "post",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Route inserted successfully") {
            this.setState({
              selected_entity_display: [],
              response_entity: response.data?.data?.original_points ?? [],
              polylines: response.data?.data?.polylines ?? [],
            });
          }

          console.log("optimized route", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("optimized route", error);
        });
    });
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          //   onBack={() => this.props.history.goBack()}
          title="Generate Optimized Route"
        />

        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Form.Item>
            <Text>Select Sub Org</Text>
            <Select
              value={this.state.sub_org}
              onChange={this.setSubOrg}
              placeholder="Select Sub Org"
            >
              {this.state.sub_orgs.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Text>Search Place</Text>
            <Select
              showSearch={true}
              labelInValue
              filterOption={false}
              showArrow={false}
              autoClearSearchValue={false}
              onSearch={this.handleSearch}
              value={{
                key: this.state.entity_id,
                label: this.state.entity_name,
              }}
              onChange={this.setEntity}
              placeholder="Search Place"
            >
              {this.state.data.map((r) => {
                return <Option key={r._id.$oid}>{r.name}</Option>;
              })}
            </Select>
          </Form.Item>
          {this.state.selected_entity_display.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text style={{ margin: 5 }}>
                Click on the place you want to remove
              </Text>
              {this.state.selected_entity_display.map((place) => (
                <Tag
                  style={{ margin: 5, maxWidth: "30%" }}
                  color="blue"
                  key={place.lat + place.lon}
                  onClick={() => {
                 
                    let filtered_place_display =
                      this.state.selected_entity_display.filter(
                        (p) => p.lat !== place.lat && p.lon !== place.lon
                      );
                    this.setState(
                      {
                        selected_entity_display: filtered_place_display,
                      },
                      () => console.log(this.state.selected_entity_display)
                    );
                  }}
                >
                  {place.name}
                </Tag>
              ))}
            </div>
          ) : null}
          {this.state.selected_entity_display.length > 0 ? (
            <Map
              style={{ marginBottom: 10 }}
              center={[this.state.center_lat, this.state.center_lon]}
              zoom={this.state.zoom}
              onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
            >
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                  VERSION: "3.53",
                }}
                type={"roadmap"}
              />

              {this.state.selected_entity_display.map((map) => {
                return (
                  <Marker
                    key={map.lat + map.lon}
                    position={[map.lat, map.lon]}
                    icon={userIcon}
                  >
                    <Popup>
                      <span>{map.name}</span>
                    </Popup>
                  </Marker>
                );
              })}
            </Map>
          ) : null}
          {this.state.response_entity.length > 0 ? (
            <Map
              style={{ marginBottom: 10 }}
              center={[this.state.center_lat, this.state.center_lon]}
              zoom={this.state.zoom}
              onZoomEnd={(e) => this.setState({ zoom: e.target._zoom })}
            >
              <ReactLeafletGoogleLayer
                googleMapsLoaderConf={{
                  KEY: "AIzaSyAODg0q3q--rIuqxyHmTMwoomDCpfYRI2k",
                  VERSION: "3.53",
                }}
                type={"roadmap"}
              />

              {this.state.response_entity.map((map) => {
                return (
                  <Marker
                    key={map.lat + map.lon}
                    position={[map.lat, map.lon]}
                    icon={userIcon}
                  >
                    <Popup>
                      <span>{`${map.opt_seq}.${map.name}`}</span>
                    </Popup>
                  </Marker>
                );
              })}
              {this.state.polylines.map((pl, index) => (
                <Polyline
                  key={index}
                  color="#1A3337"
                  positions={[pl.map((m) => [m[0], m[1]])]}
                />
              ))}
            </Map>
          ) : null}
          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.generateRoute}
            >
              Generate
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
