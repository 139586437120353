import React, { Component } from "react";
import {
  Card,
  Typography,
  Button,
  Table,
  Pagination,
  Select,
  Skeleton,
  Tag,
} from "antd";
import axios from "axios";
import CardMaterial from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import swal from "sweetalert2";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import {
  GET_ACCESS_TOKEN,
  //   timeConverter,
  GET_USER_LOCAL,
} from "../../utilities/utilities";
import {
  ANALYTICS_API,
  CREATE_BILL_TYPE,
  USER_PROFILE,
  CURRENT_USER_SUB_ORG_LIST,
} from "../../api/constants";
// import { debounce } from "lodash";

import { Pie } from "react-chartjs-2";
import { colors } from "../colors.js";

import "../login.css";

const { Text, Title } = Typography;
const { Column } = Table;
const { Option } = Select;
const { CheckableTag } = Tag;

class Bill extends Component {
  state = {
    start_time: moment().subtract(30, "days").format("x"),
    start_time_view: moment().subtract(30, "days"),
    end_time: moment().format("x"),
    end_time_view: moment(),
    date_to_use: "created_on",
    bill_types: [],
    bill_type: "",
    total_bills_count: 0,
    total_price_sum: 0,
    total_approved_price_sum: 0,
    approved_count: 0,
    pending_count: 0,
    bills_data: [],
    user_org_id: "",
    page: 1,
    page_size: 10,
    total: 0,
    loading: false,
    seven_days: false,
    thirty_days: false,
    last_month: false,
    this_month: false,
    items_counts: [],
    misc_bill_type_insights: [],
    bills_summery: "count",
    sub_org_id_bill_search: "",
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
  };
  componentDidMount() {
    this.getBillType();

    this.getCurrentUserSubOrgs();
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_bill_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getUserdata();
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_bill_search: value },
      () => {
        this.getUserdata();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current }, () => {
      console.log("page number", this.state.page);
      this.getBillTable();
    });
  };

  setLimit = (value) => {
    this.setState({ page_size: value }, () => {
      console.log(this.state.page_size);
      this.getBillTable();
    });
  };

  getUserdata = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user profile", response);

          this.setState(
            {
              user_org_id: response.data.organization.$oid,
            },
            () => {
              this.getBillTable();
              this.billPieChart();
            }
          );
        })
        .catch((error) => {
          console.log("error from user profile", error);
        });
    });
  };

  getBillType = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_BILL_TYPE}?limit=1000&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from bill type list", response);

          this.setState({
            bill_types: response.data.data,
          });
        })
        .catch((error) => {
          console.log("error from bill type list api", error);
        });
    });
  };

  getBillTable = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}bills/${this.state.user_org_id}?page_size=${
        this.state.page_size
      }&page=${this.state.page}&user_id=${
        GET_USER_LOCAL()._id.$oid
      }&sub_organization=${this.state.sub_org_id_bill_search}&start_date=${
        this.state.start_time
      }&end_date=${this.state.end_time}&date_to_use=${
        this.state.date_to_use
      }&bills_type=${this.state.bill_type}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from bill table chart api", response);
          this.setState({
            bills_data: response.data.data,
            total: response.data.total_bills_count,
            total_bills_count: response.data.total_bills_count ?? 0,
            total_price_sum: response.data.total_price_sum ?? 0,
            total_approved_price_sum:
              response.data.total_approved_price_sum ?? 0,
            approved_count: response.data.approved_count ?? 0,
            pending_count: response.data.pending_count ?? 0,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from bill table chart api", error);
        });
    });
  };
  billPieChart = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ANALYTICS_API}bills_insights/${this.state.user_org_id}?bills_summery=${this.state.bills_summery}&bills_type=${this.state.bill_type}&start_date=${this.state.start_time}&end_date=${this.state.end_time}&date_to_use=${this.state.date_to_use}&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("bill pie chart", response);
          this.setState({
            items_counts: response.data?.items_counts ?? [],
            misc_bill_type_insights:
              response.data?.misc_bill_type_insights ?? [],
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("bill pie chart", error);
        });
    });
  };

  setBillType = (value) => {
    this.setState({ bill_type: value }, () => {
      this.getBillTable();
      this.billPieChart();
      console.log(this.state.bill_type);
    });
  };

  handlePieClick = (event, chartElements) => {
    if (chartElements.length > 0) {
      const index = chartElements[0].index;
      const label = this.state.misc_bill_type_insights[index].name;

      this.setState({ bill_type: label }, () => {
        this.getBillTable();
        this.billPieChart();
        console.log(this.state.bill_type);
      });
    }
  };

  render() {
    if (this.state.loading === true) {
      return (
        <Skeleton
          paragraph={{
            rows: 20,
          }}
          active
        />
      );
    }
    return (
      <div style={{ marginTop: 10 }}>
        <Card>
          <div
            style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // minDate={moment().subtract(30, "days")}
                  style={{ marginTop: 15 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14,
                      width: 130,
                    },
                  }}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.start_time_view}
                  onChange={(value, dateString) => {
                    this.setState(
                      {
                        start_time: moment(value).startOf("day").format("x"),
                        start_time_view: value,
                        end_time: moment(value)
                          .add(30, "days")
                          .endOf("day")
                          .format("x"),
                        end_time_view: moment(value)
                          .add(30, "days")
                          .endOf("day"),
                      },
                      () => {
                        this.getBillTable();
                        this.billPieChart();
                      }
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 5,
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  // minDate={moment().subtract(30, "days")}
                  style={{ marginTop: 15 }}
                  inputProps={{
                    style: {
                      padding: 10,
                      height: 14,
                      width: 130,
                    },
                  }}
                  disableFuture
                  inputVariant="outlined"
                  value={this.state.end_time_view}
                  onChange={(value, dateString) => {
                    if (
                      (value - this.state.start_time_view) /
                        (1000 * 3600 * 24) >
                      30
                    ) {
                      swal.fire(
                        "Info",
                        "Date range must be within 30 days",
                        "info"
                      );
                    } else {
                      this.setState(
                        {
                          end_time: moment(value)
                            .endOf("day")
                            .add(59, "seconds")
                            .format("x"),
                          end_time_view: value,
                        },
                        () => {
                          this.getBillTable();
                          this.billPieChart();
                        }
                      );
                    }
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 5,
                marginLeft: 4,
              }}
            >
              <Text>Filter Time By : </Text>
              <Select
                value={this.state.date_to_use}
                onChange={(value) => {
                  this.setState({ date_to_use: value }, () => {
                    this.getBillTable();
                    this.billPieChart();
                    console.log(this.state.date_to_use);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="created_on">Created On</Option>
                <Option key="completed_on">Completed On</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Text>Bill Type:</Text>

              <Select
                value={this.state.bill_type}
                onChange={this.setBillType}
                style={{ minWidth: 200 }}
              >
                {this.state.bill_types.map((r) => {
                  return <Option key={r.name}>{r.name}</Option>;
                })}
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 4,
              }}
            >
              <Text>Summery Type:</Text>

              <Select
                value={this.state.bills_summery}
                onChange={(value) => {
                  this.setState({ bills_summery: value }, () => {
                    this.billPieChart();
                    console.log(this.state.bills_summery);
                  });
                }}
                style={{ minWidth: 100 }}
              >
                <Option key="count">Count</Option>
                <Option key="price">Price</Option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
              }}
            >
              <Text> User Sub Organization : {""}</Text>
              <Select
                style={{ minWidth: 200 }}
                disabled={
                  this.state.current_user_sub_orgs.length < 1 ? true : false
                }
                value={this.state.current_user_sub_org_id}
                onChange={this.setCurrentUserSubOrg}
              >
                {this.state.current_user_sub_orgs.map((r) => {
                  return <Option key={r.id}>{`${r.name}`}</Option>;
                })}
              </Select>
            </div>
            <Button
              style={{ marginTop: 18 }}
              type="link"
              onClick={() =>
                this.setState(
                  {
                    bill_type: "",
                    bills_summery: "count",
                    current_user_sub_org_id: "",
                    sub_org_id_bill_search: "",
                  },
                  () => {
                    this.getBillTable();
                    this.billPieChart();
                  }
                )
              }
            >
              Reset
            </Button>
          </div>
          <div style={{ marginLeft: 5 }}>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.seven_days ? "#1A3337" : "",
              }}
              checked={this.state.seven_days}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: checked,
                  thirty_days: false,
                  last_month: false,
                  this_month: false,
                });

                if (checked) {
                  this.setState(
                    {
                      start_time: moment().subtract(7, "days").format("x"),
                      start_time_view: moment().subtract(7, "days"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getBillTable();
                      this.billPieChart();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getBillTable();
                      this.billPieChart();
                    }
                  );
                }
              }}
            >
              Last 7 Days
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.thirty_days ? "#1A3337" : "",
              }}
              checked={this.state.thirty_days}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: checked,
                  last_month: false,
                  this_month: false,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment().subtract(30, "days").format("x"),
                      start_time_view: moment().subtract(30, "days"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getBillTable();
                      this.billPieChart();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getBillTable();
                      this.billPieChart();
                    }
                  );
                }
              }}
            >
              Last 30 Days
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.this_month ? "#1A3337" : "",
              }}
              checked={this.state.this_month}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: false,
                  last_month: false,
                  this_month: checked,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment().startOf("month").format("x"),
                      start_time_view: moment().startOf("month"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getBillTable();
                      this.billPieChart();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getBillTable();
                      this.billPieChart();
                    }
                  );
                }
              }}
            >
              Current Month
            </CheckableTag>
            <CheckableTag
              style={{
                fontSize: 15,
                padding: 5,
                border: "1px solid grey",
                backgroundColor: this.state.last_month ? "#1A3337" : "",
              }}
              checked={this.state.last_month}
              onChange={(checked) => {
                console.log(checked);
                this.setState({
                  seven_days: false,
                  thirty_days: false,
                  last_month: checked,
                  this_month: false,
                });
                if (checked) {
                  this.setState(
                    {
                      start_time: moment()
                        .subtract(1, "month")
                        .startOf("month")
                        .format("x"),
                      start_time_view: moment()
                        .subtract(1, "month")
                        .startOf("month"),
                      end_time: moment()
                        .subtract(1, "month")
                        .endOf("month")
                        .format("x"),
                      end_time_view: moment()
                        .subtract(1, "month")
                        .endOf("month"),
                    },
                    () => {
                      this.getBillTable();
                      this.billPieChart();
                    }
                  );
                } else {
                  this.setState(
                    {
                      start_time: moment().startOf("day").format("x"),
                      start_time_view: moment().startOf("day"),
                      end_time: moment().format("x"),
                      end_time_view: moment(),
                    },
                    () => {
                      this.getBillTable();
                      this.billPieChart();
                    }
                  );
                }
              }}
            >
              Last Month
            </CheckableTag>
          </div>
        </Card>
        <Card style={{ marginTop: 5 }}>
          <div
            className="cardscontainer"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[0]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Total
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[0]}` }}
                  >{`${this.state.total_bills_count}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[1]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Price
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[1]}` }}
                  >{`${this.state.total_price_sum}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[2]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Approved Price
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[2]}` }}
                  >{`${this.state.total_approved_price_sum}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[3]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Approved
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[3]}` }}
                  >{`${this.state.approved_count}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
            <CardMaterial
              className="cardsingle"
              style={{
                borderRadius: 15,
                borderLeft: `5px solid ${colors[4]}`,
                width: "50%",
                maxHeight: 150,
                margin: 5,
              }}
            >
              <CardContent>
                <div style={{ textAlign: "center" }}>
                  <Text
                    style={{ marginLeft: 10, fontSize: 25, color: "#3D3B40" }}
                  >
                    Pending
                  </Text>

                  <Title
                    style={{ marginTop: 5, color: `${colors[4]}` }}
                  >{`${this.state.pending_count}`}</Title>
                </div>
              </CardContent>
            </CardMaterial>
          </div>
        </Card>
        {/* badges end here */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginBottom: 5,
            marginTop: 5,
          }}
        >
          <Card style={{ width: "50%" }}>
            <Title level={3}>
              Bill {this.state.bills_summery === "price" ? "Price" : "Count"} by
              Item
            </Title>
            <Pie
              style={{ marginBottom: 10 }}
              data={{
                labels: this.state.items_counts.map((r) => r.items_name),
                datasets: [
                  {
                    label: "Bill Item Count",
                    data: this.state.items_counts.map((r) => r.count),
                    backgroundColor: this.state.items_counts.map(
                      (r, index) => colors[index]
                    ),
                  },
                ],
              }}
              options={{
                responsive: true,
                aspectRatio: 1.5,
              }}
            />
          </Card>
          <Card style={{ width: "50%", marginLeft: 5 }}>
            <Title level={3}>
              Bill {this.state.bills_summery === "price" ? "Price" : "Count"} by
              Type
            </Title>
            <Pie
              style={{ marginBottom: 10 }}
              data={{
                labels: this.state.misc_bill_type_insights.map((r) => r.name),
                datasets: [
                  {
                    label: "Bill Type Insights",
                    data: this.state.misc_bill_type_insights.map(
                      (r) => r.count
                    ),
                    backgroundColor: this.state.misc_bill_type_insights.map(
                      (r, index) => colors[index]
                    ),
                  },
                ],
              }}
              options={{
                responsive: true,
                aspectRatio: 1.5,
                onClick: (event, chartElements) =>
                  this.handlePieClick(event, chartElements),
              }}
            />
          </Card>
        </div>
        <Card style={{ marginTop: 5, width: "100%" }}>
          <Title level={3}>Recent Bills</Title>
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.page_size} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          <Table
            dataSource={this.state.bills_data}
            pagination={false}
            rowKey={(record) => record._id}
            size="small"
          >
            <Column
              title="Bill No."
              key="bill_no"
              render={(text, record) => (
                <span>{`${record?.bill_no ?? ""}`}</span>
              )}
            />
            <Column
              title="Name"
              key="name"
              render={(text, record) => <span>{`${record?.name ?? ""}`}</span>}
            />
            <Column
              title="Status"
              key="status"
              render={(text, record) => (
                <span>{`${record?.approved ?? ""}`}</span>
              )}
            />
            <Column
              title="Total Price"
              key="total_price"
              render={(text, record) => (
                <span>{`${record?.total_price ?? ""}`}</span>
              )}
            />
            <Column
              title="Total Approved Price"
              key="total_approved_price"
              render={(text, record) => (
                <span>{`${record?.total_approved_price ?? ""}`}</span>
              )}
            />
            <Column
              title="Created By"
              key="created_by_name"
              render={(text, record) => (
                <span>{`${record?.created_by_name ?? ""}`}</span>
              )}
            />
            <Column
              title="Created On"
              key="created_on"
              render={(text, record) => (
                <span>{`${record?.created_on ?? ""}`}</span>
              )}
            />
          </Table>
          {this.state.total > 10 ? (
            <Pagination
              pageSize={Number(this.state.page_size)}
              current={this.state.page}
              total={this.state.total}
              onChange={this.pageChange}
              style={{ marginTop: 10 }}
            />
          ) : null}
        </Card>

        {/**Tables end here */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(Bill);
