import React, { Component } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  PageHeader,
  Select,
  Tabs,
  Collapse,
} from "antd";
import axios from "axios";
import swal from "sweetalert2";
import {
  EDIT_ASSET,
  CREATE_COUNTRY,
  EDIT_COUNTRY,
  CURRENT_USER_SUB_ORG_LIST,
  ORG_IMAGE_UPLOAD,
  ASSET_STATUS_TYPE_CHOICES,
  ASSET_VEHICLE_TYPE_CHOICES,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  GET_USER_LOCAL,
  CHECK_BOOL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import "../login.css";

const { TextArea } = Input;
const { Text, Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;
const { Panel } = Collapse;

export default class EditAsset extends Component {
  state = {
    name: "",
    asset: "",
    registration_number: "",
    purchase_unit: "",
    vehicle_owners_name: "",
    vehicle_sellers_name: "",
    chasis_number: "",
    engine_number: "",
    cc: "",
    model: "",
    brand_name: "",
    body_color: "",
    fuel_used: "",
    fuel_expense_unit: "",
    maint_expense_unit: "",
    vehicle_purchase_amount: "",
    current_mileage: "",
    remarks: "",
    sub_org: "",
    maint_category: "yearly",
    maint_renewal_date: "",
    maint_renewal_date_view: moment(),
    mileage_settings: 0,

    tax_token_file: "",
    tax_token_file_thumb: "",
    tax_token_expiry_date: Date.now(),
    tax_token_expiry_date_view: moment(),
    tax_token_renewal_reminder_date: Date.now(),
    tax_token_renewal_reminder_date_view: moment(),

    fitness_document_file: "",
    fitness_document_file_thumb: "",
    fitness_expiry_date: Date.now(),
    fitness_expiry_date_view: moment(),
    fitness_document_renewal_reminder_date: Date.now(),
    fitness_document_renewal_reminder_date_view: moment(),

    route_permit_document_file: "",
    route_permit_document_file_thumb: "",
    route_permit_document_expiry_date: Date.now(),
    route_permit_document_expiry_date_view: moment(),
    route_permit_document_renewal_reminder_date: Date.now(),
    route_permit_document_renewal_reminder_date_view: moment(),

    insurance_document_file: "",
    insurance_document_file_thumb: "",
    insurance_document_expiry_date: Date.now(),
    insurance_document_expiry_date_view: moment(),
    insurance_document_renewal_reminder_date: Date.now(),
    insurance_document_renewal_reminder_date_view: moment(),

    purchase_date: Date.now(),
    purchase_date_view: moment(),
    registration_date: Date.now(),
    registration_date_view: moment(),
    user_id: "",
    loc: { coordinates: [90.3563, 23.685] },
    address: "",
    city: "",
    country: "",
    connect_key: [],
    asset_id: "",
    cities: [],
    filtered: [],
    countries: [],
    city_id: "",
    country_id: "",
    loading: false,
    sub_orgs: [],
    activeTab: "1",
    imgUrl: "",
    imgFile: "",

    //new fields
    asset_variant: "",
    asset_sub_variant: "",
    asset_status: "",
    body: "",
    year: "",
    length: "",
    width: "",
    height: "",
    wheelbase: "",
    kerb_weight: "",
    doors: "",
    seating_capacity: "",
    no_of_rows: "",
    fuel_tank_capacity: "",
    load_capacity: "",
    primary_fuel_type: "",
    combined_range: "",
    hv_braking_system: "",
    battery_type: "",
    battery_capacity: "",
    elec_motor_power: "",
    range: "",
    charging_port_type: "",
    charging_time: "",
    battery_health: "",
    charging_cycle_count: "",
    ev_braking_system: "",
    dv_fuel_type: "",
    no_of_engine_cylinders: "",
    fuel_efficiency: "",
    engine_displacement: "",
    turbo_charger: "",
    no_of_fuel_tank: "",
    fuel_cost_per_1000_km: "",
    fuel_cost_per_fil: "",
    add_blue_system: "",
    dpf_status: "",
    egr_system: "",
    dv_fuel_tank_capacity: "",
    other_fuel_type: "",
    other_fuel_tank_capacity: "",
    fueling_time: "",
    fuel_range: "",
    engine_type: "fuel",
    pressure_rating: "",
    tank_inspection_date: Date.now(),
    tank_inspection_date_view: moment(),
    asset_status_types: [],
    vehicle_types: [],
    is_inventory: false,
    asset_image:"",
    asset_image_thumb:""
  };

  componentDidMount() {
    this.countryList();
    this.getUserSubOrg();
    this.getAssetStatusTypes();
    this.getVehicleTypes();
    this.setState({ asset_id: this.props.location.state.asset_id });

    GET_ACCESS_TOKEN().then((token) => {
      let asset_id = this.props.location.state.asset_id;
      let url = `${EDIT_ASSET}${asset_id}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      }).then((response) => {
        console.log("response fro edit asset", response.data.data);
        this.setState(
          {
            name: response.data.data.name,
            asset: response.data.data.asset,
            user_id: response.data.data.user_id,
            address: response.data.data.address,
            city: response.data.data.city,
            country: response.data.data.country,
            is_inventory: response.data?.data?.is_inventory ?? false,
            registration_number: response.data.data.registration_number ?? "",
            purchase_unit: response.data.data.purchase_unit ?? "",
            vehicle_owners_name: response.data.data.vehicle_owners_name ?? "",
            vehicle_sellers_name: response.data.data.vehicle_sellers_name ?? "",
            chasis_number: response.data.data.chasis_number ?? "",
            engine_number: response.data.data.engine_number ?? "",
            cc: response.data.data.cc ?? "",
            model: response.data.data.model ?? "",
            brand_name: response.data.data.brand_name ?? "",
            body_color: response.data.data.body_color ?? "",
            fuel_used: response.data.data.fuel_used ?? "",
            fuel_expense_unit: response.data.data.fuel_expense_unit ?? "",
            maint_expense_unit: response.data.data.maint_expense_unit ?? "",
            vehicle_purchase_amount:
              response.data.data.vehicle_purchase_amount ?? "",
            current_mileage: response.data.data.current_mileage ?? "",
            remarks: response.data.data.remarks ?? "",
            sub_org: response.data.data.sub_org.id ?? "",
            maint_category: response.data.data.maint_category ?? "yearly",
            maint_renewal_date:
              response?.data?.data?.maint_renewal_date?.$date ?? "",
            maint_renewal_date_view:
              response?.data?.data?.maint_renewal_date?.$date ?? moment(),
            mileage_settings: response.data.data.mileage_settings ?? 0,

            tax_token_file: response.data.data.tax_token_file ?? "",
            tax_token_file_thumb: response.data.data.tax_token_file_thumb ?? "",
            tax_token_expiry_date:
              response?.data?.data?.tax_token_expiry_date?.$date ?? "",
            tax_token_expiry_date_view:
              response?.data?.data?.tax_token_expiry_date?.$date ?? moment(),
            tax_token_renewal_reminder_date:
              response?.data?.data?.tax_token_renewal_reminder_date?.$date ??
              "",
            tax_token_renewal_reminder_date_view:
              response?.data?.data?.tax_token_renewal_reminder_date?.$date ??
              moment(),

            fitness_document_file:
              response.data.data.fitness_document_file ?? "",
            fitness_document_file_thumb:
              response.data.data.fitness_document_file_thumb ?? "",
            fitness_expiry_date:
              response?.data?.data?.fitness_expiry_date?.$date ?? "",
            fitness_expiry_date_view:
              response?.data?.data?.fitness_expiry_date?.$date ?? moment(),
            fitness_document_renewal_reminder_date:
              response?.data?.data?.fitness_document_renewal_reminder_date
                ?.$date ?? "",
            fitness_document_renewal_reminder_date_view:
              response?.data?.data?.fitness_document_renewal_reminder_date
                ?.$date ?? moment(),

            route_permit_document_file:
              response.data.data.route_permit_document_file ?? "",
            route_permit_document_file_thumb:
              response.data.data.route_permit_document_file_thumb ?? "",
            route_permit_document_expiry_date:
              response?.data?.data?.route_permit_document_expiry_date?.$date ??
              "",
            route_permit_document_expiry_date_view:
              response?.data?.data?.route_permit_document_expiry_date?.$date ??
              moment(),
            route_permit_document_renewal_reminder_date:
              response?.data?.data?.route_permit_document_renewal_reminder_date
                ?.$date ?? "",
            route_permit_document_renewal_reminder_date_view:
              response?.data?.data?.route_permit_document_renewal_reminder_date
                ?.$date ?? moment(),

            insurance_document_file:
              response.data.data.insurance_document_file ?? "",
            insurance_document_file_thumb:
              response.data.data.insurance_document_file_thumb ?? "",
            insurance_document_expiry_date:
              response?.data?.data?.insurance_document_expiry_date?.$date ?? "",
            insurance_document_expiry_date_view:
              response?.data?.data?.insurance_document_expiry_date?.$date ??
              moment(),
            insurance_document_renewal_reminder_date:
              response?.data?.data?.insurance_document_renewal_reminder_date
                ?.$date ?? "",
            insurance_document_renewal_reminder_date_view:
              response?.data?.data?.insurance_document_renewal_reminder_date
                ?.$date ?? moment(),

            purchase_date: response?.data?.data?.purchase_date?.$date ?? "",
            purchase_date_view:
              response?.data?.data?.purchase_date?.$date ?? moment(),
            registration_date:
              response?.data?.data?.registration_date?.$date ?? "",
            registration_date_view:
              response?.data?.data?.registration_date?.$date ?? moment(),

            asset_variant: response?.data?.data?.asset_variant ?? "",
            asset_sub_variant: response?.data?.data?.asset_sub_variant ?? "",
            asset_status: response?.data?.data?.asset_status ?? "",
            body: response?.data?.data?.body ?? "",
            year: response?.data?.data?.year ?? "",
            length: response?.data?.data?.length ?? "",
            width: response?.data?.data?.width ?? "",
            height: response?.data?.data?.height ?? "",
            wheelbase: response?.data?.data?.wheelbase ?? "",
            kerb_weight: response?.data?.data?.kerb_weight ?? "",
            doors: response?.data?.data?.doors ?? "",
            seating_capacity: response?.data?.data?.seating_capacity ?? "",
            no_of_rows: response?.data?.data?.no_of_rows ?? "",
            fuel_tank_capacity: response?.data?.data?.fuel_tank_capacity ?? "",
            load_capacity: response?.data?.data?.load_capacity ?? "",
            primary_fuel_type: response?.data?.data?.primary_fuel_type ?? "",
            combined_range: response?.data?.data?.combined_range ?? "",
            hv_braking_system: response?.data?.data?.hv_braking_system ?? "",
            battery_type: response?.data?.data?.battery_type ?? "",
            battery_capacity: response?.data?.data?.battery_capacity ?? "",
            elec_motor_power: response?.data?.data?.elec_motor_power ?? "",
            range: response?.data?.data?.range ?? "",
            charging_port_type: response?.data?.data?.charging_port_type ?? "",
            charging_time: response?.data?.data?.charging_time ?? "",
            battery_health: response?.data?.data?.battery_health ?? "",
            charging_cycle_count:
              response?.data?.data?.charging_cycle_count ?? "",
            ev_braking_system: response?.data?.data?.ev_braking_system ?? "",
            dv_fuel_type: response?.data?.data?.dv_fuel_type ?? "",
            no_of_engine_cylinders:
              response?.data?.data?.no_of_engine_cylinders ?? "",
            fuel_efficiency: response?.data?.data?.fuel_efficiency ?? "",
            engine_displacement:
              response?.data?.data?.engine_displacement ?? "",
            turbo_charger: response?.data?.data?.turbo_charger ?? "",
            no_of_fuel_tank: response?.data?.data?.no_of_fuel_tank ?? "",
            fuel_cost_per_1000_km:
              response?.data?.data?.fuel_cost_per_1000_km ?? "",
            fuel_cost_per_fil: response?.data?.data?.fuel_cost_per_fil ?? "",
            add_blue_system: response?.data?.data?.add_blue_system ?? "",
            dpf_status: response?.data?.data?.dpf_status ?? "",
            egr_system: response?.data?.data?.egr_system ?? "",
            dv_fuel_tank_capacity:
              response?.data?.data?.dv_fuel_tank_capacity ?? "",
            other_fuel_type: response?.data?.data?.other_fuel_type ?? "",
            other_fuel_tank_capacity:
              response?.data?.data?.other_fuel_tank_capacity ?? "",
            fueling_time: response?.data?.data?.fueling_time ?? "",
            fuel_range: response?.data?.data?.fuel_range ?? "",
            engine_type: response?.data?.data?.engine_type ?? "",
            pressure_rating: response?.data?.data?.pressure_rating ?? "",
            tank_inspection_date:
              response?.data?.data?.tank_inspection_date.$date ?? Date.now(),
            tank_inspection_date_view:
              response?.data?.data?.tank_inspection_date.$date ?? moment(),
              asset_image: response?.data?.data?.asset_image ?? "",
          },
          () => {
            // GET_ACCESS_TOKEN().then((token) => {
            //   let url = `${EDIT_COUNTRY}${this.state.country_id}?access_token=${token}`;
            //   axios({
            //     method: "get",
            //     url: url,
            //   }).then((response) => {
            //     console.log("response fro edit country", response.data.data);
            //     this.setState({
            //       cities: response.data.data.cities,
            //     });
            //   });
            // });
          }
        );
      });
    });
  }

  getAssetStatusTypes = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_STATUS_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            asset_status_types: response.data.asset_status,
          });

          console.log("response from asset status types", response);
        })
        .catch((error) => {
          console.log("error from asset status types", error);
        });
    });
  };
  getVehicleTypes = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ASSET_VEHICLE_TYPE_CHOICES}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            vehicle_types: response.data.vehicle_choice,
          });

          console.log("response from vehicle types", response);
        })
        .catch((error) => {
          console.log("error from vehicle types", error);
        });
    });
  };

  getUserSubOrg = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?assigned_user_id=${
        GET_USER_LOCAL()._id.$oid
      }&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({
            sub_orgs: response.data.data,
          });

          console.log("response from current user sub org list", response);
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  countryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      let limit = 200;
      const url = `${CREATE_COUNTRY}?limit=${limit}&d=${false}&sort_by="name"&show="no"&access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ countries: response.data.data }, () => {
            console.log("dropdown", this.state.countries);
          });
        })
        .catch((error) => {
          console.log("error from country list api", error);
        });
    });
  };

  onChange = (activeTab) => {
    console.log(`onChange ${activeTab}`);
    this.setState({
      activeTab,
    });
  };

  setName = (evt) => {
    evt.preventDefault();
    this.setState({ name: evt.target.value }, () => {
      console.log(this.state.name);
    });
  };
  setAsset = (evt) => {
    evt.preventDefault();
    this.setState({ asset: evt.target.value }, () => {
      console.log(this.state.asset);
    });
  };
  setAddress = (evt) => {
    evt.preventDefault();
    this.setState({ address: evt.target.value }, () => {
      console.log(this.state.address);
    });
  };
  handleSearch = (value) => {
    const result = this.state.cities.filter((city) => {
      return city.name.toLowerCase().includes(value.toLowerCase());
    });
    console.log(result);
    this.setState({ filtered: result });
  };
  setCity = (value) => {
    this.setState({ city: value.label, city_id: value.key }, () => {
      console.log(this.state.city);
    });
  };
  setCountry = (value) => {
    this.setState({ country: value.label, country_id: value.key }, () => {
      console.log(this.state.country);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_COUNTRY}${this.state.country_id}?access_token=${token}`;

        axios({
          method: "get",
          url: url,
        }).then((response) => {
          console.log("response fro edit country", response.data.data);
          this.setState({
            cities: response.data.data.cities,
          });
        });
      });
    });
  };

  uploadAssetImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              asset_image: `${response.data.data.upload_path}`,
              asset_image_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from asset image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from asset upload", error);
        });
    });
  };

  uploadTaxImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              tax_token_file: `${response.data.data.upload_path}`,
              tax_token_file_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };
  uploadFitnessImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              fitness_document_file: `${response.data.data.upload_path}`,
              fitness_document_file_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };
  uploadRoutePermitImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              route_permit_document_file: `${response.data.data.upload_path}`,
              route_permit_document_file_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };
  uploadInsuranceImage = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const img = new FormData();

      img.append("imgFile", this.state.imgFile);

      console.log("in upload", img);

      axios({
        method: "put",
        url: `${ORG_IMAGE_UPLOAD}asset?access_token=${token}`,
        data: img,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          if (response.data.message === "Image successfully uploaded!") {
            this.setState({
              insurance_document_file: `${response.data.data.upload_path}`,
              insurance_document_file_thumb: `${response.data.data.upload_path}`,
            });
          }
          console.log("response from image api", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("Error from upload", error);
        });
    });
  };

  editAsset = () => {
    if (
      !this.state.name ||
      !this.state.city ||
      !this.state.country ||
      !this.state.address ||
      !this.state.registration_number ||
      !this.state.model ||
      !this.state.asset_status||
      !this.state.asset_variant
    ) {
      swal.fire(
        "info",
        "Please enter name,city,country,address,sub org,asset type,registration number,registration date,model and asset status",
        "info"
      );
    } else {
      this.setState({ loading: true });
      const data = {
        name: this.state.name,
        asset: this.state.asset,
        user_id: this.state.user_id,
        loc: this.state.loc,
        address: this.state.address,
        city: this.state.city,
        country: this.state.country,
        connect_key: this.state.connect_key,
        registration_number: this.state.registration_number,
        purchase_unit: this.state.purchase_unit,
        vehicle_owners_name: this.state.vehicle_owners_name,
        vehicle_sellers_name: this.state.vehicle_sellers_name,
        chasis_number: this.state.chasis_number,
        engine_number: this.state.engine_number,
        cc: this.state.cc,
        model: this.state.model,
        brand_name: this.state.brand_name,
        body_color: this.state.body_color,
        fuel_used: this.state.fuel_used,
        fuel_expense_unit: this.state.fuel_expense_unit,
        maint_expense_unit: this.state.maint_expense_unit,
        vehicle_purchase_amount: this.state.vehicle_purchase_amount,
        current_mileage: Number(this.state.current_mileage),
        remarks: this.state.remarks,
        sub_org: this.state.sub_org,
        maint_category: this.state.maint_category,
        maint_renewal_date: this.state.maint_renewal_date,
        mileage_settings: Number(this.state.mileage_settings),

        tax_token_file: this.state.tax_token_file,
        tax_token_file_thumb: this.state.tax_token_file_thumb,
        tax_token_expiry_date: this.state.tax_token_expiry_date,
        tax_token_renewal_reminder_date:
          this.state.tax_token_renewal_reminder_date,

        fitness_document_file: this.state.fitness_document_file,
        fitness_document_file_thumb: this.state.fitness_document_file_thumb,
        fitness_expiry_date: this.state.fitness_expiry_date,
        fitness_document_renewal_reminder_date:
          this.state.fitness_document_renewal_reminder_date,

        route_permit_document_file: this.state.route_permit_document_file,
        route_permit_document_file_thumb:
          this.state.route_permit_document_file_thumb,
        route_permit_document_expiry_date:
          this.state.route_permit_document_expiry_date,
        route_permit_document_renewal_reminder_date:
          this.state.route_permit_document_renewal_reminder_date,

        insurance_document_file: this.state.insurance_document_file,
        insurance_document_file_thumb: this.state.insurance_document_file_thumb,
        insurance_document_expiry_date:
          this.state.insurance_document_expiry_date,
        insurance_document_renewal_reminder_date:
          this.state.insurance_document_renewal_reminder_date,

        purchase_date: this.state.purchase_date,
        registration_date: this.state.registration_date,
        asset_image: this.state.asset_image,

        asset_variant: this.state.asset_variant,
        asset_sub_variant: this.state.asset_sub_variant,
        asset_status: this.state.asset_status,
        body: this.state.body,
        year: this.state.year,
        length: this.state.length,
        width: this.state.width,
        height: this.state.height,
        wheelbase: this.state.wheelbase,
        kerb_weight: this.state.kerb_weight,
        doors: this.state.doors,
        seating_capacity: this.state.seating_capacity,
        no_of_rows: this.state.no_of_rows,
        fuel_tank_capacity: this.state.fuel_tank_capacity,
        load_capacity: this.state.load_capacity,
        primary_fuel_type: this.state.primary_fuel_type,
        combined_range: this.state.combined_range,
        hv_braking_system: this.state.hv_braking_system,
        battery_type: this.state.battery_type,
        battery_capacity: this.state.battery_capacity,
        elec_motor_power: this.state.elec_motor_power,
        range: this.state.range,
        charging_port_type: this.state.charging_port_type,
        charging_time: this.state.charging_time,
        battery_health: this.state.battery_health,
        charging_cycle_count: this.state.charging_cycle_count,
        ev_braking_system: this.state.ev_braking_system,
        dv_fuel_type: this.state.dv_fuel_type,
        no_of_engine_cylinders: this.state.no_of_engine_cylinders,
        fuel_efficiency: this.state.fuel_efficiency,
        engine_displacement: this.state.engine_displacement,
        turbo_charger: this.state.turbo_charger,
        no_of_fuel_tank: this.state.no_of_fuel_tank,
        fuel_cost_per_1000_km: this.state.fuel_cost_per_1000_km,
        fuel_cost_per_fil: this.state.fuel_cost_per_fil,
        add_blue_system: this.state.add_blue_system,
        dpf_status: this.state.dpf_status,
        egr_system: this.state.egr_system,
        dv_fuel_tank_capacity: this.state.dv_fuel_tank_capacity,
        other_fuel_type: this.state.other_fuel_type,
        other_fuel_tank_capacity: this.state.other_fuel_tank_capacity,
        fueling_time: this.state.fueling_time,
        fuel_range: this.state.fuel_range,
        engine_type: this.state.engine_type,
        pressure_rating: this.state.pressure_rating,
        tank_inspection_date: this.state.tank_inspection_date,
        is_inventory: this.state.is_inventory,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${EDIT_ASSET}${this.state.asset_id}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            this.setState({ loading: false });
            swal.fire("success", response.data.message, "success");
            console.log("response from edit asset", response);
          })
          .catch((error) => {
            this.setState({ loading: false });
            swal.fire("error", error, "error");
            console.log("error from edit asset", error);
          });
      });
    }
  };
  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    const citiesList = this.state.cities.map((r) => {
      return <Option key={r.code}>{r.name}</Option>;
    });

    const FliteredList = this.state.filtered.map((r) => {
      return <Option key={r.code}>{r.name}</Option>;
    });
    return (
      <Card style={{ margin: 10 }}>
        <PageHeader
          onBack={() => this.props.history.goBack()}
          title="Update Asset (* Marked Fields are Required)"
        />
        <Form
          style={{
            display: "flex",
            alignSelf: "center",
            flexDirection: "column",
          }}
        >
          <Collapse defaultActiveKey={"General Information"}>
            <Panel header="General Information" key="General Information">
              <Form.Item>
                <Text>Name *</Text>
                <Input
                  placeholder="Name"
                  type="text"
                  value={this.state.name}
                  onChange={this.setName}
                />
              </Form.Item>
              <Form.Item>
                <Text>Asset (IMEI or Device Number)</Text>
                <Input
                  placeholder="Asset"
                  type="text"
                  value={this.state.asset}
                  onChange={this.setAsset}
                />
              </Form.Item>
              <Form.Item>
                <Text>Registration Number *</Text>
                <Input
                  placeholder="Registration Number"
                  type="text"
                  value={this.state.registration_number}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { registration_number: evt.target.value },
                      () => {
                        console.log(this.state.registration_number);
                      }
                    );
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Address *</Text>

                <TextArea
                  rows={4}
                  placeholder="Address"
                  type="text"
                  value={this.state.address}
                  onChange={this.setAddress}
                />
              </Form.Item>
              <Form.Item>
                <Text>Country *</Text>
                <Select
                  labelInValue
                  value={{
                    key: this.state.country_id,
                    label: this.state.country,
                  }}
                  onChange={this.setCountry}
                  placeholder="Select Country"
                >
                  {this.state.countries.map((r) => {
                    return <Option key={r._id.$oid}>{r.name}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Search City *</Text>

                <Select
                  labelInValue
                  showSearch={true}
                  filterOption={false}
                  showArrow={false}
                  autoClearSearchValue={false}
                  onSearch={this.handleSearch}
                  disabled={this.state.country === "" ? true : false}
                  value={{ key: this.state.city_id, label: this.state.city }}
                  onChange={this.setCity}
                  placeholder="Select City"
                >
                  {this.state.filtered.length === 0 ? citiesList : FliteredList}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Inventory</Text>
                <Select
                  value={this.state.is_inventory.toString()}
                  onChange={(value) => {
                    this.setState({ is_inventory: CHECK_BOOL(value) }, () => {
                      console.log(this.state.is_inventory);
                    });
                  }}
                >
                  <Option key={true}>True</Option>
                  <Option key={false}>False</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>Purchase Date</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.purchase_date_view}
                      onChange={(value, dateString) => {
                        this.setState(
                          {
                            purchase_date: moment(value).format("x"),
                            purchase_date_view: value,
                          },
                          () => {
                            console.log(
                              "Selected Time: ",
                              this.state.purchase_date
                            );
                            console.log(
                              "Selected value: ",
                              this.state.purchase_date_view
                            );
                          }
                        );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Text>Registration Date *</Text>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      inputVariant="outlined"
                      value={this.state.registration_date_view}
                      onChange={(value, dateString) => {
                        this.setState(
                          {
                            registration_date: moment(value).format("x"),
                            registration_date_view: value,
                          },
                          () => {
                            console.log(
                              "Selected Time: ",
                              this.state.registration_date
                            );
                            console.log(
                              "Selected value: ",
                              this.state.registration_date_view
                            );
                          }
                        );
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </Form.Item>
              <Form.Item>
                <Text>Purchase Unit</Text>
                <Input
                  placeholder="Purchase Unit"
                  type="text"
                  value={this.state.purchase_unit}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ purchase_unit: evt.target.value }, () => {
                      console.log(this.state.purchase_unit);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Vehicle Owners Name</Text>
                <Input
                  placeholder="Vehicle Owners Name"
                  type="text"
                  value={this.state.vehicle_owners_name}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { vehicle_owners_name: evt.target.value },
                      () => {
                        console.log(this.state.vehicle_owners_name);
                      }
                    );
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Vehicle Sellers Name</Text>
                <Input
                  placeholder="Vehicle Sellers Name"
                  type="text"
                  value={this.state.vehicle_sellers_name}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { vehicle_sellers_name: evt.target.value },
                      () => {
                        console.log(this.state.vehicle_sellers_name);
                      }
                    );
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Chasis Number</Text>
                <Input
                  placeholder="Chasis Number"
                  type="text"
                  value={this.state.chasis_number}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ chasis_number: evt.target.value }, () => {
                      console.log(this.state.chasis_number);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Select Sub Organization</Text>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.sub_org}
                  onChange={(value) => {
                    this.setState({ sub_org: value }, () => {
                      console.log("sub org value", this.state.sub_org);
                    });
                  }}
                  placeholder="Select Sub Organization"
                >
                  {this.state.sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                <Text>Remarks</Text>
                <Input
                  placeholder="Remarks"
                  type="text"
                  value={this.state.remarks}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ remarks: evt.target.value }, () => {
                      console.log(this.state.remarks);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Asset Status *</Text>
                <Select
                  value={this.state.asset_status}
                  onChange={(value) => {
                    this.setState({ asset_status: value }, () => {
                      console.log(
                        "asset status value",
                        this.state.asset_status
                      );
                    });
                  }}
                  placeholder="Select Country"
                >
                  {this.state.asset_status_types.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item>
                  <Text>
                    Upload Asset Image{this.state.asset_image?(<Button type="link" onClick={()=>window.open(`${process.env.REACT_APP_BASE_PATH}${this.state.asset_image}`)}>View</Button>):null}
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadAssetImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
            </Panel>
            <Panel header="Body Specs" key="Body Specs">
              <Form.Item>
                <Text>Model *</Text>
                <Input
                  placeholder="Model"
                  type="text"
                  value={this.state.model}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ model: evt.target.value }, () => {
                      console.log(this.state.model);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Brand Name</Text>
                <Input
                  placeholder="Brand Name"
                  type="text"
                  value={this.state.brand_name}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ brand_name: evt.target.value }, () => {
                      console.log(this.state.brand_name);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Body Color</Text>
                <Input
                  placeholder="Body Color"
                  type="text"
                  value={this.state.body_color}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ body_color: evt.target.value }, () => {
                      console.log(this.state.body_color);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Asset Type *</Text>
                <Select
                  value={this.state.asset_variant}
                  onChange={(value) => {
                    this.setState({ asset_variant: value }, () => {
                      console.log(
                        "asset variant value",
                        this.state.asset_variant
                      );
                    });
                  }}
                >
                  {this.state.vehicle_types.map((r) => {
                    return <Option key={r}>{r}</Option>;
                  })}
                </Select>
              </Form.Item>

              {/* <Form.Item>
                <Text>Asset Sub Variant</Text>
                <Input
                  placeholder="Asset Sub Variant"
                  type="text"
                  value={this.state.asset_sub_variant}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { asset_sub_variant: evt.target.value },
                      () => {
                        console.log(this.state.asset_sub_variant);
                      }
                    );
                  }}
                />
              </Form.Item> */}

              <Form.Item>
                <Text>Body</Text>
                <Input
                  placeholder="Body"
                  type="text"
                  value={this.state.body}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ body: evt.target.value }, () => {
                      console.log(this.state.body);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Year</Text>
                <Input
                  placeholder="Year"
                  type="text"
                  value={this.state.year}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ year: evt.target.value }, () => {
                      console.log(this.state.year);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Length</Text>
                <Input
                  placeholder="Length"
                  type="text"
                  value={this.state.length}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ length: evt.target.value }, () => {
                      console.log(this.state.length);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Width</Text>
                <Input
                  placeholder="Width"
                  type="text"
                  value={this.state.width}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ width: evt.target.value }, () => {
                      console.log(this.state.width);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Height</Text>
                <Input
                  placeholder="Height"
                  type="text"
                  value={this.state.height}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ height: evt.target.value }, () => {
                      console.log(this.state.height);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Wheelbase</Text>
                <Input
                  placeholder="Wheelbase"
                  type="text"
                  value={this.state.wheelbase}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ wheelbase: evt.target.value }, () => {
                      console.log(this.state.wheelbase);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Kerb Weight</Text>
                <Input
                  placeholder="Kerb Weight"
                  type="text"
                  value={this.state.kerb_weight}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ kerb_weight: evt.target.value }, () => {
                      console.log(this.state.kerb_weight);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Doors</Text>
                <Input
                  placeholder="Doors"
                  type="text"
                  value={this.state.doors}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ doors: evt.target.value }, () => {
                      console.log(this.state.doors);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Seating Capacity</Text>
                <Input
                  placeholder="Seating Capacity"
                  type="text"
                  value={this.state.seating_capacity}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { seating_capacity: evt.target.value },
                      () => {
                        console.log(this.state.seating_capacity);
                      }
                    );
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>No. of Rows</Text>
                <Input
                  placeholder="No. of Rows"
                  type="text"
                  value={this.state.no_of_rows}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ no_of_rows: evt.target.value }, () => {
                      console.log(this.state.no_of_rows);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Load Capacity</Text>
                <Input
                  placeholder="Load Capacity"
                  type="text"
                  value={this.state.load_capacity}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ load_capacity: evt.target.value }, () => {
                      console.log(this.state.load_capacity);
                    });
                  }}
                />
              </Form.Item>
            </Panel>
            <Panel
              header="Engine, Battery and Fuel Specs"
              key="Engine, Battery and Fuel Specs"
            >
              <Form.Item>
                <Text>Engine Type</Text>
                <Select
                  value={this.state.engine_type}
                  onChange={(value) => this.setState({ engine_type: value })}
                >
                  <Option key="fuel">Fuel</Option>
                  <Option key="electric ">Electric</Option>
                  <Option key="hybrid">Hybrid</Option>
                  <Option key="cng">CNG</Option>
                </Select>
              </Form.Item>
              {this.state.engine_type === "hybrid" ? (
                <>
                  <Form.Item>
                    <Text>Primary Fuel Type</Text>
                    <Input
                      placeholder="Primary Fuel Type"
                      type="text"
                      value={this.state.primary_fuel_type}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { primary_fuel_type: evt.target.value },
                          () => {
                            console.log(this.state.primary_fuel_type);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Combined Range</Text>
                    <Input
                      placeholder="Combined Range"
                      type="text"
                      value={this.state.combined_range}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { combined_range: evt.target.value },
                          () => {
                            console.log(this.state.combined_range);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                </>
              ) : null}
              {this.state.engine_type === "electric" ||
              this.state.engine_type === "hybrid" ? (
                <>
                  <Form.Item>
                    <Text>Battery Type</Text>
                    <Input
                      placeholder="Battery Type"
                      type="text"
                      value={this.state.battery_type}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { battery_type: evt.target.value },
                          () => {
                            console.log(this.state.battery_type);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Battery Capacity</Text>
                    <Input
                      placeholder="Battery Capacity"
                      type="text"
                      value={this.state.battery_capacity}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { battery_capacity: evt.target.value },
                          () => {
                            console.log(this.state.battery_capacity);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Elec Motor Power</Text>
                    <Input
                      placeholder="Elec Motor Power"
                      type="text"
                      value={this.state.elec_motor_power}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { elec_motor_power: evt.target.value },
                          () => {
                            console.log(this.state.elec_motor_power);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Range</Text>
                    <Input
                      placeholder="Range"
                      type="text"
                      value={this.state.range}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState({ range: evt.target.value }, () => {
                          console.log(this.state.range);
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Charging Port Type</Text>
                    <Input
                      placeholder="Charging Port Type"
                      type="text"
                      value={this.state.charging_port_type}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { charging_port_type: evt.target.value },
                          () => {
                            console.log(this.state.charging_port_type);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Charging Time</Text>
                    <Input
                      placeholder="Charging Time"
                      type="text"
                      value={this.state.charging_time}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { charging_time: evt.target.value },
                          () => {
                            console.log(this.state.charging_time);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Battery Health</Text>
                    <Input
                      placeholder="Battery Health"
                      type="text"
                      value={this.state.battery_health}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { battery_health: evt.target.value },
                          () => {
                            console.log(this.state.battery_health);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Charging Cycle Count</Text>
                    <Input
                      placeholder="Charging Cycle Count"
                      type="text"
                      value={this.state.charging_cycle_count}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { charging_cycle_count: evt.target.value },
                          () => {
                            console.log(this.state.charging_cycle_count);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Regenerative Braking System</Text>
                    <Input
                      placeholder="Regenerative Braking System"
                      type="text"
                      value={this.state.ev_braking_system}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { ev_braking_system: evt.target.value },
                          () => {
                            console.log(this.state.ev_braking_system);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                </>
              ) : null}
              {this.state.engine_type === "fuel" ? (
                <>
                  <Form.Item>
                    <Text>DV Fuel Type</Text>
                    <Input
                      placeholder="DV Fuel Type"
                      type="text"
                      value={this.state.dv_fuel_type}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { dv_fuel_type: evt.target.value },
                          () => {
                            console.log(this.state.dv_fuel_type);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Fuel Efficiency</Text>
                    <Input
                      placeholder="Fuel Efficiency"
                      type="text"
                      value={this.state.fuel_efficiency}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { fuel_efficiency: evt.target.value },
                          () => {
                            console.log(this.state.fuel_efficiency);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Engine Displacement</Text>
                    <Input
                      placeholder="Engine Displacement"
                      type="text"
                      value={this.state.engine_displacement}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { engine_displacement: evt.target.value },
                          () => {
                            console.log(this.state.engine_displacement);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Turbo Charger</Text>
                    <Input
                      placeholder="Turbo Charger"
                      type="text"
                      value={this.state.turbo_charger}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { turbo_charger: evt.target.value },
                          () => {
                            console.log(this.state.turbo_charger);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Ad Blue System</Text>
                    <Input
                      placeholder="Ad Blue System"
                      type="text"
                      value={this.state.add_blue_system}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { add_blue_system: evt.target.value },
                          () => {
                            console.log(this.state.add_blue_system);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Diesel Particulate Filter (DPF) Status</Text>
                    <Input
                      placeholder="Diesel Particulate Filter (DPF) Status"
                      type="text"
                      value={this.state.dpf_status}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState({ dpf_status: evt.target.value }, () => {
                          console.log(this.state.dpf_status);
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Exhaust Gas Recirculation (EGR) System</Text>
                    <Input
                      placeholder="Exhaust Gas Recirculation (EGR) System"
                      type="text"
                      value={this.state.egr_system}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState({ egr_system: evt.target.value }, () => {
                          console.log(this.state.egr_system);
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>DV Fuel Tank Capacity</Text>
                    <Input
                      placeholder="DV Fuel Tank Capacity"
                      type="text"
                      value={this.state.dv_fuel_tank_capacity}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { dv_fuel_tank_capacity: evt.target.value },
                          () => {
                            console.log(this.state.dv_fuel_tank_capacity);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                </>
              ) : null}
              {this.state.engine_type === "cng" ? (
                <>
                  <Form.Item>
                    <Text>No. of Engine Cylinders</Text>
                    <Input
                      placeholder="No. of Engine Cylinders"
                      type="text"
                      value={this.state.no_of_engine_cylinders}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { no_of_engine_cylinders: evt.target.value },
                          () => {
                            console.log(this.state.no_of_engine_cylinders);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Other Fuel Type</Text>
                    <Input
                      placeholder="Other Fuel Type"
                      type="text"
                      value={this.state.other_fuel_type}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { other_fuel_type: evt.target.value },
                          () => {
                            console.log(this.state.other_fuel_type);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Other Fuel Tank Capacity</Text>
                    <Input
                      placeholder="Other Fuel Tank Capacity"
                      type="text"
                      value={this.state.other_fuel_tank_capacity}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { other_fuel_tank_capacity: evt.target.value },
                          () => {
                            console.log(this.state.other_fuel_tank_capacity);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Fueling Time</Text>
                    <Input
                      placeholder="Fueling Time"
                      type="text"
                      value={this.state.fueling_time}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { fueling_time: evt.target.value },
                          () => {
                            console.log(this.state.fueling_time);
                          }
                        );
                      }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Text>Fuel Range</Text>
                    <Input
                      placeholder="Fuel Range"
                      type="text"
                      value={this.state.fuel_range}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState({ fuel_range: evt.target.value }, () => {
                          console.log(this.state.fuel_range);
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Pressure Rating</Text>
                    <Input
                      placeholder="Pressure Rating"
                      type="text"
                      value={this.state.pressure_rating}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { pressure_rating: evt.target.value },
                          () => {
                            console.log(this.state.pressure_rating);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Text>Tank Inspection Date</Text>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          inputVariant="outlined"
                          value={this.state.tank_inspection_date_view}
                          onChange={(value, dateString) => {
                            this.setState(
                              {
                                tank_inspection_date: moment(value).format("x"),
                                tank_inspection_date_view: value,
                              },
                              () => {
                                console.log(
                                  "Selected Time: ",
                                  this.state.tank_inspection_date
                                );
                                console.log(
                                  "Selected value: ",
                                  this.state.tank_inspection_date_view
                                );
                              }
                            );
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Form.Item>
                </>
              ) : null}
              {this.state.engine_type === "fuel" ||
              this.state.engine_type === "cng" ||
              this.state.engine_type === "hybrid" ? (
                <>
                  <Form.Item>
                    <Text>Fuel Cost per 1000 km</Text>
                    <Input
                      placeholder="Fuel Cost per 1000 km"
                      type="text"
                      value={this.state.fuel_cost_per_1000_km}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { fuel_cost_per_1000_km: evt.target.value },
                          () => {
                            console.log(this.state.fuel_cost_per_1000_km);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Text>Fuel Cost per Fill</Text>
                    <Input
                      placeholder="Fuel Cost per Fill"
                      type="text"
                      value={this.state.fuel_cost_per_fil}
                      onChange={(evt) => {
                        evt.preventDefault();
                        this.setState(
                          { fuel_cost_per_fil: evt.target.value },
                          () => {
                            console.log(this.state.fuel_cost_per_fil);
                          }
                        );
                      }}
                    />
                  </Form.Item>
                </>
              ) : null}

              <Form.Item>
                <Text>Engine Number</Text>
                <Input
                  placeholder="Engine Number"
                  type="text"
                  value={this.state.engine_number}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ engine_number: evt.target.value }, () => {
                      console.log(this.state.engine_number);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>CC</Text>
                <Input
                  placeholder="CC"
                  type="text"
                  value={this.state.cc}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ cc: evt.target.value }, () => {
                      console.log(this.state.cc);
                    });
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Vehicle Purchase Amount</Text>
                <Input
                  placeholder="Vehicle Purchase Amount"
                  type="text"
                  value={this.state.vehicle_purchase_amount}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { vehicle_purchase_amount: evt.target.value },
                      () => {
                        console.log(this.state.vehicle_purchase_amount);
                      }
                    );
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>Fuel Tank Capacity</Text>
                <Input
                  placeholder="Fuel Tank Capacity"
                  type="text"
                  value={this.state.fuel_tank_capacity}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { fuel_tank_capacity: evt.target.value },
                      () => {
                        console.log(this.state.fuel_tank_capacity);
                      }
                    );
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>HV Braking System</Text>
                <Input
                  placeholder="HV Braking System"
                  type="text"
                  value={this.state.hv_braking_system}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { hv_braking_system: evt.target.value },
                      () => {
                        console.log(this.state.hv_braking_system);
                      }
                    );
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Text>No. of Fuel Tank</Text>
                <Input
                  placeholder="No. of Fuel Tank"
                  type="text"
                  value={this.state.no_of_fuel_tank}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ no_of_fuel_tank: evt.target.value }, () => {
                      console.log(this.state.no_of_fuel_tank);
                    });
                  }}
                />
              </Form.Item>
            </Panel>
            <Panel header="Maintenance Specs" key="Maintenance Specs">
              <Form.Item>
                <Text>Fuel Used</Text>
                <Input
                  placeholder="Fuel Used"
                  type="text"
                  value={this.state.fuel_used}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ fuel_used: evt.target.value }, () => {
                      console.log(this.state.fuel_used);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Fuel Expense Unit</Text>
                <Input
                  placeholder="Fuel Expense Unit"
                  type="text"
                  value={this.state.fuel_expense_unit}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { fuel_expense_unit: evt.target.value },
                      () => {
                        console.log(this.state.fuel_expense_unit);
                      }
                    );
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Maintainance Expense Unit</Text>
                <Input
                  placeholder="Maintainance Expense Unit"
                  type="text"
                  value={this.state.maint_expense_unit}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { maint_expense_unit: evt.target.value },
                      () => {
                        console.log(this.state.maint_expense_unit);
                      }
                    );
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Current Milege</Text>
                <Input
                  placeholder="Current Milege"
                  type="number"
                  value={this.state.current_mileage}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState({ current_mileage: evt.target.value }, () => {
                      console.log(this.state.current_mileage);
                    });
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Text>Maintainance Category</Text>

                <Select
                  value={this.state.maint_category}
                  onChange={(value) => this.setState({ maint_category: value })}
                >
                  <Option key="monthly">Monthly</Option>
                  <Option key="quarterly ">Quarterly</Option>
                  <Option key="half yearly">Half yearly</Option>
                  <Option key="yearly">Yearly</Option>
                  <Option key="per mileage">Per mileage</Option>
                </Select>
              </Form.Item>
              {this.state.maint_category === "per mileage" ? null : (
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Maintainance Renewal Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.maint_renewal_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              maint_renewal_date: moment(value).format("x"),
                              maint_renewal_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.maint_renewal_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.maint_renewal_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              )}
              <Form.Item>
                <Text>Mileage Settings</Text>
                <Input
                  disabled={
                    this.state.maint_category === "per mileage" ? false : true
                  }
                  placeholder="Mileage Settings"
                  type="number"
                  value={this.state.mileage_settings}
                  onChange={(evt) => {
                    evt.preventDefault();
                    this.setState(
                      { mileage_settings: evt.target.value },
                      () => {
                        console.log(this.state.mileage_settings);
                      }
                    );
                  }}
                />
              </Form.Item>
            </Panel>
          </Collapse>

          <Card>
            <Title style={{ marginLeft: 1 }} level={4}>
              Document Specs
            </Title>
            <Tabs
              destroyInactiveTabPane={true}
              activeKey={this.state.activeTab}
              onChange={this.onChange}
            >
              <TabPane tab="Tax Token Information" key="1">
                <Form.Item>
                  <Text>
                    Upload Tax Token Image (Image must be under 100 KB){this.state.tax_token_file?(<Button type="link" onClick={()=>window.open(`${process.env.REACT_APP_BASE_PATH}${this.state.tax_token_file}`)}>View</Button>):null}
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadTaxImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Tax Token Expiry Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.tax_token_expiry_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              tax_token_expiry_date: moment(value).format("x"),
                              tax_token_expiry_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.tax_token_expiry_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.tax_token_expiry_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Tax Token Renewal Reminder Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.tax_token_renewal_reminder_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              tax_token_renewal_reminder_date:
                                moment(value).format("x"),
                              tax_token_renewal_reminder_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.tax_token_renewal_reminder_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.tax_token_renewal_reminder_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </TabPane>
              <TabPane tab="Fitness Documents" key="2">
                <Form.Item>
                  <Text>
                    Upload Fitness Document Image (Image must be under 100 KB){this.state.fitness_document_file?(<Button type="link" onClick={()=>window.open(`${process.env.REACT_APP_BASE_PATH}${this.state.fitness_document_file}`)}>View</Button>):null}
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadFitnessImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Fitness Document Expiry Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.fitness_expiry_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              fitness_expiry_date: moment(value).format("x"),
                              fitness_expiry_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.fitness_expiry_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.fitness_expiry_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Fitness Document Renewal Reminder Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={
                          this.state.fitness_document_renewal_reminder_date_view
                        }
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              fitness_document_renewal_reminder_date:
                                moment(value).format("x"),
                              fitness_document_renewal_reminder_date_view:
                                value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state
                                  .fitness_document_renewal_reminder_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state
                                  .fitness_document_renewal_reminder_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </TabPane>

              <TabPane tab="Route Permit Documents" key="3">
                <Form.Item>
                  <Text>
                    Upload Route Permit Document Image (Image must be under 100
                    KB){this.state.route_permit_document_file?(<Button type="link" onClick={()=>window.open(`${process.env.REACT_APP_BASE_PATH}${this.state.route_permit_document_file}`)}>View</Button>):null}
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadRoutePermitImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Route Permit Expiry Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={
                          this.state.route_permit_document_expiry_date_view
                        }
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              route_permit_document_expiry_date:
                                moment(value).format("x"),
                              route_permit_document_expiry_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.route_permit_document_expiry_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state
                                  .route_permit_document_expiry_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Route Permit Renewal Reminder Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={
                          this.state
                            .route_permit_document_renewal_reminder_date_view
                        }
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              route_permit_document_renewal_reminder_date:
                                moment(value).format("x"),
                              route_permit_document_renewal_reminder_date_view:
                                value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state
                                  .route_permit_document_renewal_reminder_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state
                                  .route_permit_document_renewal_reminder_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </TabPane>

              <TabPane tab="Insurance Documents" key="4">
                <Form.Item>
                  <Text>
                    Upload Insurance Document Image (Image must be under 100 KB){this.state.insurance_document_file?(<Button type="link" onClick={()=>window.open(`${process.env.REACT_APP_BASE_PATH}${this.state.insurance_document_file}`)}>View</Button>):null}
                  </Text>

                  <input
                    type="file"
                    onChange={(e) => {
                      console.log("Image file inside", this.state.imgUrl);
                      this.setState(
                        {
                          imgUrl: URL.createObjectURL(e.target.files[0]),
                          imgFile: e.target.files[0],
                        },
                        () => {
                          this.uploadInsuranceImage();
                        }
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Insurance Document Expiry Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={this.state.insurance_document_expiry_date_view}
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              insurance_document_expiry_date:
                                moment(value).format("x"),
                              insurance_document_expiry_date_view: value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state.insurance_document_expiry_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state.insurance_document_expiry_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
                <Form.Item>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text>Insurance Document Renewal Reminder Date</Text>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DateTimePicker
                        inputVariant="outlined"
                        value={
                          this.state
                            .insurance_document_renewal_reminder_date_view
                        }
                        onChange={(value, dateString) => {
                          this.setState(
                            {
                              insurance_document_renewal_reminder_date:
                                moment(value).format("x"),
                              insurance_document_renewal_reminder_date_view:
                                value,
                            },
                            () => {
                              console.log(
                                "Selected Time: ",
                                this.state
                                  .insurance_document_renewal_reminder_date
                              );
                              console.log(
                                "Selected value: ",
                                this.state
                                  .insurance_document_renewal_reminder_date_view
                              );
                            }
                          );
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Form.Item>
              </TabPane>
            </Tabs>
          </Card>

          <Form.Item>
            <Button
              className="login-form-button"
              htmlType="submit"
              onClick={this.editAsset}
            >
              Update
            </Button>
          </Form.Item>
        </Form>
      </Card>
    );
  }
}
