import React, { Component } from "react";
import { Table, Card, PageHeader, Button, Tooltip, Pagination } from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
// import swal from "sweetalert2";

import { ENTITY_TYPE } from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";

import "../login.css";

const { Column } = Table;

class EntityTypeList extends Component {
  state = {
    data: [],
    page: 0,
    limit: 10,
    total: 0,
    loading: false,
  };
  componentDidMount() {
    this.props.getUserData();
    this.getEntityTypeList();
  }

  getEntityTypeList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${ENTITY_TYPE}?page=${this.state.page}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from entity type list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from entity type list api", error);
        });
    });
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getEntityTypeList();
    });
  };

  //   deleteUnit = id => {
  //     this.setState({ loading: true });
  //     GET_ACCESS_TOKEN().then(token => {
  //       const url = `${EDIT_UNIT}${id}?access_token=${token}`;

  //       axios({
  //         method: "delete",
  //         url: url
  //       })
  //         .then(response => {
  //           this.setState({ loading: false });
  //           swal.fire("Success", response.data.message, "success");
  //           setTimeout(() => window.location.reload(), 1000);
  //           console.log("deleted", response);
  //         })
  //         .catch(error => {
  //           this.setState({ loading: false });
  //           swal.fire("Error", error, "error");
  //           console.log("error from del", error);
  //         });
  //     });
  //   };

  render() {
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.place.place_create ? (
          <Link to="/createEntityType">
            <Tooltip placement="top" title="Create Entity Type">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

        <PageHeader title="Place Types" />
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column title="Name" dataIndex="name" key="name" />
          <Column
            title="Created On"
            key="created_on"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Modified On"
            key="modified_on"
            render={(text, record) => (
              <span>{timeConverter(record.modified_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.place.place_update ? (
                  <Link
                    to={{
                      pathname: "/editEntityType",
                      state: { entity_type_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
                {/* {this.props.userProfileData.order_and_delivery.unit
                  .unit_delete ? (
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteUnit(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ) : null} */}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(EntityTypeList);
