import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { persistor } from "../redux/store/store";

import { LOGOUT } from "../api/constants";
import { GET_ACCESS_TOKEN } from "../utilities/utilities";
import { Spinner } from "./loader";

export default class Logout extends React.Component {
  state = {
    redirect: false
  };
  componentDidMount() {
    GET_ACCESS_TOKEN().then(token => {
      let url = `${LOGOUT}?access_token=${token}`;
      axios({
        method: "get",
        url: url
      })
        .then(response => {
          if (response.data.status === "logout successful") {
            persistor.pause();
            persistor.flush().then(() => {
              return persistor.purge();
            });
            console.log("from logout", response);
            localStorage.removeItem("conveyanceUser");
            localStorage.removeItem("userProfile");
            localStorage.removeItem("activeKeyParent");
            localStorage.removeItem("activeKeyInsights");
            localStorage.removeItem("activeKey");
            localStorage.removeItem("activeKeyOrder");
            localStorage.removeItem("viewasvalue");
            localStorage.removeItem("registerKey");
            localStorage.removeItem("selectedKeys");

            this.setState({ redirect: true }, () => {
              window.location.reload();
            });
          }
        })
        .catch(error => {
          persistor.pause();
          persistor.flush().then(() => {
            return persistor.purge();
          });
          console.log("logout error", error);
          localStorage.removeItem("conveyanceUser");
          localStorage.removeItem("userProfile");
          localStorage.removeItem("activeKeyParent");
          localStorage.removeItem("activeKeyInsights");
          localStorage.removeItem("activeKey");
          localStorage.removeItem("activeKeyOrder");
          localStorage.removeItem("viewasvalue");
          localStorage.removeItem("registerKey");
          localStorage.removeItem("selectedKeys");

          this.setState({ redirect: true }, () => {
            window.location.reload();
          });
        });
    });
  }
  render() {
    if (this.state.redirect === true) {
      return <Redirect to="/" />;
    }

    return <Spinner />;
  }
}
