import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import "./App.css";
import { connect } from "react-redux";
import { getUserData } from "../redux/actions/userProfileAction";
import { saveDefaultOpenSubmenu } from "../redux/actions/menuAction";
import { saveSideBarScrollPosition } from "../redux/actions/sideBarScrollAction";
import Home from "./home";
import GettingStarted from "./gettingStarted";
import About from "./about";
import CreateOrganization from "./organization/createOrganization";
import ViewOrganization from "./organization/viewOrganization";
import ChangeOrganization from "./organization/changeOrganization";
import CreateDriver from "./driver/createDriver";
import ViewUser from "./driver/viewUser";
import ConnectToOrganization from "./driver/connectToOrganization";
import ConnectAssetToUser from "./assets/connectAssetToUser";
import CreatePlan from "./plan/createPlan";
import CreateExtraPackage from "./extra package/createExtraPackage";
import CreateAsset from "./assets/createAsset";
import AssetList from "./assets/assetList";
import EditAsset from "./assets/editAsset";
import ViewAsset from "./assets/viewAsset";
import AssetLastLocation from "./assets/assetLastLocation";
import RouteHistory from "./assets/routeHistory";
import SingleAsset from "./assets/singleAsset";
import AllocatedDriverLog from "./assets/allocatedDriverLog";
import CreateService from "./service/createService";
import DriverList from "./driver/driverList";
import EditDriver from "./driver/editDriver";
import CreateBulkUser from "./driver/userBulkUpload";
import MobileHeartbeatList from "./driver/mobileHeartbeatList";
import PlanList from "./plan/planList";
import ServiceList from "./service/serviceList";
import EditOrganization from "./organization/editOrganization";
import EditService from "./service/editService";
import EditPlan from "./plan/editPlan";
import OrganizationList from "./organization/organizationList";
import SendOrgInvitaion from "./organization/sendOrgInvitation";
import CreateShop from "./shop/createShop";
import ShopList from "./shop/shopList";
import EditShop from "./shop/editShop";
import ViewShop from "./shop/viewShop";
// import EntityLink from "./shop/entityLink";
import CreateTask from "./task/createTask";
import CreateTaskType from "./task type/createTaskType";
import TaskTypeList from "./task type/taskTypeList";
import EditTaskType from "./task type/editTaskType";
// import TaskDetailsLink from "./task/taskDetailsLink";
// import LiveDashboard from "./task/liveDashboard";
import CreateSupplier from "./supplier/createSupplier";
import SupplierList from "./supplier/supplierList";
import EditSupplier from "./supplier/editSupplier";
import CreateProduct from "./product/createProduct";
import ProductList from "./product/productList";
import EditProduct from "./product/editProduct";
import ViewProduct from "./product/viewProduct";
import ProductBulkUpload from "./product/productBulkUpload";
import CreateMtype from "./mtype/createMtype";
import MtypeList from "./mtype/mtypeList";
import EditMtype from "./mtype/editMtype";
import CreateMaterial from "./material/createMaterial";
import EditMaterial from "./material/editMaterial";
import MaterialList from "./material/materialList";
import CreateOrder from "./order/createOrder";
import CreatePurchaseOrder from "./order/createPurchaseOrder";
import CreatePosOrder from "./order/createPosOrder";
import OrderList from "./order/orderList";
import PurchaseOrderList from "./order/purchaseOrderList";
import AdvancedOrderlist from "./order/advancedOrderlist";
import PromotionOrderList from "./order/promotionOrderList";
import EditMultipleOrder from "./order/editMultipleOrder";
import EditOrder from "./order/editOrder";
import CreateProcedure from "./procedure/createProcedure";
import ProcedureList from "./procedure/procedureList";
import EditProcedure from "./procedure/editProcedure";
import CreateUnit from "./unit/createUnit";
import UnitList from "./unit/unitList";
import EditUnit from "./unit/editUnit";
import CreateBill from "./bill/createBill";
import CreateBillType from "./bill/createBillType";
import EditBill from "./bill/editBill";
import BillList from "./bill/billList";
import ViewBill from "./bill/viewBill";
import MonthlyBillList from "./monthlyBill/monthlyBillList";
import CreateMonthlyBill from "./monthlyBill/createMonthlyBill";
import ViewMonthlyBill from "./monthlyBill/viewMonthlyBill";
import CreateRequisition from "./requisition/createRequisition";
import ViewRequisition from "./requisition/viewRequisition";
import EditRequisition from "./requisition/editRequisition";
import RequisitionDetails from "./requisition/requisitionDetails";
import CreateCountry from "./country/createCountry";
import CountryList from "./country/countryList";
import EditCountry from "./country/editCountry";
import SubscribeOrganizationPlan from "./organization/subscribeOrganizationPlan";
// import TaskListTab from "./task/taskListTab";
import AdvancedTaskList from "./task/advancedTaskList";
import EditTask from "./task/editTask";
import CreateRoute from "./routes/createRoute";
import RouteList from "./routes/routeList";
import EditRoute from "./routes/editRoute";
import Logout from "./logout";
import StaffBillPayment from "./monthlyBill/staffBillPayment";
import SendInvitation from "./invitation/sendInvitation";
import CreateConnection from "./connect Organization/createConnection";
import ConnectionList from "./connect Organization/connectionList";
import EditConnection from "./connect Organization/editConnection";
import ViewOrder from "./order/viewOrder";
import OrderfromCode from "./order/orderfromCode";
import OrderLink from "./order/orderLink";
import TaskFromOrder from "./order/taskFromOrder";
import CreateReturnOrder from "./order/createReturnOrder";
import AttendanceList from "./attendance/attendanceList";
import CreateAttendance from "./attendance/createAttendance";
import EditAttendance from "./attendance/editAttendance";
import ViewAttendance from "./attendance/viewAttendance";
import QuickAttendanceList from "./attendance/quickAttendanceList";
import NotificationList from "./notification/notificationList";
import ViewTask from "./task/viewTask";
import UserProfile from "./userProfile";
import CreateYoutubeLink from "./tutorial/createYoutubeLink";
import ViewYoutubeLink from "./tutorial/viewYoutubeLink";
import EditYoutubeLink from "./tutorial/editYoutubeLink";
import ContainerList from "./container/containerList";
import CreateContainer from "./container/createContainer";
import ContainerforOrder from "./container/containerforOrder";
import EditContainer from "./container/editContainer";
import ContainerDetails from "./container/containerDetails";
import CreateParcel from "./parcel/createParcel";
import ParcelList from "./parcel/parcelList";
import EditParcel from "./parcel/editParcel";
import ViewParcel from "./parcel/viewParcel";
import ParcelDetails from "./parcel/parcelDetails";
import ParcelFromOrder from "./order/parcelFromOrder";
import MultiParcelFromOrder from "./order/multiParcelFromOrder";
import CreateTracking from "./tracking/createTracking";
import ViewTracking from "./tracking/viewTracking";
import EditTracking from "./tracking/editTracking";
import ConnecttoFinder from "./assets/connecttoFinder";
import UserRoute from "./driver/userRoute";
import FuelData from "./assets/fuelData";
import CreateVisitPlan from "./visits/createVisitPlan";
import VisitPlans from "./visits/visitPlans";
import ViewPlan from "./visits/viewPlan";
import AssetReport from "./assets/assetReport";
import CreateRate from "./rate/createRate";
import EditRate from "./rate/editRate";
import RateList from "./rate/rateList";
import CreateQrcode from "./qrcode/createQrcode";
import QrcodeList from "./qrcode/qrcodeList";
import CreateTerritory from "./territory/createTerritory";
import TerritoryList from "./territory/territoryList";
import EditTerritory from "./territory/editTerritory";
import ViewTerritory from "./territory/viewTerritory";
import CreateRoasterPlan from "./roaster/createRoasterPlan";
import RoasterPlanList from "./roaster/roasterPlanList";
import EditRoasterPlan from "./roaster/editRoasterPlan";
import CreateRoaster from "./roaster/createRoaster";
import RoasterList from "./roaster/roasterList";
import EditRoaster from "./roaster/editRoaster";
import CreateCustomField from "./custom field/createCustomField";
import StartTask from "./quickTask/startTask";
import StartingFrom from "./quickTask/startingFrom";
import StartTime from "./quickTask/startTime";
import ChooseAsset from "./quickTask/chooseAsset";
import Destination from "./quickTask/destination";
import AttendanceSettings from "./settings/attendanceSettings";
import ConnectInventory from "./settings/connectInventory";
import UpdateSettings from "./settings/updateSettings";
import CreateLeave from "./leave/createLeave";
import LeaveList from "./leave/leaveList";
import ViewLeave from "./leave/viewLeave";
import EntityDetailsLink from "./shop/entityDetailsLink";
import CreateIncident from "./incident/createIncident";
import IncidentList from "./incident/incidentList";
import EditIncident from "./incident/editIncident";
import IncidentBills from "./incident/incidentBills";
import CreateReminder from "./reminder/createReminder";
import ReminderList from "./reminder/reminderList";
import CreateRole from "./role/createRole";
import RoleList from "./role/roleList";
import EditRole from "./role/editRole";
import ManagePermission from "./role/managePermission";
import CreateSubOrgType from "./subOrg/createSubOrgType";
import SubOrgTypeList from "./subOrg/subOrgTypeList";
import EditSubOrgType from "./subOrg/editSubOrgType";
import CreateSubOrg from "./subOrg/createSubOrg";
import SubOrgList from "./subOrg/subOrgList";
import EditSubOrg from "./subOrg/editSubOrg";
import ViewSubOrg from "./subOrg/viewSubOrg";
import CreateSalesTarget from "./sales target/createSalesTarget";
import SalesTargetList from "./sales target/salesTargetList";
import EditSalesTarget from "./sales target/editSalesTarget";
import CreateInventory from "./inventory/createInventory";
import InventoryList from "./inventory/inventoryList";
import ViewInventory from "./inventory/viewInventory";
import ModifyInventory from "./inventory/modifyInventory";
import InternalTransfer from "./inventory/internalTransfer";
import ViewInventoryStockHistory from "./inventory/viewInventoryStockHistory";
import ViewProductStockHistory from "./inventory/viewProductStockHistory";
import ViewClosingHistory from "./inventory/viewClosingHistory";
import OrderSettings from "./settings/orderSettings";
import SmsSettings from "./settings/smsSettings";
import EmailSettings from "./settings/emailSettings";
import InventorySettings from "./settings/inventorySettings";
import ConnectThirdParty from "./settings/connectThirdParty";
import CreateTarget from "./target/createTarget";
import CreateAchievement from "./target/createAchievement";
import TargetList from "./target/targetList";
import TargetDetails from "./target/targetDetails";
import CreateProductCategory from "./product/createProductCategory";
import ProductCategoryList from "./product/productCategoryList";
import EditProductCategory from "./product/editProductCategory";
import WalletList from "./wallet/walletList";
import FinanceReport from "./finance Report/financeReport";
import CreateCustomForm from "./custom form/createCustomForm";
import EditCustomForm from "./custom form/editCustomForm";
import AchievementList from "./target/achievementList";
import AchievementDetails from "./target/achievementDetails";
import CreatePromotion from "./promotion/createPromotion";
import PromotionList from "./promotion/promotionList";
import EditPromotion from "./promotion/editPromotion";
import WalletHistory from "./wallet/walletHistory";
import DueCollectionList from "./wallet/dueCollectionList";
import CreateBillSettings from "./bill/createBillSettings";
import EditBillSettings from "./bill/editBillSettings";
import BillSettingsList from "./bill/billSettingsList";
import PrescriptionSearchList from "./task/prescriptionSearchList";
import HandoverList from "./wallet/handoverList";
import OptimizedRoute from "./assets/optimizedRoute";
import DispatchRoute from "./assets/dispatchRoute";

import Logo from "../assets/icon.png";
import ConnectIcon from "../assets/connect.svg";

import {
  Layout,
  Icon,
  Menu,
  // Avatar,
  // Badge,
  // Dropdown,
  // Popover,
  // Spin,
  Divider,
  Typography,
  // Button,
} from "antd";
// import Divider from '@material-ui/core/Divider';
import AppBar from "@material-ui/core/AppBar";
import axios from "axios";
import swal from "sweetalert2";
import MenuMaterial from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonMaterial from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import Avatar from "@material-ui/core/Avatar";

import {
  PrivateRoute,
  LOCAL_USER_INFO,
  GET_ACCESS_TOKEN,
  // GET_USER_LOCAL,
  // TIME_DIFF
} from "../utilities/utilities";
import {
  // USER_ONLINE_LIST,
  // CREATE_TASK,
  CREATE_ORGANIZATION,
  USER_PROFILE,
  // CREATE_NOTIFICATION,
} from "../api/constants";
import ViewContainer from "./container/viewContainer";
import ReactGA from "react-ga4";
import CreateEntityType from "./shop/createEntityType";
import EntityTypeList from "./shop/entityTypeList";
import EditEntityType from "./shop/editEntityType";
// import { persistor } from "../redux/store/store";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

// const menu = (
//   <Menu style={{ position: "relative", zIndex: 1 }}>
//     <Menu.Item key="profile">
//       <Link to="/profile">
//         <Icon type="user" style={{ fontSize: 15, marginRight: 5 }} />
//         <span style={{ fontSize: 17 }}>Profile</span>
//       </Link>
//     </Menu.Item>
//     <Menu.Item key="about">
//       <Link to="/about">
//         <Icon type="info-circle" style={{ fontSize: 15, marginRight: 5 }} />
//         <span style={{ fontSize: 17 }}>About</span>
//       </Link>
//     </Menu.Item>

//     <Menu.Item key="logout">
//       <Link to="/logout">
//         <Icon type="logout" style={{ fontSize: 15, marginRight: 5 }} />
//         <span style={{ fontSize: 17 }}>Logout</span>
//       </Link>
//     </Menu.Item>
//   </Menu>
// );

class DashBoard extends React.Component {
  state = {
    collapsed: false,
    userName: "",
    isStaff: false,
    UserLocationList: [],
    collapsedUser: true,
    currentTime: "",
    taskData: [],
    smallLoading: true,
    noData: false,
    orgAvailable: true,
    org_admin: false,
    notification_page: 0,
    total_notification: [],
    is_super: false,
    menuOpen: false,
    organizations: [],
    primaryOrg: "",
    openKey: [],
    selectedKey: [],
    width: window.innerWidth,
    height: window.innerHeight,
    anchorEl: null,
    display_settings_menu: null,
    role_name: "",
  };

  componentDidMount() {
    const handleResize = () => {
      this.setState({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    var el = document.getElementById("side-bar-menu");
    el.scrollTo(0, this.props.defaultSideBarScrollPosition - 300);
    console.log("sidebar data", this.props.defaultSideBarScrollPosition - 300);

    this.props.getUserData();
    let token = LOCAL_USER_INFO();
    this.setState(
      {
        userName: token.user.name,
        isStaff: LOCAL_USER_INFO().user.is_staff,
        is_super: LOCAL_USER_INFO().user.is_super,
        currentTime: new Date().getTime(),
        openKey: this.props.defaultOpenSubmenuData,
        selectedKey: JSON.parse(localStorage.getItem("selectedKeys")),
      },
      () => {
        console.log("selected key", this.state.selectedKey);
      }
    );

    this.getUserdata();
    // this.getUserLocationList();
    this.getOrganizationList();
    // this.getNotifications();
    ReactGA.initialize("G-TDQG1KK5D1");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleSettingsClick = (event) => {
    this.setState({ display_settings_menu: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleSettingsClose = () => {
    this.setState({ display_settings_menu: null });
  };

  // getNotifications = () => {
  //   GET_ACCESS_TOKEN().then((token) => {
  //     const url = `${CREATE_NOTIFICATION}?page=${this.state.notification_page}&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url,
  //     })
  //       .then((response) => {
  //         console.log("response from notification list", response);

  //         this.setState({
  //           total_notification: response.data.data,
  //         });
  //       })
  //       .catch((error) => {
  //         console.log("error from notification list", error);
  //       });
  //   });
  // };

  getUserdata = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${USER_PROFILE}?access_token=${token}`;
      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from user profile", response);

          this.setState({
            org_admin: response.data?.org_admin ?? false,
            role_name: response.data?.role_name ?? "",
            is_super: response.data?.is_super ?? false,
            isStaff: response.data?.is_staff ?? false,
            organizations: response.data?.organizations ?? [],
            primaryOrg: response.data?.organization?.$oid ?? "",
            userName: response.data?.name ?? "",
          });
          localStorage.setItem("userProfile", JSON.stringify(response.data));
        })
        .catch((error) => {
          // persistor.pause();
          // persistor.flush().then(() => {
          //   return persistor.purge();
          // });
          // localStorage.removeItem("conveyanceUser");
          // localStorage.removeItem("userProfile");
          // localStorage.removeItem("activeKeyParent");
          // localStorage.removeItem("activeKeyInsights");
          // localStorage.removeItem("activeKey");
          // localStorage.removeItem("activeKeyOrder");
          // localStorage.removeItem("viewasvalue");
          // localStorage.removeItem("registerKey");
          // localStorage.removeItem("selectedKeys");
          window.location.reload();
          console.log("error from user profile", error);
        });
    });
  };

  getOrganizationList = () => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_ORGANIZATION}?access_token=${token}&get_connected_orgs=true`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          console.log("response from organization list", response);
          if (response.data.total === 0) {
            swal.fire(
              "Info",
              "Create Organization to Access Full Features",
              "info"
            );
            this.setState({ orgAvailable: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log("error from org list api", error);
        });
    });
  };

  // getTaskdata = user_id => {
  //   let date = new Date();
  //   let time = date.getTime();
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url = `${CREATE_TASK}?tz=${time}&assigned_to=${user_id}&assigned=true&access_token=${token}`;
  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         this.setState({ smallLoading: false });

  //         console.log("response from assigned Task list", response);
  //         if (response.data.data.length === 0) {
  //           this.setState({ noData: true });
  //         } else {
  //           this.setState({ taskData: response.data.data, noData: false });
  //         }
  //       })
  //       .catch(error => {
  //         console.log("error from assigned Task list api", error);
  //       });
  //   });
  // };

  // getUserLocationList = () => {
  //   GET_ACCESS_TOKEN().then(token => {
  //     const url = `${USER_ONLINE_LIST}?access_token=${token}&page=0&limit=100`;

  //     axios({
  //       method: "get",
  //       url: url
  //     })
  //       .then(response => {
  //         console.log(
  //           "response from organization user location list",
  //           response
  //         );

  //         this.setState({ UserLocationList: response.data.data });
  //       })
  //       .catch(error => {
  //         console.log("error from org user location list api", error);
  //       });
  //   });
  // };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  toggleUserCollapsed = () => {
    this.setState({
      collapsedUser: !this.state.collapsedUser,
    });
  };

  render() {
    const primary = this.state.organizations.filter(
      (org) => org._id.$oid === this.state.primaryOrg
    );
    return (
      <Router forceRefresh={true}>
        <Layout>
          <AppBar
            style={{
              display: "flex",
              flexDirection: "row",
              height: 48,
              backgroundColor: "white",
              minWidth: 500,
            }}
            id="area"
          >
            <div>
              <IconButton
                style={{ marginLeft: -5, marginTop: -5 }}
                disableRipple
                onClick={this.toggleCollapsed}
              >
                {this.state.collapsed ? (
                  <MenuIcon
                    style={{
                      color: "#1A3337",
                      height: 33,
                      width: 33,
                    }}
                  />
                ) : (
                  <MenuOpenIcon
                    style={{
                      color: "#1A3337",
                      height: 33,
                      width: 33,
                    }}
                  />
                )}
              </IconButton>
              {/* <Icon
                style={{ color: "#1A3337" }}
                className="icon"
                type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                onClick={this.toggleCollapsed}
              /> */}
            </div>
            <div
              className="title"
              style={{ display: "flex", marginTop: 7, marginLeft: -30 }}
            >
              <Link to="/home">
                <div>
                  <img
                    src={Logo}
                    alt=""
                    style={{ width: 33, height: 33, borderRadius: 20 }}
                  />
                </div>
              </Link>
            </div>
            <div
              style={{ paddingLeft: 10, marginTop: 12, textAlign: "center" }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: "black",
                }}
              >
                {primary.length > 0 ? primary[0]?.name?.toUpperCase() : ""}
              </Text>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "absolute",
                right: 15,
              }}
            >
              <div>
                {this.state.role_name === "org_admin" ||
                this.state.org_admin ? (
                  <Link
                    to={{
                      pathname: "/subscribeOrganizationPlan",
                      state: { org_id: this.state.primaryOrg },
                    }}
                  >
                    <ButtonMaterial
                      style={{
                        marginTop: 7,
                      }}
                    >
                      <Text
                        style={{
                          color: "#1A3337",
                          // fontWeight:"bold"
                        }}
                      >
                        Upgrade Plan
                      </Text>
                    </ButtonMaterial>
                  </Link>
                ) : null}
              </div>

              <div>
                <IconButton onClick={this.handleClick}>
                  <Avatar
                    style={{
                      backgroundColor: "#1A3337",
                      marginTop: -5,
                      height: 33,
                      width: 33,
                    }}
                  >
                    {this.state.userName ? this.state.userName.charAt(0) : ""}
                  </Avatar>
                </IconButton>
                <MenuMaterial
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  PaperProps={{
                    style: {
                      minWidth: 100,
                    },
                  }}
                >
                  {/* <Divider /> */}
                  <Link style={{ color: "#303841" }} to="/profile">
                    <MenuItem>{this.state.userName}</MenuItem>
                  </Link>
                  {/* <Divider /> */}
                  <Link style={{ color: "#303841" }} to="/about">
                    <MenuItem>About</MenuItem>
                  </Link>
                  {this.props.userProfileData.organization.organization_profile
                    .monthly_billing_view ? (
                    <Link style={{ color: "#303841" }} to="/monthlyBillList">
                      <MenuItem>Monthly Bill</MenuItem>
                    </Link>
                  ) : null}
                  {/* <MenuItem onClick={() => this.props.history.push("/logout")}>
                    Logout
                  </MenuItem> */}
                </MenuMaterial>
              </div>
              <div>
                {this.state.role_name === "org_admin" ||
                this.state.org_admin ? (
                  <Link
                    to={{
                      pathname: "/connectThirdParty",
                    }}
                  >
                    <img
                      src={ConnectIcon}
                      alt="Connect Third party"
                      style={{ width: 30, height: 30, marginTop: 8 }}
                    />
                  </Link>
                ) : null}
              </div>
              <div>
                <IconButton onClick={this.handleSettingsClick}>
                  <SettingsIcon
                    style={{
                      height: 33,
                      width: 33,
                      marginTop: -5,
                      color: "#1A3337",
                    }}
                  />
                </IconButton>
                <MenuMaterial
                  id="simple-menu"
                  anchorEl={this.state.display_settings_menu}
                  keepMounted
                  open={Boolean(this.state.display_settings_menu)}
                  onClose={this.handleSettingsClose}
                  PaperProps={{
                    style: {
                      minWidth: 100,
                    },
                  }}
                >
                  {/* {this.props.userProfileData.roles_and_permission
                    .roles_and_permission_list ? (
                    <MenuItem
                      onClick={() =>
                        this.props.history.push("/connectThirdParty")
                      }
                    >
                      Connect
                    </MenuItem>
                  ) : null} */}
                  {this.props.userProfileData.roles_and_permission
                    .roles_and_permission_list ? (
                    <Link style={{ color: "#303841" }} to="/roleList">
                      <MenuItem>Roles & Permissions</MenuItem>
                    </Link>
                  ) : null}
                  {this.props.userProfileData.custom_field
                    .custom_fields_create ? (
                    <Link style={{ color: "#303841" }} to="/createCustomField">
                      <MenuItem>Custom Field</MenuItem>
                    </Link>
                  ) : null}
                  {this.props.userProfileData.organizational_setting
                    .attendance_settings ? (
                    <Link style={{ color: "#303841" }} to="/attendanceSettings">
                      <MenuItem>Attendance</MenuItem>
                    </Link>
                  ) : null}
                  {this.props.userProfileData.organizational_setting
                    .order_settings ? (
                    <Link style={{ color: "#303841" }} to="/orderSettings">
                      <MenuItem>Order</MenuItem>
                    </Link>
                  ) : null}
                  {this.state.org_admin ||
                  this.state.isStaff ||
                  this.state.is_super ? (
                    <Link style={{ color: "#303841" }} to="/inventorySettings">
                      <MenuItem>Inventory</MenuItem>
                    </Link>
                  ) : null}
                  {/* {this.props.userProfileData.order_and_delivery.promotion
                    .promotion_create ? (
                    <MenuItem
                      onClick={() =>
                        this.props.history.push("/createPromotion")
                      }
                    >
                      New Promotion
                    </MenuItem>
                  ) : null} */}
                  {this.props.userProfileData.organizational_setting
                    .sms_settings ? (
                    <Link style={{ color: "#303841" }} to="/smsSettings">
                      <MenuItem>SMS</MenuItem>
                    </Link>
                  ) : null}

                  {this.props.userProfileData.organizational_setting
                    .email_settings ? (
                    <Link style={{ color: "#303841" }} to="/emailSettings">
                      <MenuItem>Email</MenuItem>
                    </Link>
                  ) : null}
                  {/* {this.state.org_admin ||
                    this.props.userProfileData.organizational_setting
                      .inventory_connection_settings ? (
                      <Menu.Item key="connectInventory">
                        <Link to="/connectInventory">Connect Inventory</Link>
                      </Menu.Item>
                    ) : null} */}
                  {this.state.isStaff || this.state.is_super ? (
                    <Link style={{ color: "#303841" }} to="/updateSettings">
                      <MenuItem>Update</MenuItem>
                    </Link>
                  ) : null}
                </MenuMaterial>
              </div>

              {/* <h4 style={{ marginTop: 17, color: "#1A3337" }}>
                {" "}
                {this.state.userName}{" "}
              </h4> */}

              <Link to="/logout">
                <IconButton style={{ marginTop: -5, marginLeft: -7 }}>
                  <PowerSettingsNewIcon
                    style={{
                      height: 35,
                      width: 35,
                      color: "#1A3337",
                    }}
                  />
                </IconButton>
              </Link>
            </div>
          </AppBar>
          <Layout
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              marginTop: 51,
            }}
          >
            <Sider
              trigger={null}
              collapsible
              collapsed={this.state.collapsed}
              style={{ backgroundColor: "#fff" }}
            >
              <Menu
                defaultSelectedKeys={this.state.selectedKey}
                defaultOpenKeys={this.state.openKey}
                selectedKeys={this.state.selectedKey}
                openKeys={this.state.openKey}
                onOpenChange={(openKeys) => {
                  console.log(openKeys);

                  this.setState(
                    { openKey: [openKeys[openKeys.length - 1]] },
                    () => {
                      this.props.saveDefaultOpenSubmenu(this.state.openKey);
                    }
                  );
                }}
                onSelect={({ item, key, keyPath, selectedKeys, domEvent }) => {
                  console.log("selected keys", selectedKeys);
                  localStorage.setItem(
                    "selectedKeys",
                    JSON.stringify(selectedKeys)
                  );
                }}
                onScroll={(e) => {
                  // console.log("scroll position", e.currentTarget.scrollTop);
                  let scroll = e.currentTarget.scrollTop;
                  this.props.saveSideBarScrollPosition(scroll);
                }}
                mode="inline"
                theme="light"
                className="side-bar"
                id="side-bar-menu"
                style={{
                  maxWidth: 200,
                  height: this.state.height - 50,
                  position: "fixed",
                  left: 0,
                  zIndex: 1,
                }}
              >
                <Menu.Item key="gettingStarted">
                  <Link to="/gettingStarted">
                    <Icon type="experiment" />
                    <span>Getting Started</span>
                  </Link>
                </Menu.Item>
                <hr />
                <SubMenu
                  key="create new"
                  title={
                    <span>
                      <Icon type="plus" />
                      <span>Create New</span>
                    </span>
                  }
                >
                  {this.state.org_admin ||
                  this.props.userProfileData.attendance.attendance
                    .attendance_create_for ? (
                    <Menu.Item key="createAttendance">
                      <Link to="/createAttendance">
                        <span>Attendance</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* {this.state.org_admin ||
                  this.props.userProfileData.attendance.roaster_plan
                    .roaster_plan_create ? (
                    <Menu.Item key="createRoasterPlan">
                      <Link to="/createRoasterPlan">
                        <span>Roster Plan</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.state.org_admin ||
                  this.props.userProfileData.attendance.roaster
                    .roaster_create ? (
                    <Menu.Item key="createRoaster">
                      <Link to="/createRoaster">
                        <span>Roster</span>
                      </Link>
                    </Menu.Item>
                  ) : null} */}

                  {this.props.userProfileData.attendance.leave_application
                    .leave_application_create ? (
                    <Menu.Item key="createLeave">
                      <Link to="/createLeave">
                        <span>Leave</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  <hr />
                  {this.props.userProfileData.task.task.task_create ? (
                    <Menu.Item key="startTask">
                      <Link to="/startTask">
                        <span>Quick Task</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.task.task.task_create ? (
                    <Menu.Item key="createTask">
                      <Link to="/createTask">
                        <span>Task</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* {this.props.userProfileData.task.task_type
                    .task_type_create ? (
                    <Menu.Item key="createTaskType">
                      <Link to="/createTaskType">Task Type</Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.task.route.route_create ? (
                    <Menu.Item key="createRoute">
                      <Link to="/createRoute">Route</Link>
                    </Menu.Item>
                  ) : null} */}
                  <hr />
                  {this.props.userProfileData.order_and_delivery.order
                    .order_create ? (
                    <Menu.Item key="createOrder">
                      <Link to="/createOrder">
                        <span>Order</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.order_and_delivery.order
                    .purchase_order_create ? (
                    <Menu.Item key="createPurchaseOrder">
                      <Link to="/createPurchaseOrder">
                        <span>Purchase Order</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.order_and_delivery.order
                    .pos_order_create ? (
                    <Menu.Item key="createPosOrder">
                      <Link to="/createPosOrder">
                        <span>POS Order</span>
                      </Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.order_and_delivery.order
                    .return_order_create ? (
                    <Menu.Item key="createReturnOrder">
                      <Link to="/createReturnOrder">
                        <span>Return Order</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* {this.props.userProfileData.order_and_delivery.product
                    .product_category.product_category_create ? (
                    <Menu.Item key="createProductCategory">
                      <Link to="/createProductCategory">Product Category</Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.order_and_delivery.product
                    .product_create ? (
                    <Menu.Item key="createProduct">
                      <Link to="/createProduct">Product</Link>
                    </Menu.Item>
                  ) : null} */}

                  {this.props.userProfileData.inventory.create_inventory ? (
                    <Menu.Item key="createInventory">
                      <Link to="/createInventory">
                        <span>Inventory</span>
                      </Link>
                    </Menu.Item>
                  ) : null}

                  {/* {this.props.userProfileData.order_and_delivery.territory
                    .territory_create ? (
                    <Menu.Item key="createTerritory">
                      <Link to="/createTerritory">Territory</Link>
                    </Menu.Item>
                  ) : null} */}
                  <hr />
                  {/* {this.state.org_admin || this.state.isStaff ? (
                    <Menu.Item key="createBillType">
                      <Link to="/createBillType">Bill Type</Link>
                    </Menu.Item>
                  ) : null}

                  <Menu.Item key="createBillSettings">
                    <Link to="/createBillSettings">Bill Settings</Link>
                  </Menu.Item> */}

                  {this.props.userProfileData.bill.bill.bill_create ? (
                    <Menu.Item key="createBill">
                      <Link to="/createBill">Bill</Link>
                    </Menu.Item>
                  ) : null}
                  {/* {this.props.userProfileData.bill.supplier.supplier_create ? (
                    <Menu.Item key="createSupplier">
                      <Link to="/createSupplier">Supplier</Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.bill.item_type
                    .item_type_create ? (
                    <Menu.Item key="createMtype">
                      <Link to="/createMtype">Item Type</Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.bill.item.item_create ? (
                    <Menu.Item key="createMaterial">
                      <Link to="/createMaterial">Item</Link>
                    </Menu.Item>
                  ) : null} */}

                  {/* {this.props.userProfileData.requisition.requisition_create ? (
                    <Menu.Item key="createRequisition">
                      <Link to="/createRequisition">Requisition</Link>
                    </Menu.Item>
                  ) : null} */}
                  <hr />
                  {this.props.userProfileData.asset.asset_create ? (
                    <Menu.Item key="createAsset">
                      <Link to="/createAsset">Asset</Link>
                    </Menu.Item>
                  ) : null}

                  {this.state.org_admin ||
                  this.state.isStaff ||
                  this.props.userProfileData.user.user_create ? (
                    <Menu.Item key="createUser">
                      <Link to="/createUser">User</Link>
                    </Menu.Item>
                  ) : null}
                  {this.state.org_admin ||
                  this.state.isStaff ||
                  this.props.userProfileData.user.bulk_update_user ? (
                    <Menu.Item key="createBulkUser">
                      <Link to="/createBulkUser">Bulk User</Link>
                    </Menu.Item>
                  ) : null}
                  {/*<hr/>
                  {this.props.userProfileData.incident.incident_create ? (
                    <Menu.Item key="createIncident">
                      <Link to="/createIncident">
                        <span>Incident</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  <Menu.Item key="createReminder">
                    <Link to="/createReminder">
                      <span>Reminder</span>
                    </Link>
                  </Menu.Item> */}
                  <hr />
                  {this.props.userProfileData.data_level.access_all_data ||
                  this.props.userProfileData.data_level.access_suborg_data ? (
                    <Menu.Item key="createTarget">
                      <Link to="/createTarget">
                        <span>Target</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* {this.props.userProfileData.visit_plan.visit_plan_create ? (
                    <Menu.Item key="createVisitPlan">
                      <Link to="/createVisitPlan">
                        <span>Visit Plan</span>
                      </Link>
                    </Menu.Item>
                  ) : null} */}
                </SubMenu>

                <Menu.Item key="home">
                  <Link to="/home">
                    <Icon type="area-chart" />
                    <span>Analytics</span>
                  </Link>
                </Menu.Item>
                <hr />

                <SubMenu
                  key="sub16"
                  title={
                    <span>
                      <Icon type="check" />
                      <span>Attendance</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.attendance.attendance
                    .attendance_list ? (
                    <Menu.Item key="attendanceList">
                      <Link to="/attendanceList">
                        <span>Attendance</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.attendance.quick_attendance
                    .quick_attendance_list ? (
                    <Menu.Item key="quickAttendanceList">
                      <Link to="/quickAttendanceList">
                        <span>Quick Attendance</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.attendance.roaster_plan
                    .roaster_plan_list ? (
                    <Menu.Item key="roasterPlanList">
                      <Link to="/roasterPlanList">
                        <span>Roster Plans</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.attendance.roaster
                    .roaster_list ? (
                    <Menu.Item key="roasterList">
                      <Link to="/roasterList">
                        <span>Rosters</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.attendance.leave_application
                    .leave_application_list ? (
                    <Menu.Item key="leaveList">
                      <Link to="/leaveList">
                        <span>Leaves</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>

                <hr />

                {/* {this.props.userProfileData.task.task.task_list ? (
                  <Menu.Item
                    key="liveTask"
                    onClick={() => {
                      if (process.env.NODE_ENV === "production") {
                        window.open("https://web.conveyance.app/liveTask");
                      } else {
                        window.open("http://localhost:3000/liveTask");
                      }
                    }}
                  >
                    <Icon type="border-outer" />
                    <span>Dashboard</span>
                  </Menu.Item>
                ) : null} */}

                <SubMenu
                  key="task"
                  title={
                    <span>
                      <Icon type="table" />
                      <span>Task</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.task.task_type.task_type_list ? (
                    <Menu.Item key="taskTypeList">
                      <Link to="/taskTypeList">
                        {" "}
                        <span>Task Type</span>
                      </Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.task.task.task_list ? (
                    <Menu.Item key="taskList">
                      <Link to="/taskList">
                        <span>Task</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.task.route.route_list ? (
                    <Menu.Item key="routeList">
                      <Link to="/routeList">
                        {" "}
                        <span>Route</span>
                      </Link>
                    </Menu.Item>
                  ) : null}

                  {this.state.primaryOrg === "65c460e865487463b647aa7d" ? (
                    <Menu.Item key="prescriptionSearchList">
                      <Link to="/prescriptionSearchList">
                        <Icon type="border-outer" />
                        <span>Prescription</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>

                <hr />

                {/* {this.state.isStaff || this.state.org_admin ? (
                  <SubMenu
                    key="container"
                    title={
                      <span>
                        <Icon type="car" />
                        <span>Container</span>
                      </span>
                    }
                  >
                    <Menu.Item key="createContainer">
                      <Link to="/createContainer">Create Container</Link>
                    </Menu.Item>
                    <Menu.Item key="containerList">
                      <Link to="/containerList">View Containers</Link>
                    </Menu.Item>
                    <Menu.Item key="containerforOrder">
                      <Link to="/containerforOrder">Container for Order</Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null} */}

                {/* <SubMenu
                  key="parcel"
                  title={
                    <span>
                      <Icon type="inbox" />
                      <span>Parcel</span>
                    </span>
                  }
                >
                  <Menu.Item key="createParcel">
                    <Link to="/createParcel">Create Parcel</Link>
                  </Menu.Item>
                  <Menu.Item key="parcelList">
                    <Link to="/parcelList">View Parcels</Link>
                  </Menu.Item>
                </SubMenu> */}
                <SubMenu
                  key="order"
                  title={
                    <span>
                      <Icon type="shopping-cart" />
                      <span>Order</span>
                    </span>
                  }
                >
                  {/* {this.state.isStaff || this.state.org_admin ? (
                  <Menu.Item key="multiParcelFromOrder">
                    <Link to="/multiParcelFromOrder">
                      <Icon type="code-sandbox" />
                      <span>Generate Parcels</span>
                    </Link>
                  </Menu.Item>
                ) : null} */}
                  {!this.state.primaryOrg === "634688628ec3521536fdb2cc" ? (
                    <Menu.Item key="orderfromCode">
                      <Link to="/orderfromCode">
                        <span>Connect Order</span>
                      </Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.order_and_delivery.order
                    .purchase_order_list ? (
                    <Menu.Item key="purchaseOrderList">
                      <Link to="/purchaseOrderList">
                        <span>Purchase Order</span>
                      </Link>
                    </Menu.Item>
                  ) : null}

                  {/* {this.props.userProfileData.order_and_delivery.order
                  .order_list ? (
                  <Menu.Item key="orderList">
                    <Link to="/orderList">
                      <Icon type="border-outer" />
                      <span>View Orders</span>
                    </Link>
                  </Menu.Item>
                ) : null} */}
                  {/* {this.props.userProfileData.order_and_delivery.order
                  .order_list ? (
                  <Menu.Item key="advancedOrderlist">
                    <Link to="/advancedOrderlist">
                      <Icon type="border-outer" />
                      <span>Order List</span>
                    </Link>
                  </Menu.Item>
                ) : null} */}
                  {this.props.userProfileData.order_and_delivery.order
                    .order_list ? (
                    <Menu.Item key="promotionOrderList">
                      <Link to="/promotionOrderList">
                        <span>Order</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {!this.state.primaryOrg === "634688628ec3521536fdb2cc" ? (
                    <Menu.Item key="salesTargetList">
                      <Link to="/salesTargetList">
                        <span>Sales Targets</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.order_and_delivery.wallet
                    .wallet_list ? (
                    <Menu.Item key="walletList">
                      <Link to="/walletList">
                        <span>Wallets</span>
                      </Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.due_collection
                    .due_collection_list ? (
                    <Menu.Item key="dueCollectionList">
                      <Link to="/dueCollectionList">
                        <span>Due Collections</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.handover_request
                    .handover_request_list ? (
                    <Menu.Item key="handoverList">
                      <Link to="/handoverList">
                        <span>Handover List</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.order_and_delivery.promotion
                    .promotion_list ? (
                    <Menu.Item key="promotionList">
                      <Link to="/promotionList">
                        <span>Promotions</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.order_and_delivery.territory
                    .territory_list ? (
                    <Menu.Item key="territoryList">
                      <Link to="/territoryList">Territory</Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>
                {this.props.userProfileData.order_and_delivery.product
                  .product_create ||
                this.props.userProfileData.order_and_delivery.product
                  .product_list ||
                this.props.userProfileData.order_and_delivery.product
                  .product_category.product_category_create ||
                this.props.userProfileData.order_and_delivery.product
                  .product_category.product_category_list ? (
                  <SubMenu
                    key="product"
                    title={
                      <span>
                        <Icon type="barcode" />
                        <span>Product</span>
                      </span>
                    }
                  >
                    {this.props.userProfileData.order_and_delivery.product
                      .product_category.product_category_list ? (
                      <Menu.Item key="productCategoryList">
                        <Link to="/productCategoryList">Category</Link>
                      </Menu.Item>
                    ) : null}

                    {this.props.userProfileData.order_and_delivery.product
                      .product_list ? (
                      <Menu.Item key="productList">
                        <Link to="/productList">Product</Link>
                      </Menu.Item>
                    ) : null}
                    <Menu.Item key="productBulkUpload">
                      <Link to="/productBulkUpload">Bulk Upload</Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                <SubMenu
                  key="inventory"
                  title={
                    <span>
                      <Icon type="shop" />
                      <span>Inventory</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.inventory.inventory_list ? (
                    <Menu.Item key="inventoryList">
                      <Link to="/inventoryList">
                        <span>Inventory</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.inventory
                    .inventory_internal_transfer ? (
                    <Menu.Item key="internalTransfer">
                      <Link to="/internalTransfer">
                        <span>Transfer</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>

                <hr />

                {this.props.userProfileData.bill.bill.bill_create ||
                this.props.userProfileData.bill.bill.bill_list ? (
                  <SubMenu
                    key="sub11"
                    title={
                      <span>
                        <Icon type="money-collect" />
                        <span>Bill</span>
                      </span>
                    }
                  >
                    <Menu.Item key="billSettingsList">
                      <Link to="/billSettingsList">Settings</Link>
                    </Menu.Item>

                    {this.props.userProfileData.bill.bill.bill_list ? (
                      <Menu.Item key="billList">
                        <Link to="/billList">Bill</Link>
                      </Menu.Item>
                    ) : null}
                    {this.props.userProfileData.requisition.requisition_list ? (
                      <Menu.Item key="viewRequisition">
                        <Link to="/viewRequisition">Requisition</Link>
                      </Menu.Item>
                    ) : null}
                    {this.props.userProfileData.bill.supplier.supplier_list ? (
                      <Menu.Item key="supplierList">
                        <Link to="/supplierList">Supplier</Link>
                      </Menu.Item>
                    ) : null}
                    {this.props.userProfileData.bill.item_type
                      .item_type_list ? (
                      <Menu.Item key="mtypeList">
                        <Link to="/mtypeList">Item Type</Link>
                      </Menu.Item>
                    ) : null}
                    {this.props.userProfileData.bill.item.item_list ? (
                      <Menu.Item key="materialList">
                        <Link to="/materialList">Item</Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                ) : null}

                <SubMenu
                  key="sub3"
                  title={
                    <span>
                      <Icon type="contacts" />
                      <span>Asset & User</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.asset.asset_list ? (
                    <Menu.Item key="assetList">
                      <Link to="/assetList">Asset</Link>
                    </Menu.Item>
                  ) : null}

                  <Menu.Item key="allocatedDriverLog">
                    <Link to="/allocatedDriverLog">Driver Log</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="optimizedRoute">
                    <Link to="/optimizedRoute">Optimized Route</Link>
                  </Menu.Item> */}
                  {/* <Menu.Item key="dispatchRoute">
                    <Link to="/dispatchRoute">Dispatch Route</Link>
                  </Menu.Item> */}

                  {this.props.userProfileData.asset.connect_asset_to_user ? (
                    <Menu.Item key="connectAssetToUser">
                      <Link to="/connectAssetToUser">Connect Asset</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.asset.connect_asset_to_user ? (
                    <Menu.Item key="connecttoFinder">
                      <Link to="/connecttoFinder">Connect Finder</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.report.asset_report ? (
                    <Menu.Item key="assetReport">
                      <Link to="/assetReport">Report</Link>
                    </Menu.Item>
                  ) : null}

                  {this.state.collapsed ? null : <hr />}

                  {this.state.org_admin ||
                  this.props.userProfileData.user.user_list ? (
                    <Menu.Item key="userList">
                      <Link to="/userList">User</Link>
                    </Menu.Item>
                  ) : null}
                  {/* <Menu.Item key="44">
                    <Link to="/sendInvitation">Invite User</Link>
                  </Menu.Item> */}
                  {this.state.org_admin ||
                  this.state.isStaff ||
                  this.props.userProfileData.user.connect_user_to_org ? (
                    <Menu.Item key="ConnectToOrganization">
                      <Link to="/ConnectToOrganization">Connect User</Link>
                    </Menu.Item>
                  ) : null}
                  {this.state.org_admin ||
                  this.state.isStaff ||
                  this.state.is_super ||
                  this.props.userProfileData.user.user_mobile_heartbeat_logs ? (
                    <Menu.Item key="mobileHeartbeatList">
                      <Link to="/mobileHeartbeatList">Mobile Heartbeat</Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>

                <hr />

                {this.props.userProfileData.incident.incident_list ? (
                  <Menu.Item key="incidentList">
                    <Link to="/incidentList">
                      <Icon type="tag" />
                      <span>Incidents</span>
                    </Link>
                  </Menu.Item>
                ) : null}

                <Menu.Item key="reminderList">
                  <Link to="/reminderList">
                    <Icon type="schedule" />
                    <span>Reminders</span>
                  </Link>
                </Menu.Item>

                <hr />

                <SubMenu
                  key="target"
                  title={
                    <span>
                      <Icon type="radar-chart" />
                      <span>Target</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.data_level.access_all_data ||
                  this.props.userProfileData.data_level.access_suborg_data ? (
                    <Menu.Item key="targetList">
                      <Link to="/targetList">
                        <span>Target</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.data_level.access_all_data ||
                  this.props.userProfileData.data_level.access_suborg_data ? (
                    <Menu.Item key="achievementList">
                      <Link to="/achievementList">
                        <span>Achievement</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>

                {this.props.userProfileData.visit_plan.visit_plan_list ? (
                  <Menu.Item key="visitPlans">
                    <Link to="/visitPlans">
                      <Icon type="form" />
                      <span>Visit Plan</span>
                    </Link>
                  </Menu.Item>
                ) : null}

                {/* <Menu.Item key="insights">
                  <Link to="/insights">
                    <Icon type="bar-chart" />
                    <span>Insights</span>
                  </Link>
                </Menu.Item> */}

                <hr />

                <SubMenu
                  key="sub1"
                  title={
                    <span>
                      <Icon type="plus-square" />
                      <span>Organization</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.organization.organization_profile
                    .organization_create ? (
                    <Menu.Item key="createOrganization">
                      <Link to="/createOrganization">Create Organization</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.organization.organization_profile
                    .organization_list ? (
                    <Menu.Item key="organizationList">
                      <Link to="/organizationList">Organizations</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.organization
                    .change_organization ? (
                    <Menu.Item key="changeOrganization">
                      <Link to="/changeOrganization">Change Organization</Link>
                    </Menu.Item>
                  ) : null}
                  <Menu.Item key="createConnection">
                    <Link to="/createConnection">Connect Org</Link>
                  </Menu.Item>
                  <Menu.Item key="connectionList">
                    <Link to="/connectionList">Connected Orgs</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="46">
                    <Link to="/sendOrgInvitation">Send Invitation</Link>
                  </Menu.Item> */}
                </SubMenu>

                <SubMenu
                  key="sub-org"
                  title={
                    <span>
                      <Icon type="plus-square" />
                      <span>Sub Orgs</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.sub_org.sub_org_type
                    .sub_org_type_create ? (
                    <Menu.Item key="createSubOrgType">
                      <Link to="/createSubOrgType">Create Sub Org Type</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.sub_org.sub_org_type
                    .sub_org_type_list ? (
                    <Menu.Item key="subOrgTypeList">
                      <Link to="/subOrgTypeList">Sub Org Types</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.sub_org.sub_org_create ? (
                    <Menu.Item key="createSubOrg">
                      <Link to="/createSubOrg">Create Sub Org</Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.sub_org.sub_org_list ? (
                    <Menu.Item key="subOrgList">
                      <Link to="/subOrgList">Sub Orgs</Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>
                {this.props.userProfileData.place.place_create ||
                this.props.userProfileData.place.place_list ? (
                  <SubMenu
                    key="places"
                    title={
                      <span>
                        <Icon type="shop" />
                        <span>Places</span>
                      </span>
                    }
                  >
                    {this.props.userProfileData.place.place_create ? (
                      <Menu.Item key="createEntity">
                        <Link to="/createEntity">Create Place</Link>
                      </Menu.Item>
                    ) : null}
                    {this.props.userProfileData.place.place_list ? (
                      <Menu.Item key="entityList">
                        <Link to="/entityList">View Places</Link>
                      </Menu.Item>
                    ) : null}
                      {this.props.userProfileData.place.place_create ? (
                      <Menu.Item key="createEntityType">
                        <Link to="/createEntityType">Create Place Type</Link>
                      </Menu.Item>
                    ) : null}
                      {this.props.userProfileData.place.place_list ? (
                      <Menu.Item key="entityTypeList">
                        <Link to="/entityTypeList">Place Types</Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                ) : null}

                {this.props.userProfileData.data_level.access_all_data ? (
                  <Menu.Item key="financeReport">
                    <Link to="/financeReport">
                      <Icon type="shop" />
                      <span>Finance Report</span>
                    </Link>
                  </Menu.Item>
                ) : null}
                {this.props.userProfileData.custom_form.custom_form_create ||
                this.props.userProfileData.custom_form.custom_form_list ? (
                  <Menu.Item key="createCustomForm">
                    <Link to="/createCustomForm">
                      <Icon type="shop" />
                      <span>Custom Form</span>
                    </Link>
                  </Menu.Item>
                ) : null}

                <SubMenu
                  key="Procedure"
                  title={
                    <span>
                      <Icon type="appstore" />
                      <span>Procedure</span>
                    </span>
                  }
                >
                  {this.state.org_admin ||
                  this.state.isStaff ||
                  this.props.userProfileData.procedure.procedure_create ? (
                    <Menu.Item key="createProcedure">
                      <Link to="/createProcedure">Create Procedure</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.procedure.procedure_list ? (
                    <Menu.Item key="procedureList">
                      <Link to="/procedureList">View Procedure</Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>
                {this.props.userProfileData.order_and_delivery.unit
                  .unit_create ||
                this.props.userProfileData.order_and_delivery.unit.unit_list ? (
                  <SubMenu
                    key="unit"
                    title={
                      <span>
                        <Icon type="book" />
                        <span>Unit</span>
                      </span>
                    }
                  >
                    {this.props.userProfileData.order_and_delivery.unit
                      .unit_create ? (
                      <Menu.Item key="createUnit">
                        <Link to="/createUnit">Create Unit</Link>
                      </Menu.Item>
                    ) : null}
                    {this.props.userProfileData.order_and_delivery.unit
                      .unit_list ? (
                      <Menu.Item key="unitList">
                        <Link to="/unitList">View Unit</Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>
                ) : null}

                {this.state.isStaff ? (
                  <SubMenu
                    key="sub2"
                    title={
                      <span>
                        <Icon type="solution" />
                        <span>Service & Plan</span>
                      </span>
                    }
                  >
                    <Menu.Item key="createService">
                      <Link to="/createService">Create Service</Link>
                    </Menu.Item>
                    <Menu.Item key="serviceList">
                      <Link to="/serviceList">View Service</Link>
                    </Menu.Item>
                    {this.state.collapsed ? null : (
                      <Menu.Item disabled>
                        <Divider />
                      </Menu.Item>
                    )}
                    {this.state.is_super ? (
                      <Menu.Item key="createRate">
                        <Link to="/createRate">Create Rate</Link>
                      </Menu.Item>
                    ) : null}

                    <Menu.Item key="rateList">
                      <Link to="/rateList">View Rates</Link>
                    </Menu.Item>
                    {this.state.collapsed ? null : (
                      <Menu.Item disabled>
                        <Divider />
                      </Menu.Item>
                    )}
                    <Menu.Item key="createPlan">
                      <Link to="/createPlan">Create Plan</Link>
                    </Menu.Item>
                    <Menu.Item key="planList">
                      <Link to="/planList">View Plan</Link>
                    </Menu.Item>
                    {this.state.collapsed ? null : (
                      <Menu.Item disabled>
                        <Divider />
                      </Menu.Item>
                    )}
                    <Menu.Item key="createExtraPackage">
                      <Link to="/createExtraPackage">Create Extra Pack</Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}

                {this.state.isStaff ? (
                  <SubMenu
                    key="sub14"
                    title={
                      <span>
                        <Icon type="flag" />
                        <span>Country</span>
                      </span>
                    }
                  >
                    <Menu.Item key="createCountry">
                      <Link to="/createCountry">Create Country</Link>
                    </Menu.Item>
                    <Menu.Item key="countryListcountryList">
                      <Link to="/countryList">View Country</Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}

                {/* <SubMenu
                  key="sub12"
                  title={
                    <span>
                      <Icon type="dollar" />
                      <span>Payment</span>
                    </span>
                  }
                >
                  {this.state.isStaff ? (
                    <Menu.Item key="createMonthlyBill">
                      <Link to="/createMonthlyBill">Create Monthly Bill</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.organization.organization_profile
                    .monthly_billing_view ? (
                    <Menu.Item key="monthlyBillList">
                      <Link to="/monthlyBillList">View Monthly Bill</Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu> */}

                <SubMenu
                  key="qrcode"
                  title={
                    <span>
                      <Icon type="diff" />
                      <span>QR Code</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.qr_code.qr_code_create ? (
                    <Menu.Item key="createQrcode">
                      <Link to="/createQrcode">
                        <span>Create Code</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.qr_code.qr_code_list ? (
                    <Menu.Item key="qrcodeList">
                      <Link to="/qrcodeList">
                        <span>View Codes</span>
                      </Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>

                {this.state.isStaff ? (
                  <SubMenu
                    key="tutorial"
                    title={
                      <span>
                        <Icon type="youtube" />
                        <span>Tutorial</span>
                      </span>
                    }
                  >
                    <Menu.Item key="createYoutubeLink">
                      <Link to="/createYoutubeLink">
                        <span>Create Link</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="youtubeLinkList">
                      <Link to="/youtubeLinkList">
                        <span>View Links</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                {this.state.org_admin ||
                this.props.userProfileData.organizational_setting
                  .tracking_settings ? (
                  <SubMenu
                    key="tracking"
                    title={
                      <span>
                        <Icon type="compass" />
                        <span>Tracking</span>
                      </span>
                    }
                  >
                    <Menu.Item key="createTracking">
                      <Link to="/createTracking">
                        <span>Tracking Setting</span>
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="viewTracking">
                      <Link to="/viewTracking">
                        <span>View Settings</span>
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}

                {/* {this.state.isStaff || this.state.org_admin ? (
                  <Menu.Item key="createCustomField">
                    <Link to="/createCustomField">
                      <Icon type="plus-square" />
                      <span>Custom Field</span>
                    </Link>
                  </Menu.Item>
                ) : null} */}

                {/* <SubMenu
                  key="Settings"
                  title={
                    <span>
                      <Icon type="setting" />
                      <span>Settings</span>
                    </span>
                  }
                >
                  {this.props.userProfileData.roles_and_permission
                    .roles_and_permission_list ? (
                    <Menu.Item key="connectThirdParty">
                      <Link to="/connectThirdParty">Connect</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.roles_and_permission
                    .roles_and_permission_list ? (
                    <Menu.Item key="roleList">
                      <Link to="/roleList">Roles & Permissions</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.custom_field
                    .custom_fields_create ? (
                    <Menu.Item key="createCustomField">
                      <Link to="/createCustomField">Custom Field</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.organizational_setting
                    .attendance_settings ? (
                    <Menu.Item key="attendanceSettings">
                      <Link to="/attendanceSettings">Attendance</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.organizational_setting
                    .order_settings ? (
                    <Menu.Item key="orderSettings">
                      <Link to="/orderSettings">Order</Link>
                    </Menu.Item>
                  ) : null}
                  {this.state.org_admin ||
                  this.state.isStaff ||
                  this.state.is_super ? (
                    <Menu.Item key="inventorySettings">
                      <Link to="/inventorySettings">Inventory Settings</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.order_and_delivery.promotion
                    .promotion_create ? (
                    <Menu.Item key="createPromotion">
                      <Link to="/createPromotion">New Promotion</Link>
                    </Menu.Item>
                  ) : null}
                  {this.props.userProfileData.organizational_setting
                    .sms_settings ? (
                    <Menu.Item key="smsSettings">
                      <Link to="/smsSettings">SMS</Link>
                    </Menu.Item>
                  ) : null}

                  {this.props.userProfileData.organizational_setting
                    .email_settings ? (
                    <Menu.Item key="emailSettings">
                      <Link to="/emailSettings">Email</Link>
                    </Menu.Item>
                  ) : null} */}
                {/* {this.state.org_admin ||
                    this.props.userProfileData.organizational_setting
                      .inventory_connection_settings ? (
                      <Menu.Item key="connectInventory">
                        <Link to="/connectInventory">Connect Inventory</Link>
                      </Menu.Item>
                    ) : null} */}
                {/* {this.state.isStaff || this.state.is_super ? (
                    <Menu.Item key="updateSettings">
                      <Link to="/updateSettings">Update</Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu> */}

                {/* {this.state.collapsed ? null : (
                  <Menu.Item disabled>
                    <Divider>Action</Divider>
                  </Menu.Item>
                )}

                <Menu.Item key="profile">
                  <Link to="/profile">
                    <Icon type="user" />
                    <span>Profile</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="about">
                  <Link to="/about">
                    <Icon type="info-circle" />
                    <span>About</span>
                  </Link>
                </Menu.Item>

                <Menu.Item key="logout">
                  <Link to="/logout">
                    <Icon type="logout" />
                    <span>Logout</span>
                  </Link>
                </Menu.Item> */}
              </Menu>
            </Sider>

            <Content
              style={{
                marginLeft: 2,
                overflow: "initial",
                minHeight: this.state.height,
                zIndex: 0,
              }}
            >
              <PrivateRoute
                exact
                path="/home"
                component={this.state.orgAvailable ? Home : CreateOrganization}
              />
              <PrivateRoute
                exact
                path="/gettingStarted"
                component={
                  this.state.orgAvailable ? GettingStarted : CreateOrganization
                }
              />

              <PrivateRoute exact path="/logout" component={Logout} />
              <PrivateRoute
                exact
                path="/createTask"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.task.task_create
                    ? CreateTask
                    : CreateOrganization
                }
              />
              {/* <PrivateRoute
                exact
                path="/liveTask"
                component={
                  this.state.orgAvailable ? LiveDashboard : CreateOrganization
                }
              /> */}
              <PrivateRoute
                exact
                path="/taskList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.task.task_list
                    ? AdvancedTaskList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editTask"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.task.task_update
                    ? EditTask
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewTask"
                component={
                  this.state.orgAvailable ? ViewTask : CreateOrganization
                }
              />
              {/* <PrivateRoute
                exact
                path="/taskDetailsLink/:task_id"
                component={
                  this.state.orgAvailable ? TaskDetailsLink : CreateOrganization
                }
              /> */}
              <PrivateRoute
                exact
                path="/createTaskType"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.task_type.task_type_create
                    ? CreateTaskType
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/taskTypeList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.task_type.task_type_list
                    ? TaskTypeList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editTaskType"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.task_type.task_type_update
                    ? EditTaskType
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createOrganization"
                component={CreateOrganization}
              />
              <PrivateRoute
                exact
                path="/viewOrganization"
                component={ViewOrganization}
              />
              <PrivateRoute
                exact
                path="/editOrganization"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.organization.organization_profile
                    .organization_update
                    ? EditOrganization
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/changeOrganization"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.organization.change_organization
                    ? ChangeOrganization
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/organizationList"
                component={OrganizationList}
              />
              <PrivateRoute
                exact
                path="/subscribeOrganizationPlan"
                component={
                  this.state.orgAvailable
                    ? SubscribeOrganizationPlan
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/planList"
                component={
                  this.state.orgAvailable ? PlanList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/userList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.user.user_list
                    ? DriverList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/connectToOrganization"
                component={
                  this.state.orgAvailable
                    ? ConnectToOrganization
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/connectAssetToUser"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.asset.connect_asset_to_user
                    ? ConnectAssetToUser
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createUser"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.user.user_create
                    ? CreateDriver
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewUser"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.user.user_detail
                    ? ViewUser
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createBulkUser"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.user.bulk_update_user
                    ? CreateBulkUser
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/mobileHeartbeatList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.user.user_mobile_heartbeat_logs
                    ? MobileHeartbeatList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createAsset"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.asset.asset_create
                    ? CreateAsset
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/assetList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.asset.asset_list
                    ? AssetList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/optimizedRoute"
                component={
                  this.state.orgAvailable ? OptimizedRoute : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/dispatchRoute"
                component={
                  this.state.orgAvailable ? DispatchRoute : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editAsset"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.asset.asset_update
                    ? EditAsset
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewAsset"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.asset.asset_detail
                    ? SingleAsset
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/assetLastLocation"
                component={
                  this.state.orgAvailable
                    ? AssetLastLocation
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/allocatedDriverLog"
                component={
                  this.state.orgAvailable
                    ? AllocatedDriverLog
                    : CreateOrganization
                }
              />

              <PrivateRoute
                exact
                path="/routeHistory"
                component={
                  this.state.orgAvailable ? RouteHistory : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewAsset/:asset_id"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.asset.asset_detail
                    ? ViewAsset
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editUser"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.user.user_update
                    ? EditDriver
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createService"
                component={
                  this.state.orgAvailable ? CreateService : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createSupplier"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.supplier.supplier_create
                    ? CreateSupplier
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/supplierList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.supplier.supplier_list
                    ? SupplierList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editSupplier"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.supplier.supplier_update
                    ? EditSupplier
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createProduct"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.product
                    .product_create
                    ? CreateProduct
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/productList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.product
                    .product_list
                    ? ProductList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editProduct"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.product
                    .product_update
                    ? EditProduct
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewProduct"
                component={
                  this.state.orgAvailable ? ViewProduct : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/productBulkUpload"
                component={
                  this.state.orgAvailable
                    ? ProductBulkUpload
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createMtype"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.item_type.item_type_create
                    ? CreateMtype
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/mtypeList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.item_type.item_type_list
                    ? MtypeList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editMtype"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.item_type.item_type_update
                    ? EditMtype
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createMaterial"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.item.item_create
                    ? CreateMaterial
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/materialList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.item.item_list
                    ? MaterialList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editMaterial"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.item.item_update
                    ? EditMaterial
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createOrder"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.order
                    .order_create
                    ? CreateOrder
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createPurchaseOrder"
                component={
                  this.state.orgAvailable
                    ? CreatePurchaseOrder
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createPosOrder"
                component={
                  this.state.orgAvailable ? CreatePosOrder : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/orderList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.order.order_list
                    ? OrderList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/promotionOrderList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.order.order_list
                    ? PromotionOrderList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editMultipleOrder"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.order.order_list
                    ? EditMultipleOrder
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/purchaseOrderList"
                component={
                  this.state.orgAvailable
                    ? PurchaseOrderList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/advancedOrderlist"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.order.order_list
                    ? AdvancedOrderlist
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editOrder"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.order
                    .order_update
                    ? EditOrder
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewOrder"
                component={
                  this.state.orgAvailable ? ViewOrder : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/ViewOrder/:order_id"
                component={
                  this.state.orgAvailable ? OrderLink : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/orderfromCode"
                component={
                  this.state.orgAvailable ? OrderfromCode : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createProcedure"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.procedure.procedure_create
                    ? CreateProcedure
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/procedureList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.procedure.procedure_list
                    ? ProcedureList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editProcedure"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.procedure.procedure_update
                    ? EditProcedure
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createUnit"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.unit.unit_create
                    ? CreateUnit
                    : CreateOrganization
                }
              />
              <PrivateRoute exact path="/about" component={About} />
              <PrivateRoute
                exact
                path="/unitList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.unit.unit_list
                    ? UnitList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editUnit"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.unit.unit_update
                    ? EditUnit
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createEntity"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.place.place_create
                    ? CreateShop
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createEntityType"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.place.place_create
                    ? CreateEntityType
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/entityList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.place.place_list
                    ? ShopList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/entityTypeList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.place.place_list
                    ? EntityTypeList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editEntity"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.place.place_update
                    ? EditShop
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editEntityType"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.place.place_update
                    ? EditEntityType
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewEntity"
                component={
                  this.state.orgAvailable ? ViewShop : CreateOrganization
                }
              />
              {/* <PrivateRoute
                exact
                path="/ViewEntity/:shop_id"
                component={
                  this.state.orgAvailable ? EntityLink : CreateOrganization
                }
              /> */}
              <PrivateRoute
                exact
                path="/EntityDetails/:shop_id"
                component={
                  this.state.orgAvailable
                    ? EntityDetailsLink
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/serviceList"
                component={
                  this.state.orgAvailable ? ServiceList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editService"
                component={
                  this.state.orgAvailable ? EditService : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editPlan"
                component={
                  this.state.orgAvailable ? EditPlan : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createPlan"
                component={
                  this.state.orgAvailable ? CreatePlan : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createExtraPackage"
                component={
                  this.state.orgAvailable
                    ? CreateExtraPackage
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createBill"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.bill.bill_create
                    ? CreateBill
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createBillType"
                component={
                  this.state.orgAvailable ? CreateBillType : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editBill"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.bill.bill_update
                    ? EditBill
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewBill"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.bill.bill_detail
                    ? ViewBill
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/billList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.bill.bill.bill_list
                    ? BillList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/sendOrgInvitation"
                component={
                  this.state.orgAvailable
                    ? SendOrgInvitaion
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createConnection"
                component={
                  this.state.orgAvailable
                    ? CreateConnection
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/connectionList"
                component={
                  this.state.orgAvailable ? ConnectionList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editConnection"
                component={
                  this.state.orgAvailable ? EditConnection : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/monthlyBillList"
                component={
                  this.state.orgAvailable ? MonthlyBillList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createMonthlyBill"
                component={
                  this.state.orgAvailable
                    ? CreateMonthlyBill
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/staffBillPayment"
                component={
                  this.state.orgAvailable
                    ? StaffBillPayment
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewMonthlyBill"
                component={
                  this.state.orgAvailable ? ViewMonthlyBill : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createRequisition"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.requisition.requisition_create
                    ? CreateRequisition
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewRequisition"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.requisition.requisition_detail
                    ? ViewRequisition
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editRequisition"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.requisition.requisition_update
                    ? EditRequisition
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/requisitionDetails"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.requisition.requisition_detail
                    ? RequisitionDetails
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createCountry"
                component={
                  this.state.orgAvailable ? CreateCountry : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/countryList"
                component={
                  this.state.orgAvailable ? CountryList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editCountry"
                component={
                  this.state.orgAvailable ? EditCountry : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createRoute"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.route.route_create
                    ? CreateRoute
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/routeList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.route.route_list
                    ? RouteList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editRoute"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.task.route.route_update
                    ? EditRoute
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/sendInvitation"
                component={
                  this.state.orgAvailable ? SendInvitation : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/genTask"
                component={
                  this.state.orgAvailable ? TaskFromOrder : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/attendanceList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.attendance
                    .attendance_list
                    ? AttendanceList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createAttendance"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.attendance
                    .attendance_create_for
                    ? CreateAttendance
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editAttendance"
                component={
                  this.state.orgAvailable ? EditAttendance : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewAttendance"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.attendance
                    .attendance_detail
                    ? ViewAttendance
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/quickAttendanceList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.quick_attendance
                    .quick_attendance_list
                    ? QuickAttendanceList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/notificationList"
                component={
                  this.state.orgAvailable
                    ? NotificationList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createContainer"
                component={
                  this.state.orgAvailable ? CreateContainer : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/containerList"
                component={
                  this.state.orgAvailable ? ContainerList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editContainer"
                component={
                  this.state.orgAvailable ? EditContainer : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewContainer"
                component={
                  this.state.orgAvailable
                    ? ContainerDetails
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/ViewContainer/:container_id"
                component={
                  this.state.orgAvailable ? ViewContainer : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/containerforOrder"
                component={
                  this.state.orgAvailable
                    ? ContainerforOrder
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createParcel"
                component={
                  this.state.orgAvailable ? CreateParcel : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/parcelList"
                component={
                  this.state.orgAvailable ? ParcelList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/parcelfromOrder"
                component={
                  this.state.orgAvailable ? ParcelFromOrder : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editParcel"
                component={
                  this.state.orgAvailable ? EditParcel : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewParcel"
                component={
                  this.state.orgAvailable ? ParcelDetails : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/ViewParcel/:parcel_id"
                component={
                  this.state.orgAvailable ? ViewParcel : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/multiParcelFromOrder"
                component={
                  this.state.orgAvailable
                    ? MultiParcelFromOrder
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createTracking"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.organizational_setting
                    .tracking_settings
                    ? CreateTracking
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewTracking"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.organizational_setting
                    .tracking_settings
                    ? ViewTracking
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editTracking"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.organizational_setting
                    .tracking_settings
                    ? EditTracking
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/connecttoFinder"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.asset.connect_to_finder
                    ? ConnecttoFinder
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/userRoute"
                component={
                  this.state.orgAvailable ? UserRoute : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/fuelData"
                component={
                  this.state.orgAvailable ? FuelData : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createVisitPlan"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.visit_plan.visit_plan_create
                    ? CreateVisitPlan
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/visitPlans"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.visit_plan.visit_plan_list
                    ? VisitPlans
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewPlan"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.visit_plan.visit_plan_detail
                    ? ViewPlan
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/assetReport"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.report.asset_report
                    ? AssetReport
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createRate"
                component={
                  this.state.orgAvailable ? CreateRate : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editRate"
                component={
                  this.state.orgAvailable ? EditRate : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/rateList"
                component={
                  this.state.orgAvailable ? RateList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createQrcode"
                component={
                  this.state.orgAvailable ? CreateQrcode : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/qrcodeList"
                component={
                  this.state.orgAvailable ? QrcodeList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createTerritory"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.territory
                    .territory_create
                    ? CreateTerritory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/territoryList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.territory
                    .territory_list
                    ? TerritoryList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editTerritory"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.territory
                    .territory_update
                    ? EditTerritory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewTerritory"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.order_and_delivery.territory
                    .territory_detail
                    ? ViewTerritory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createRoasterPlan"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.roaster_plan
                    .roaster_plan_create
                    ? CreateRoasterPlan
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/roasterPlanList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.roaster_plan
                    .roaster_plan_list
                    ? RoasterPlanList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editRoasterPlan"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.roaster_plan
                    .roaster_plan_update
                    ? EditRoasterPlan
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createRoaster"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.roaster.roaster_create
                    ? CreateRoaster
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/roasterList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.roaster.roaster_list
                    ? RoasterList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editRoaster"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.roaster.roaster_update
                    ? EditRoaster
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createCustomField"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.custom_field.custom_fields_create
                    ? CreateCustomField
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/startTask"
                component={
                  this.state.orgAvailable ? StartTask : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/startingFrom"
                component={
                  this.state.orgAvailable ? StartingFrom : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/startTime"
                component={
                  this.state.orgAvailable ? StartTime : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/chooseAsset"
                component={
                  this.state.orgAvailable ? ChooseAsset : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/destination"
                component={
                  this.state.orgAvailable ? Destination : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createLeave"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.leave_application
                    .leave_application_create
                    ? CreateLeave
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/leaveList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.leave_application
                    .leave_application_list
                    ? LeaveList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewLeave"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.attendance.leave_application
                    .leave_application_detail
                    ? ViewLeave
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/updateSettings"
                component={
                  this.state.orgAvailable ? UpdateSettings : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/connectInventory"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.organizational_setting
                    .inventory_connection_settings
                    ? ConnectInventory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createIncident"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.incident.incident_create
                    ? CreateIncident
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/incidentList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.incident.incident_list
                    ? IncidentList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editIncident"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.incident.incident_update
                    ? EditIncident
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/incidentBills"
                component={
                  this.state.orgAvailable ? IncidentBills : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createReminder"
                component={
                  this.state.orgAvailable ? CreateReminder : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/reminderList"
                component={
                  this.state.orgAvailable ? ReminderList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createRole"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.roles_and_permission
                    .roles_and_permission_create
                    ? CreateRole
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/roleList"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.roles_and_permission
                    .roles_and_permission_list
                    ? RoleList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editRole"
                component={
                  this.state.orgAvailable &&
                  this.props.userProfileData.roles_and_permission
                    .roles_and_permission_update
                    ? EditRole
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/managePermission"
                component={
                  this.state.orgAvailable
                    ? ManagePermission
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createSubOrgType"
                component={
                  this.state.orgAvailable
                    ? CreateSubOrgType
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/subOrgTypeList"
                component={
                  this.state.orgAvailable ? SubOrgTypeList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editSubOrgType"
                component={
                  this.state.orgAvailable ? EditSubOrgType : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createSubOrg"
                component={
                  this.state.orgAvailable ? CreateSubOrg : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/subOrgList"
                component={
                  this.state.orgAvailable ? SubOrgList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editSubOrg"
                component={
                  this.state.orgAvailable ? EditSubOrg : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewSubOrg"
                component={
                  this.state.orgAvailable ? ViewSubOrg : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createSalesTarget"
                component={
                  this.state.orgAvailable
                    ? CreateSalesTarget
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/salesTargetList"
                component={
                  this.state.orgAvailable ? SalesTargetList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editSalesTarget"
                component={
                  this.state.orgAvailable ? EditSalesTarget : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createInventory"
                component={
                  this.state.orgAvailable ? CreateInventory : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/inventoryList"
                component={
                  this.state.orgAvailable ? InventoryList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewInventory"
                component={
                  this.state.orgAvailable ? ViewInventory : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/modifyInventory"
                component={
                  this.state.orgAvailable ? ModifyInventory : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewClosingHistory"
                component={
                  this.state.orgAvailable
                    ? ViewClosingHistory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/internalTransfer"
                component={
                  this.state.orgAvailable
                    ? InternalTransfer
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewInventoryStockHistory"
                component={
                  this.state.orgAvailable
                    ? ViewInventoryStockHistory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/viewProductStockHistory"
                component={
                  this.state.orgAvailable
                    ? ViewProductStockHistory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createTarget"
                component={
                  this.state.orgAvailable ? CreateTarget : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createAchievement"
                component={
                  this.state.orgAvailable
                    ? CreateAchievement
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/achievementList"
                component={
                  this.state.orgAvailable ? AchievementList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/achievementDetails"
                component={
                  this.state.orgAvailable
                    ? AchievementDetails
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/targetDetails"
                component={
                  this.state.orgAvailable ? TargetDetails : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/targetList"
                component={
                  this.state.orgAvailable ? TargetList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/orderSettings"
                component={
                  this.state.orgAvailable ? OrderSettings : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/smsSettings"
                component={
                  this.state.orgAvailable ? SmsSettings : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/emailSettings"
                component={
                  this.state.orgAvailable ? EmailSettings : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/connectThirdParty"
                component={
                  this.state.orgAvailable
                    ? ConnectThirdParty
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createProductCategory"
                component={
                  this.state.orgAvailable
                    ? CreateProductCategory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/productCategoryList"
                component={
                  this.state.orgAvailable
                    ? ProductCategoryList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editProductCategory"
                component={
                  this.state.orgAvailable
                    ? EditProductCategory
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/walletList"
                component={
                  this.state.orgAvailable ? WalletList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/financeReport"
                component={
                  this.state.orgAvailable ? FinanceReport : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createCustomForm"
                component={
                  this.state.orgAvailable
                    ? CreateCustomForm
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editCustomForm"
                component={
                  this.state.orgAvailable ? EditCustomForm : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createPromotion"
                component={
                  this.state.orgAvailable ? CreatePromotion : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/promotionList"
                component={
                  this.state.orgAvailable ? PromotionList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editPromotion"
                component={
                  this.state.orgAvailable ? EditPromotion : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/walletHistory"
                component={
                  this.state.orgAvailable ? WalletHistory : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/prescriptionSearchList"
                component={
                  this.state.orgAvailable
                    ? PrescriptionSearchList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/dueCollectionList"
                component={
                  this.state.orgAvailable
                    ? DueCollectionList
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/handoverList"
                component={
                  this.state.orgAvailable ? HandoverList : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createBillSettings"
                component={
                  this.state.orgAvailable
                    ? CreateBillSettings
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/editBillSettings"
                component={
                  this.state.orgAvailable
                    ? EditBillSettings
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/billSettingsList"
                component={
                  this.state.orgAvailable
                    ? BillSettingsList
                    : CreateOrganization
                }
              />
              <PrivateRoute exact path="/profile" component={UserProfile} />
              <PrivateRoute
                exact
                path="/createYoutubeLink"
                component={CreateYoutubeLink}
              />
              <PrivateRoute
                exact
                path="/youtubeLinkList"
                component={ViewYoutubeLink}
              />
              <PrivateRoute
                exact
                path="/editYoutubeLink"
                component={EditYoutubeLink}
              />
              <PrivateRoute
                exact
                path="/inventorySettings"
                component={InventorySettings}
              />
              <PrivateRoute
                exact
                path="/attendanceSettings"
                component={
                  this.props.userProfileData.organizational_setting
                    .attendance_settings
                    ? AttendanceSettings
                    : CreateOrganization
                }
              />
              <PrivateRoute
                exact
                path="/createReturnOrder"
                component={
                  this.props.userProfileData.organizational_setting
                    .attendance_settings
                    ? CreateReturnOrder
                    : CreateOrganization
                }
              />
            </Content>
            {/* {this.state.collapsedUser ? null : (
              <Sider
                trigger={null}
                collapsible
                collapsed={this.state.collapsedUser}
                style={{ backgroundColor: "white" }}
              >
                <Menu
                  mode="inline"
                  theme="light"
                  className="side-bar"
                  defaultOpenKeys={["online"]}
                  style={{
                    width: 280,
                    height: window.innerHeight - 50,
                    position: "fixed",
                    right: 0,
                    zIndex: 1
                  }}
                >
                  <SubMenu
                    key="online"
                    title={
                      <span>
                        <span>Available Users</span>
                      </span>
                    }
                  >
                    {this.state.UserLocationList.map(item => {
                      return (
                        <Menu.Item
                          onMouseEnter={() =>
                            this.getTaskdata(item.locs.user_id.$oid)
                          }
                          key={item.locs.user_id.$oid}
                        >
                          <Popover
                            content={
                              this.state.smallLoading ? (
                                <Spin />
                              ) : this.state.noData ? (
                                <div>Not Task Found</div>
                              ) : (
                                this.state.taskData.map(item => (
                                  <div
                                    key={item._id.$oid}
                                    style={{
                                      borderTop: "1px solid grey",
                                      borderBottom: "1px solid grey",
                                      margin: 5
                                    }}
                                  >
                                    <div>Task Name :{item.name} </div>
                                    <div>Status :{item.task_status} </div>
                                  </div>
                                ))
                              )
                            }
                            placement="left"
                          >
                            <Avatar style={{ marginRight: 10 }}>
                              {item.user[0]}
                            </Avatar>{" "}
                            {item.user}
                            <Badge
                              dot={true}
                              style={{ marginLeft: 10 }}
                              color={
                                TIME_DIFF(
                                  this.state.currentTime,
                                  item.locs.timestamp.$date
                                ) <= 5
                                  ? "green"
                                  : TIME_DIFF(
                                      this.state.currentTime,
                                      item.locs.timestamp.$date
                                    ) > 5 &&
                                    TIME_DIFF(
                                      this.state.currentTime,
                                      item.locs.timestamp.$date
                                    ) <= 30
                                  ? "yellow"
                                  : "red"
                              }
                            ></Badge>
                          </Popover>
                        </Menu.Item>
                      );
                    })}
                  </SubMenu>
                </Menu>
              </Sider>
            )} */}
          </Layout>
        </Layout>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
  defaultOpenSubmenuData: state.menu.defaultOpenSubmenuData,
  defaultSideBarScrollPosition:
    state.sideBarScrollPosition.defaultSideBarScrollPosition,
});

export default connect(mapStateToProps, {
  getUserData,
  saveDefaultOpenSubmenu,
  saveSideBarScrollPosition,
})(DashBoard);
