export const colors=[
  "#16C47F", "#FB4141", "#FFD65A", "#C30E59", "#0A97B0", "#E16A54", "#638C6D", "#009990", "#92C7CF", "#FBF0B2",
  "#8033FF", "#33FF80", "#FF8033", "#33B5FF", "#B533FF", "#FF33D6", "#33FFB5", "#D633FF", "#FFB533", "#33D6FF",
  "#FF33FF", "#33FF33", "#FF33A6", "#33FFCC", "#A6FF33", "#FF6633", "#3366FF", "#FF33CC", "#33FF99", "#CC33FF",
  "#FF9966", "#6699FF", "#FF3399", "#33FF66", "#9933FF", "#FF6699", "#66FF33", "#FF9966", "#3366FF", "#99FF33",
  "#FF33FF", "#3399FF", "#33FF00", "#FF00FF", "#00FF33", "#FF0033", "#0033FF", "#FF00CC", "#00CCFF", "#CC00FF",
  "#FF3300", "#3300FF", "#FF00A6", "#00FF99", "#A600FF", "#FF0099", "#0099FF", "#99FF00", "#FF6600", "#6600FF",
  "#FF33D6", "#33FFB5", "#D633FF", "#FFB533", "#33D6FF", "#FF33FF", "#33FF33", "#FF33A6", "#33FFCC", "#A6FF33",
  "#FF6633", "#3366FF", "#FF33CC", "#33FF99", "#CC33FF", "#FF9966", "#6699FF", "#FF3399", "#33FF66", "#9933FF",
  "#FF6699", "#66FF33", "#FF9966", "#3366FF", "#99FF33", "#FF33FF", "#3399FF", "#33FF00", "#FF00FF", "#00FF33",
  "#FF0033", "#0033FF", "#FF00CC", "#00CCFF", "#CC00FF", "#FF3300", "#3300FF", "#FF00A6", "#00FF99", "#A600FF"
]