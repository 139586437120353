import React, { Component } from "react";
import {
  Table,
  Card,
  Button,
  Select,
  Typography,
  Pagination,
  Input,
  Tooltip,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import { debounce } from "lodash";
import moment from "moment";
// import DateFnsUtils from "@date-io/date-fns";
// import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FileSaver from "file-saver";

import {
  CREATE_INVENTORY,
  CURRENT_USER_SUB_ORG_LIST,
  GET_FIRST_CHILD_SUB_ORG_LIST,
  INVENTORY_LAST_CLOSING_VALUE_UPDATE,
  INVENTORY_MULTI_SHEET_REPORT,
  LOW_STOCK_REPORT,
} from "../../api/constants";
import { GET_ACCESS_TOKEN, timeConverter } from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Option } = Select;
const { Text, Title } = Typography;

class InventoryList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false,
    limit: 10,
    inventory_id: "",
    current_user_sub_orgs: [],
    current_user_sub_org_id: "",
    first_child_sub_orgs: [],
    first_child_sub_org_id: "",
    sub_org_id_inventory_search: "",
    sub_org_name: "",
    sub_org_parent_name: "",
    entity_name: "",
    entity_address: "",
    start_date: 1675188000000,
    start_date_view: moment("2023-02-01"),
    end_date: Date.now(),
    end_date_view: moment(),
    showLoadingButton: false,
    inventory_ids: [],
  };
  componentDidMount() {
    this.getCurrentUserSubOrgs();
    console.log(moment());
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getCurrentUserSubOrgs = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CURRENT_USER_SUB_ORG_LIST}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from current user sub org list", response);

          this.setState(
            {
              current_user_sub_orgs: response.data.data,
              current_user_sub_org_id:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
              sub_org_id_inventory_search:
                response.data.data.length > 0 &&
                !this.props.userProfileData.data_level.access_all_data
                  ? response?.data?.data[0].id
                  : "",
            },
            () => {
              this.getInventoryList();
              if (this.state.current_user_sub_org_id !== "") {
                GET_ACCESS_TOKEN().then((token) => {
                  const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

                  axios({
                    method: "get",
                    url: url,
                  })
                    .then((response) => {
                      console.log(
                        "response from first child sub org list",
                        response
                      );

                      this.setState({
                        first_child_sub_orgs: response.data.data,
                      });
                    })
                    .catch((error) => {
                      console.log(
                        "error from first child sub org list api",
                        error
                      );
                    });
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log("error from current user sub org list api", error);
        });
    });
  };

  getInventoryList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?entity_address=${this.state.entity_address}&entity_name=${this.state.entity_name}&sub_org_parent_name=${this.state.sub_org_parent_name}&sub_org_name=${this.state.sub_org_name}&sub_org=${this.state.sub_org_id_inventory_search}&page=${this.state.page}&limit=${this.state.limit}&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from inventory list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from inventory list api", error);
        });
    });
  }, 500);

  setCurrentUserSubOrg = (value) => {
    this.setState(
      { current_user_sub_org_id: value, sub_org_id_inventory_search: value },
      () => {
        this.getInventoryList();
        GET_ACCESS_TOKEN().then((token) => {
          const url = `${GET_FIRST_CHILD_SUB_ORG_LIST}?sub_org=${this.state.current_user_sub_org_id}&limit=100&access_token=${token}`;

          axios({
            method: "get",
            url: url,
          })
            .then((response) => {
              console.log("response from first child sub org list", response);

              this.setState({
                first_child_sub_orgs: response.data.data,
              });
            })
            .catch((error) => {
              console.log("error from first child sub org list api", error);
            });
        });
      }
    );
  };
  setFirstChildSubOrg = (value) => {
    this.setState(
      { first_child_sub_org_id: value, sub_org_id_inventory_search: value },
      () => {
        this.getInventoryList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getInventoryList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getInventoryList();
    });
  };

  updateClosingValue = () => {
    swal
      .fire({
        title:
          "Are you sure you want to update the closing value for this inventory?",
        text: "Click Yes to confirm",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.value) {
          const data = {
            last_closing_time: Date.now() / 1000,
          };
          console.log(data);
          GET_ACCESS_TOKEN().then((token) => {
            let url = `${INVENTORY_LAST_CLOSING_VALUE_UPDATE}${this.state.inventory_id}?access_token=${token}`;

            axios({
              method: "put",
              url: url,
              data: data,
              headers: {
                "content-type": "application/x-www-form-urlencoded",
              },
            })
              .then((response) => {
                swal.fire("Info", response.data.message, "info");
                console.log("response from closing value update", response);
                this.getInventoryList();
              })
              .catch((error) => {
                swal.fire("Error", error.message, "error");
                console.log("error from closing value update", error);
                this.getInventoryList();
              });
          });
        }
      });
  };

  addTo = (record) => {
    let addedArray = [];
    addedArray.push(record._id.$oid);
    this.setState(
      {
        inventory_ids: [...this.state.inventory_ids, ...addedArray],
      },
      () => {
        console.log("added data", this.state.inventory_ids);
      }
    );
  };

  removefrom = (record) => {
    let addedFiltered = this.state.inventory_ids.filter(
      (m) => m !== record._id.$oid
    );
    this.setState({ inventory_ids: addedFiltered }, () => {
      console.log("added data", this.state.inventory_ids);
    });
  };

  downloadInventorySummary = () => {
    if (this.state.inventory_ids.length > 5) {
      swal.fire("Info", "You can not select more than 5 inventories", "info");
    } else {
      this.setState({ showLoadingButton: true });
      GET_ACCESS_TOKEN().then((token) => {
        const url = `${INVENTORY_MULTI_SHEET_REPORT}?ids=${JSON.stringify(
          this.state.inventory_ids
        )}&report_type=excel&sub_org=${
          this.state.sub_org_id_inventory_search
        }&access_token=${token}`;
        console.log("the url", url);
        fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            console.log(
              "response from inventory summary excel report fmt",
              blob
            );
            FileSaver.saveAs(
              blob,
              `inventory_summary_report_${moment().format(
                "DD-MMMM-YYYY_hh:mm A"
              )}.xlsx`
            );
            this.setState({ showLoadingButton: false });
          })
          .catch((error) => {
            this.setState({ showLoadingButton: false });
            console.log("error from inventory summary api", error);
          });
      });
    }
  };

  downloadLowStockReport = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${LOW_STOCK_REPORT}?access_token=${token}`;
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          console.log(
            "response from inventory low stock excel report fmt",
            blob
          );
          FileSaver.saveAs(
            blob,
            `inventory_low_stock_report_${moment().format(
              "DD-MMMM-YYYY_hh:mm A"
            )}.xlsx`
          );
        })
        .catch((error) => {
          console.log("error from inventory low stock api", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.inventory.create_inventory ? (
          <Link to="/createInventory">
            <Tooltip placement="top" title="Create Inventory">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

        
        {this.props.userProfileData.data_level.access_suborg_data ? (
          <div>
            <Title level={4}>Filter Inventory With Sub Organization</Title>

            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div style={{ margin: 10 }}>
                <Text> User Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.current_user_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.current_user_sub_org_id}
                  onChange={this.setCurrentUserSubOrg}
                >
                  {this.state.current_user_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              <div style={{ margin: 10 }}>
                <Text> Child Sub Organization : {""}</Text>
                <Select
                  style={{ minWidth: 200 }}
                  disabled={
                    this.state.first_child_sub_orgs.length < 1 ? true : false
                  }
                  value={this.state.first_child_sub_org_id}
                  onChange={this.setFirstChildSubOrg}
                >
                  {this.state.first_child_sub_orgs.map((r) => {
                    return <Option key={r.id}>{`${r.name}`}</Option>;
                  })}
                </Select>
              </div>
              {this.state.inventory_ids.length > 0 &&
              this.props.userProfileData.report.inventory_report
                .multiple_inventory_summary_report ? (
                <div style={{ margin: 10 }}>
                  <Button
                    loading={this.state.showLoadingButton}
                    style={{
                      backgroundColor: "#1A3337",
                      color: "white",
                    }}
                    onClick={this.downloadInventorySummary}
                  >
                    Download Summary
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {/* {this.props.userProfileData.data_level.access_suborg_data ? (
          <Card>
            <Title level={4}>Select Start and End Date to Download Report</Title>
            <div
              style={{ display: "flex", flexDirection: "row", marginBottom: 3 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.start_date_view}
                    onChange={(value, dateString) => {
                      this.setState({
                        start_date: moment(value).format("x"),
                        start_date_view: value
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: 5
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    inputVariant="outlined"
                    value={this.state.end_date_view}
                    onChange={(value, dateString) => {
                      this.setState({
                        end_date: moment(value)
                          .add(59, "seconds")
                          .format("x"),
                        end_date_view: value
                      });
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <Button
                loading={this.state.showLoadingButton}
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                  marginTop: 15
                }}
                onClick={this.downloadInventorySummary}
              >
                Download Summary
              </Button>
            </div>
          </Card>
        ) : null} */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10 }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            <Text>{`  Entries `}</Text>
          </div>
          {this.props.userProfileData.report.inventory_report
            .inventory_low_stock_report ? (
            <div style={{ margin: 10 }}>
              <Button
                loading={this.state.showLoadingButton}
                style={{
                  backgroundColor: "#1A3337",
                  color: "white",
                }}
                onClick={this.downloadLowStockReport}
              >
                Download Low Stock Report
              </Button>
            </div>
          ) : null}
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={<></>}
            key="inventory_id"
            render={(text, record) => (
              <div>
                <button
                  // disabled={record.bill_report_no === "" ? false : true}
                  style={{
                    height: 15,
                    width: 14,
                    border: "1px solid grey",
                    backgroundColor: this.state.inventory_ids.some(
                      (d) => d === record._id.$oid
                    )
                      ? "#1E88E5"
                      : "white",
                  }}
                  onClick={() => {
                    if (
                      this.state.inventory_ids.some(
                        (d) => d === record._id.$oid
                      )
                    ) {
                      this.removefrom(record);
                    } else {
                      this.addTo(record);
                    }
                  }}
                ></button>
              </div>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Place Name</Text>

                  <Input
                    style={{ minWidth: 90 }}
                    value={this.state.entity_name}
                    onChange={(evt) => {
                      this.setState(
                        { entity_name: evt.target.value, page: 0 },
                        () => {
                          this.getInventoryList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            dataIndex="entity_name"
            key="entity_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Place Address</Text>

                  <Input
                    style={{ minWidth: 90 }}
                    value={this.state.entity_address}
                    onChange={(evt) => {
                      this.setState(
                        { entity_address: evt.target.value, page: 0 },
                        () => {
                          this.getInventoryList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            dataIndex="entity_address"
            key="entity_address"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Contact Person Name</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="entity_contact_person_name"
            key="entity_contact_person_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Contact Person Mobile</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="entity_contact_person_mobile"
            key="entity_contact_person_mobile"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Sub Org</Text>

                  <Input
                    style={{ minWidth: 90 }}
                    value={this.state.sub_org_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_name: evt.target.value, page: 0 },
                        () => {
                          this.getInventoryList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            render={(text, record) => <span>{record.sub_org?.name ?? ""}</span>}
            key="sub_org_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Parent Sub Org</Text>

                  <Input
                    style={{ minWidth: 90 }}
                    value={this.state.sub_org_parent_name}
                    onChange={(evt) => {
                      this.setState(
                        { sub_org_parent_name: evt.target.value, page: 0 },
                        () => {
                          this.getInventoryList();
                        }
                      );
                    }}
                  />
                </div>
              </>
            }
            render={(text, record) => (
              <span>{record.sub_org?.parent_sub_org_data?.name ?? ""}</span>
            )}
            key="parent_sub_org_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Total Product</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="total_product"
            key="total_product"
          />
          {/* <Column title="Sub Org" dataIndex="sub_org" key="sub_org" /> */}
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Starting Value</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="starting_value"
            key="starting_value"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Starting Time</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="starting_time"
            render={(text, record) => (
              <span>
                {record.starting_time === ""
                  ? null
                  : timeConverter(record.starting_time.$date)}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Last Closing Value</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="last_closing_val"
            key="last_closing_val"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Last Closing Time</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="last_closing_time"
            render={(text, record) => (
              <span>
                {record.last_closing_time === ""
                  ? null
                  : timeConverter(record.last_closing_time.$date)}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Current Value</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="current_value"
            key="current_value"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Modified On</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="modified_on"
            render={(text, record) => (
              <span>{timeConverter(record.modified_on.$date)}</span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created On</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {this.props.userProfileData.inventory.inventory_detail ? (
                  <Link
                    to={{
                      pathname: "/viewInventory",
                      state: { inventory_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View Details
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.inventory
                  .inventory_closing_history ? (
                  <Link
                    to={{
                      pathname: "/viewClosingHistory",
                      state: { inventory_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      View Closing History
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.inventory.modify_inventory_stock ? (
                  <Link
                    to={{
                      pathname: "/modifyInventory",
                      state: { inventory_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Modify Inventory
                    </Button>
                  </Link>
                ) : null}
                {this.props.userProfileData.inventory
                  .inventory_stock_history ? (
                  <Link
                    to={{
                      pathname: "/viewInventoryStockHistory",
                      state: { inventory_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Inventory Stock History
                    </Button>
                  </Link>
                ) : null}

                <Button
                  type="link"
                  style={{ color: "green" }}
                  onClick={() =>
                    this.setState(
                      {
                        inventory_id: record._id.$oid,
                      },
                      () => {
                        this.updateClosingValue();
                      }
                    )
                  }
                >
                  Update Closing Value
                </Button>
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, {
  getUserData,
})(InventoryList);
