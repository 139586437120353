import React, { Component } from "react";
import {
  Form,
  Icon,
  Input,
  Button,
  Card,
  Select,
  Typography,
  Tabs,
} from "antd";
import { Redirect } from "react-router-dom";
import swal from "sweetalert2";
import axios from "axios";

import { RESET_PASSWORD, REVERSE_GEO_COUNTRY } from "../api/constants";
// import { validateEmail } from "../utilities/utilities";
import { Spinner } from "./loader";
import "./login.css";
import Logo from "../assets/logoblue.png";

import { countryCode } from "./countryCode";

const { Option } = Select;
const { TabPane } = Tabs;
const { Title } = Typography;

export default class ForgetPassword extends Component {
  state = {
    mobile: "",
    email: "",
    loading: false,
    toMobile: false,
    toEmail: false,
    countryCode: [],
    code: "",
    width: window.innerWidth,
    height: window.innerHeight,
  };

  componentDidMount() {
    const handleResize = () => {
      this.setState({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", handleResize);
    this.setState({ countryCode: countryCode });
    this.getCountry();
  }

  getCountry = () => {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      axios.get(REVERSE_GEO_COUNTRY(latitude, longitude)).then((response) => {
        console.log("response from reverse geo", response.data.address.country);
        if (response.data.address.country === "Bangladesh") {
          this.setState({ code: "+880" });
        } else if (response.data.address.country === "Nepal") {
          this.setState({ code: "+977" });
        } else if (response.data.address.country === "Australia") {
          this.setState({ code: "+61" });
        } else {
          this.setState({ code: "+61" });
        }
      });
    };
    navigator.geolocation.getCurrentPosition(success);
  };

  setCode = (value) => {
    this.setState({ code: value }, () => {
      console.log(this.state.code);
    });
  };
  setMobile = (evt) => {
    evt.preventDefault();
    this.setState({ mobile: evt.target.value });
    console.log(this.state.mobile);
  };
  setEmail = (evt) => {
    evt.preventDefault();
    this.setState({ email: evt.target.value });
    console.log(this.state.email);
  };

  sendCode = () => {
    if (this.state.mobile === "" && this.state.email === "") {
      swal.fire(
        "Warning",
        "Please enter a valid mobile number or email",
        "warning"
      );
    } else if (this.state.mobile !== "" && this.state.email !== "") {
      swal.fire(
        "Warning",
        "Please only enter a valid mobile number or email",
        "warning"
      );
    } else {
      this.setState({ loading: true });
      if (this.state.mobile !== "") {
        const url = `${RESET_PASSWORD}?user=${
          this.state.code + this.state.mobile
        }`;
        axios({
          method: "get",
          url: url,
        })
          .then((response) => {
            this.setState({ loading: false });
            console.log("response from reset Password api", response);
            if (response.data.hasOwnProperty("status")) {
              swal.fire("Information", response.data.status, "info");
              if (
                isNaN(this.state.mobile) === false &&
                response.data.status === "sms sent"
              ) {
                this.setState({ toMobile: true });
              } else if (response.data.status === "sms already sent") {
                this.setState({ toMobile: true });
              }
              // else if(validateEmail(this.state.mobile)===true && response.data.status==="email sent"){
              //   localStorage.setItem(
              //     "email",
              //     JSON.stringify(this.state.mobile)
              //   );

              // }
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log("error in reset Password", error);
          });
      } else if (this.state.email !== "") {
        const url = `${RESET_PASSWORD}?user=${this.state.email}`;
        axios({
          method: "get",
          url: url,
        })
          .then((response) => {
            this.setState({ loading: false });
            console.log("response from reset Password api", response);
            if (response.data.hasOwnProperty("status")) {
              swal.fire("Information", response.data.status, "info");
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            console.log("error in reset Password", error);
          });
      }
    }
  };
  render() {
    if (this.state.toEmail === true) {
      return <Redirect to="/home" />;
    } else if (this.state.loading === true) {
      return <Spinner />;
    } else if (this.state.toMobile === true) {
      return (
        <Redirect
          to={{
            pathname: "/resetCodeMobile",
            state: { mobile: this.state.mobile, code: this.state.code },
          }}
        />
      );
    }
    return (
      <div className="container" style={{ width: this.state.width,backgroundColor: "#EFF3EA", }}>
      <div style={{ display: "flex" }}>
        <Card
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            borderRadius: 15,
            position: "relative",
            top: this.state.height / 20,
          }}
        >
          <div style={{ textAlign: "center", paddingBottom: 10 }}>
            <a href="https://www.conveyance.app/">
              <img
                src={Logo}
                alt=""
                style={{
                  width: 230,
                  position: "relative",
                  padding: 10,
                }}
              />
            </a>
          </div>
          <Title level={4}>Enter your Mobile or E-mail</Title>
          <Form className="login-form">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Enter Mobile" key="1">
                <Form.Item>
                  <div
                    style={{ display: "flex", flexDirection: "row", flex: 1 }}
                  >
                    <Select
                      value={this.state.code}
                      onChange={this.setCode}
                      placeholder="Select Coode"
                      style={{ width: 140, marginRight: 5 }}
                    >
                      {this.state.countryCode.map((r) => {
                        return (
                          <Option key={r.dial_code}>
                            {r.code} {r.dial_code}
                          </Option>
                        );
                      })}
                    </Select>
                    <Input
                      type="number"
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="Mobile"
                      value={this.state.mobile}
                      onChange={this.setMobile}
                      // style={{display:"flex",flex:1}}
                    />
                  </div>
                </Form.Item>
              </TabPane>
              <TabPane tab="Enter Email" key="2">
                <Form.Item>
                  <Input
                    type="text"
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.setEmail}
                  />
                </Form.Item>
              </TabPane>
            </Tabs>

            <Form.Item>
              <Button
              style={{width:"100%"}}
                className="login-form-button"
                htmlType="submit"
                onClick={this.sendCode}
              >
                Send Code
              </Button>
            </Form.Item>
          </Form>
        </Card>
        </div>
      </div>
    );
  }
}
