import React, { Component } from "react";
import {
  Table,
  Tag,
  Card,
  PageHeader,
  Button,
  Tooltip,
  Modal,
  Form,
  Input,
  Typography,
  Select,
  Pagination,
} from "antd";
import { connect } from "react-redux";
import { getUserData } from "../../redux/actions/userProfileAction";
import { Link } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert2";
import { debounce } from "lodash";

import {
  CREATE_PRODUCT,
  EDIT_PRODUCT,
  PRODUCT_STOCK_CHANGE,
  CREATE_INVENTORY,
  PRODUCT_ADD_REASON,
  PRODUCT_SUBTRACT_REASON,
  GENERATE_BARCODE_PRODUCT,
} from "../../api/constants";
import {
  GET_ACCESS_TOKEN,
  timeConverter,
  GET_USER_LOCAL,
  CHECK_BOOL,
} from "../../utilities/utilities";
import { Spinner } from "../loader";
import ReactGA from "react-ga4";

import "../login.css";

const { Column } = Table;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

class ProductList extends Component {
  state = {
    data: [],
    page: 0,
    total: 0,
    loading: false,
    show_stock_modal: false,
    added_stock: 0,
    subtracted_stock: 0,
    cost_amount: 0,
    comment: "",
    remarks: "",
    product_id: "",
    limit: 10,
    stock_update_type: "add",
    inventory_data: [],
    inventory_id: "",
    inventory_name: "",
    category_name: "",
    product_name: "",
    sku: "",
    allow_zero_value: true,
    is_parent_product: false,
    add_reason_options: [],
    remove_reason_options: [],
    unit_purchase_price: 0,
    barcode_search: false,
    low_stock_product: false,
  };
  componentDidMount() {
    this.props.getUserData();
    this.getAddReasonOptions();
    this.getSubtractReasonOptions();
    this.getInventoryList();

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.pathname,
    });
  }

  getAddReasonOptions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRODUCT_ADD_REASON}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product add reason list", response);
          this.setState(
            { add_reason_options: response.data.add_reason_choices },
            () => {
              this.setState({ remarks: this.state.add_reason_options[0].name });
            }
          );
        })
        .catch((error) => {
          console.log("error from product add reason list api", error);
        });
    });
  };
  getSubtractReasonOptions = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${PRODUCT_SUBTRACT_REASON}?access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product subtract reason list", response);
          this.setState({
            remove_reason_options: response.data.subtract_reason_choices,
          });
        })
        .catch((error) => {
          console.log("error from product subtract reason list api", error);
        });
    });
  };

  getInventoryList = () => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_INVENTORY}?limit=100&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from inventory list", response);
          this.setState(
            {
              inventory_data: response.data.data,
              inventory_id:
                response.data.data.length > 0
                  ? response.data.data[0]._id.$oid
                  : "",
            },
            () => {
              this.getProductList();
            }
          );
        })
        .catch((error) => {
          console.log("error from inventory list api", error);
        });
    });
  };

  getProductList = debounce(() => {
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${CREATE_PRODUCT}?low_stock_product=${
        this.state.low_stock_product
      }&barcode_search=${this.state.barcode_search}&sku=${
        this.state.sku
      }&name=${this.state.product_name}&is_parent_product=${
        this.state.is_parent_product
      }&category_name=${this.state.category_name}&inventory_id=${
        this.state.is_parent_product ? "" : this.state.inventory_id
      }&enable_order_for_availabl_stock_or_stock_quantity_zero=${
        this.state.allow_zero_value
      }&page=${this.state.page}&limit=${
        this.state.limit
      }&access_token=${token}`;

      axios({
        method: "get",
        url: url,
      })
        .then((response) => {
          console.log("response from product list", response);
          this.setState({
            data: response.data.data,
            total: response.data.total,
          });
        })
        .catch((error) => {
          console.log("error from product list api", error);
        });
    });
  }, 500);

  // handleLoadMore = () => {
  //   this.setState(
  //     {
  //       page: this.state.page + 1
  //     },
  //     () => {
  //       this.getProductList();
  //     }
  //   );
  // };

  setInventory = (value) => {
    this.setState(
      { inventory_name: value.label, inventory_id: value.key },
      () => {
        console.log(this.state.inventory_name);
        this.getProductList();
      }
    );
  };

  pageChange = (current, pageSize) => {
    this.setState({ page: current - 1 }, () => {
      console.log("page number", this.state.page);
      this.getProductList();
    });
  };

  setLimit = (value) => {
    this.setState({ limit: value }, () => {
      console.log(this.state.limit);
      this.getProductList();
    });
  };

  setStockUpdateType = (value) => {
    this.setState({ stock_update_type: value }, () => {
      console.log(this.state.stock_update_type);
      if (this.state.stock_update_type === "add") {
        this.setState({
          subtracted_stock: 0,
          remarks: "",
        });
      } else {
        this.setState({
          added_stock: 0,
          cost_amount: 0,
          remarks: "",
        });
      }
    });
  };

  deleteProduct = (id) => {
    this.setState({ loading: true });
    GET_ACCESS_TOKEN().then((token) => {
      const url = `${EDIT_PRODUCT}${id}?access_token=${token}`;

      axios({
        method: "delete",
        url: url,
      })
        .then((response) => {
          this.setState({ loading: false });
          swal.fire("Success", response.data.message, "success");
          setTimeout(() => window.location.reload(), 1000);
          console.log("deleted", response);
        })
        .catch((error) => {
          this.setState({ loading: false });
          swal.fire("Error", error, "error");
          console.log("error from del", error);
        });
    });
  };

  updateStock = () => {
    if (
      this.state.stock_update_type === "add" &&
      Number(this.state.added_stock) < 0.01
    ) {
      swal.fire("Info", "Added quantity can not be less than 0", "info");
    } else if (
      this.state.stock_update_type === "remove" &&
      Number(this.state.subtracted_stock) < 0.01
    ) {
      swal.fire("Info", "Removed quantity can not be less than 0", "info");
    } else if (this.state.remarks === "") {
      swal.fire("Info", "Please select remarks", "info");
    } else if (
      this.state.remarks === "wrong_added_stock_adjustment" &&
      Number(this.state.cost_amount) < 0.01
    ) {
      swal.fire("Info", "Please enter cost amount", "info");
    } else {
      const data = {
        added_stock: Number(this.state.added_stock),
        subtracted_stock: Number(this.state.subtracted_stock),
        cost_amount: Number(this.state.cost_amount),
        add_or_subtract_reason: this.state.remarks,
        comment: this.state.comment,
      };
      console.log(data);
      GET_ACCESS_TOKEN().then((token) => {
        let url = `${PRODUCT_STOCK_CHANGE}${this.state.product_id}?access_token=${token}`;

        axios({
          method: "put",
          url: url,
          data: data,
          headers: {
            "content-type": "application/x-www-form-urlencoded",
          },
        })
          .then((response) => {
            swal.fire("Info", response.data.message, "info");
            this.getProductList();
            this.setState({
              show_stock_modal: false,
              added_stock: 0,
              subtracted_stock: 0,
              cost_amount: 0,
              remarks: "",
              comment: "",
              unit_purchase_price: 0,
            });
            console.log("response from product status change", response);
          })
          .catch((error) => {
            swal.fire("Error", error.message, "error");
            console.log("error from product status change", error);
          });
      });
    }
  };

  generateBarCode = (product_id) => {
    const data = {
      product_id: product_id,
    };
    console.log(data);
    GET_ACCESS_TOKEN().then((token) => {
      let url = `${GENERATE_BARCODE_PRODUCT}${product_id}?access_token=${token}`;

      axios({
        method: "put",
        url: url,
        data: data,
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          swal.fire("Info", response.data.message, "info");
          this.getProductList();
          console.log("response from product barcode gen", response);
        })
        .catch((error) => {
          swal.fire("Error", error.message, "error");
          console.log("error from product barcode gen", error);
        });
    });
  };

  render() {
    if (this.state.loading === true) {
      return <Spinner />;
    }
    return (
      <Card style={{ margin: 10 }}>
        {this.props.userProfileData.order_and_delivery.product
          .product_create ? (
          <Link to="/createProduct">
            <Tooltip placement="top" title="Create Product">
              <Button shape="circle" icon="plus" className="tooltip-button" />
            </Tooltip>
          </Link>
        ) : null}

        <PageHeader title="Products" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Text>{`Displaying : `}</Text>
            <Select value={this.state.limit} onChange={this.setLimit}>
              <Option key={10}>10</Option>
              <Option key={25}>25</Option>
              <Option key={50}>50</Option>
              <Option key={100}>100</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
          <div
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "column",
              width: 300,
            }}
          >
            <Text>Select Inventory :</Text>
            <Select
              labelInValue
              style={{ width: "100%" }}
              value={{
                key: this.state.inventory_id,
                label: this.state.inventory_name,
              }}
              onChange={this.setInventory}
              placeholder="Select Inventory"
            >
              {this.state.inventory_data.map((r) => {
                return <Option key={r._id.$oid}>{r.entity_name}</Option>;
              })}
            </Select>
          </div>
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Text>{`Display Zero Stock : `}</Text>
            <Select
              value={this.state.allow_zero_value.toString()}
              onChange={(value) =>
                this.setState({ allow_zero_value: CHECK_BOOL(value) }, () =>
                  this.getProductList()
                )
              }
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Text>{`Display Parent Product : `}</Text>
            <Select
              value={this.state.is_parent_product.toString()}
              onChange={(value) =>
                this.setState({ is_parent_product: CHECK_BOOL(value) }, () =>
                  this.getProductList()
                )
              }
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Text>{`Display Low Stocks : `}</Text>
            <Select
              value={this.state.low_stock_product.toString()}
              onChange={(value) =>
                this.setState({ low_stock_product: CHECK_BOOL(value) }, () =>
                  this.getProductList()
                )
              }
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
          <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
            <Text>{`Search With Bar Code : `}</Text>
            <Select
              value={this.state.barcode_search.toString()}
              onChange={(value) =>
                this.setState({ barcode_search: CHECK_BOOL(value) }, () =>
                  this.getProductList()
                )
              }
            >
              <Option key={true}>True</Option>
              <Option key={false}>False</Option>
            </Select>
            {/* <Text>{`  Entries `}</Text> */}
          </div>
        </div>
        <Table
          dataSource={this.state.data}
          pagination={false}
          rowKey={(record) => record._id.$oid}
          size="small"
          bordered
        >
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Text ellipsis={true}>Product Name</Text>
                </div>

                <Input
                  value={this.state.product_name}
                  onChange={(evt) => {
                    this.setState(
                      { product_name: evt.target.value, page: 0 },
                      () => {
                        this.getProductList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="name"
            key="name"
          />
          {/* <Column title="Product ID" dataIndex="product" key="product" /> */}
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Description</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="description"
            key="description"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Text ellipsis={true}>Category Name</Text>
                </div>

                <Input
                  value={this.state.category_name}
                  onChange={(evt) => {
                    this.setState(
                      { category_name: evt.target.value, page: 0 },
                      () => {
                        this.getProductList();
                      }
                    );
                  }}
                />
              </>
            }
            dataIndex="category_name"
            key="category_name"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Image</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="Image"
            key="image"
            render={(text, record) => (
              <span>
                {record.product_img_thumb ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_PATH}${record.product_img_thumb}`}
                    alt="avatar"
                    style={{ width: 200, height: 200 }}
                  />
                ) : null}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Unit Purchase Price</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="unit_purchase_price"
            key="unit_purchase_price"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Unit Selling Price</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="unit_selling_price"
            key="unit_selling_price"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>MRP</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="mrp"
            key="mrp"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Stock Quantity</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="stock_quantity"
            key="stock_quantity"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Low Stock Quantity</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="low_stock_quantity"
            key="low_stock_quantity"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Ordered Quantity</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="ordered_quantity"
            key="ordered_quantity"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Available Stock</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="available_stock"
            key="available_stock"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Product Status</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="product_status"
            key="product_status"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Text ellipsis={true}>SKU</Text>
                </div>

                <Input
                  value={this.state.sku}
                  onChange={(evt) => {
                    this.setState({ sku: evt.target.value, page: 0 }, () => {
                      this.getProductList();
                    });
                  }}
                />
              </>
            }
            dataIndex="sku"
            key="sku"
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Unit</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="unit"
            key="unit"
          />

          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Tags</Text>
                  <Input disabled />
                </div>
              </>
            }
            dataIndex="tags"
            key="tags"
            render={(tags, index) => (
              <span>
                {tags.map((tag) => (
                  <Tag
                    color="blue"
                    key={tag}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: 2,
                    }}
                  >
                    {tag}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={
              <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text ellipsis={true}>Created On</Text>
                  <Input disabled />
                </div>
              </>
            }
            key="created_by"
            render={(text, record) => (
              <span>{timeConverter(record.created_on.$date)}</span>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(text, record) => (
              <span>
                {record.product_barcode ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BASE_PATH}${record.product_barcode}`
                      );
                    }}
                  >
                    View Barcode
                  </Button>
                ) : null}

                <Button
                  type="link"
                  style={{ color: "green" }}
                  onClick={() => {
                    if (record.sku) {
                      this.generateBarCode(record._id.$oid);
                    } else {
                      swal.fire(
                        "Info",
                        "Please add SKU for the product first",
                        "info"
                      );
                    }
                  }}
                >
                  Generate Barcode
                </Button>
                {GET_USER_LOCAL().org_admin && !this.state.is_parent_product ? (
                  <Button
                    type="link"
                    style={{ color: "green" }}
                    onClick={() =>
                      this.setState({
                        show_stock_modal: true,
                        product_id: record._id.$oid,
                        unit_purchase_price: record.unit_purchase_price,
                      })
                    }
                  >
                    Modify Inventory
                  </Button>
                ) : null}

                <Link
                  to={{
                    pathname: "/viewProduct",
                    state: { product_id: record._id.$oid },
                  }}
                >
                  <Button type="link" style={{ color: "green" }}>
                    View
                  </Button>
                </Link>
                {!this.state.is_parent_product ? (
                  <Link
                    to={{
                      pathname: "/viewProductStockHistory",
                      state: {
                        product_id: record._id.$oid,
                        inventory_id: record?.inventory_id ?? "",
                      },
                    }}
                  >
                    <Button style={{ color: "green" }} type="link">
                      View Product Stock History
                    </Button>
                  </Link>
                ) : null}

                {this.props.userProfileData.order_and_delivery.product
                  .product_update ? (
                  <Link
                    to={{
                      pathname: "/editProduct",
                      state: { product_id: record._id.$oid },
                    }}
                  >
                    <Button type="link" style={{ color: "green" }}>
                      Edit
                    </Button>
                  </Link>
                ) : null}
                {/* {this.props.userProfileData.order_and_delivery.product
                  .product_delete ? (
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        this.deleteProduct(record._id.$oid);
                      }
                    }}
                  >
                    Delete
                  </Button>
                ) : null} */}
              </span>
            )}
          />
        </Table>
        {this.state.total > 10 ? (
          <Pagination
            pageSize={Number(this.state.limit)}
            current={this.state.page + 1}
            total={this.state.total}
            onChange={this.pageChange}
            style={{ marginTop: 10 }}
          />
        ) : null}
        <Modal
          title="Modify Inventory"
          destroyOnClose={true}
          footer={null}
          visible={this.state.show_stock_modal}
          onCancel={() => {
            this.setState({
              show_stock_modal: false,
              product_id: "",
              added_stock: 0,
              subtracted_stock: 0,
              cost_amount: 0,
              remarks: "",
              unit_purchase_price: 0,
            });
          }}
          width={window.innerWidth - 200}
          closable={true}
        >
          <Form>
            <Form.Item>
              <Text>Stock Update Type</Text>
              <Select
                value={this.state.stock_update_type}
                onChange={this.setStockUpdateType}
              >
                <Option key="add">Add</Option>
                <Option key="remove">Remove</Option>
              </Select>
            </Form.Item>
            {this.state.stock_update_type === "add" ? (
              <Form.Item>
                <Text>Added Stock Amount</Text>
                <Input
                  placeholder="Added Stock Amount"
                  type="text"
                  value={this.state.added_stock}
                  onChange={(evt) =>
                    this.setState({ added_stock: evt.target.value }, () => {
                      this.setState({
                        cost_amount:
                          Number(this.state.added_stock) *
                          Number(this.state.unit_purchase_price),
                      });
                    })
                  }
                />
              </Form.Item>
            ) : null}

            {this.state.stock_update_type === "remove" ? (
              <Form.Item>
                <Text>Removed Stock Amount</Text>
                <Input
                  placeholder="Removed Stock Amount"
                  type="text"
                  value={this.state.subtracted_stock}
                  onChange={(evt) =>
                    this.setState({ subtracted_stock: evt.target.value })
                  }
                />
              </Form.Item>
            ) : null}

            {this.state.stock_update_type === "add" ? (
              <Form.Item>
                <Text>Total Purchase Price</Text>
                <Input
                  placeholder="Total Purchase Price"
                  type="text"
                  value={this.state.cost_amount}
                  onChange={(evt) =>
                    this.setState({ cost_amount: evt.target.value })
                  }
                />
              </Form.Item>
            ) : this.state.stock_update_type === "remove" &&
              this.state.remarks === "wrong_added_stock_adjustment" ? (
              <Form.Item>
                <Text>Total Purchase Price</Text>
                <Input
                  placeholder="Total Purchase Price"
                  type="text"
                  value={this.state.cost_amount}
                  onChange={(evt) =>
                    this.setState({ cost_amount: evt.target.value })
                  }
                />
              </Form.Item>
            ) : null}
            {this.state.stock_update_type === "add" ? (
              <Form.Item>
                <Text>Remarks</Text>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.remarks}
                  onChange={(value) => this.setState({ remarks: value })}
                  placeholder="Remarks"
                >
                  {this.state.add_reason_options.map((r) => {
                    return <Option key={r.name}>{r.label}</Option>;
                  })}
                </Select>
              </Form.Item>
            ) : (
              <Form.Item>
                <Text>Remarks</Text>
                <Select
                  style={{ width: "100%" }}
                  value={this.state.remarks}
                  onChange={(value) => this.setState({ remarks: value })}
                  placeholder="Remarks"
                >
                  {this.state.remove_reason_options.map((r) => {
                    return <Option key={r.name}>{r.label}</Option>;
                  })}
                </Select>
              </Form.Item>
            )}
            <Form.Item>
              <Text>Comment</Text>
              <TextArea
                rows={5}
                maxLength={1000}
                placeholder="Comment"
                type="text"
                value={this.state.comment}
                onChange={(evt) => this.setState({ comment: evt.target.value })}
              />
            </Form.Item>

            <Form.Item>
              <Button
                className="login-form-button"
                htmlType="submit"
                onClick={this.updateStock}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userProfileData: state.userProfile.userProfileData,
});

export default connect(mapStateToProps, { getUserData })(ProductList);
